export const rootAPI = {
  baseURL: 'https://api.ohme.io/',
}

export const APIv1 = {
  baseURL: rootAPI.baseURL + 'v1/',
}

export const stripe = {
  apiKey: 'pk_live_51ItWI4DWXzInvvBVpY9vtYE8qq1Bc6NNlINeyZLHuZJmN53Ks0qr6lhmSwe0LHb7BK6QTg6jR2VHqXx2yZGK7lg500a8CGkON2',
}

export const firebase = {
  apiKey: 'AIzaSyCeBO_09CK-8EAFBe8N5jA_hVP-Ik-YLPA',
  authDomain: 'ohme-prod.firebaseapp.com',
  databaseURL: 'https://ohme-prod.firebaseio.com',
  projectId: 'ohme-prod',
  storageBucket: 'ohme-prod.appspot.com',
  messagingSenderId: '206163667850',
  appId: '1:206163667850:web:05a242df77b45f59503673',
  measurementId: 'G-WSY0S31MJ7'
}

export const quickstatBase = 'https://fleet-dashboard-prod.ohme-ev.com/'
