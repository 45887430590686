<template>
  <Sidebar v-if="group" :back="baseRoute" :key="'group-' + group.id">
    <header>
      <h2>{{group.name}}</h2>
    </header>

    <template v-slot:controls>
      <transition name="bottom-sheet">
        <div v-if="!confirmDeletion" class="choose-action">
          <p class="actions sheet">
            <router-link tag="button" class="action" :to="{name: baseRoute + '-edit-group', params: {groupId: group.id}, query: {return: baseRoute + '-group-details'}}">Edit {{groupType}} group</router-link>
            <button class="action delete" @click="confirmDeletion = true">Delete</button>
          </p>
        </div>
      </transition>

      <transition name="bottom-sheet">
        <Overlay v-if="confirmDeletion" @close="confirmDeletion = false">
          <div class="confirm-removal sheet">
            <h3>Are you sure you want to delete this group?</h3>
            <p>Deleting the group from the fleet will lose all of its data. Drivers may not be able to use packages allowed for this group.</p>
            <div class="actions">
              <button class="action" @click="deleteGroup">Delete group</button>
              <button class="cancel" @click="confirmDeletion = false">Cancel</button>
            </div>
          </div>
          <div class="shade" />
        </Overlay>
      </transition>
    </template>

    <section class="group details">
      <ul>
        <li>
          <span class="label">Type</span><span class="invisible">:</span>
          {{objectSingular | capitalize}}
        </li>
        <li v-if="parentIds.length > 0">
          <span class="label">Parent group{{parentIds.length === 1 ? '' : 's'}}</span><span class="invisible">:</span>
          <div class="parents"><GroupTags :type="group.type" :baseRoute="baseRoute" :ids="parentIds" :sorted="false" /></div>
        </li>
      </ul>
    </section>

    <section class="packages details">
      <h3>Packages {{groupType === 'driver' ? 'available to' : 'allowed for'}} this group</h3>

      <p v-if="products.length === 0" class="empty">There are no packages {{groupType === 'driver' ? 'available to' : 'allowed for'}} this group.</p>
      <table v-else class="list">
        <thead>
          <tr>
            <td class="textual">Package name</td>
            <td class="textual">Type</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in products" :key="p.id">
            <th><Abbreviated middle :value="p.name" :max-length="40" :to="{name: baseRoute + '-package-details', params: {productId: p.id}}" /></th>
            <td class="textual">{{p.type === 'payg'? 'Pay As You Go' : 'Recurring'}}</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section v-if="groupType === 'charger'" class="chargers details">
      <h3>Chargers in this group</h3>

      <p v-if="chargers.length === 0" class="empty">There are no chargers in this group.</p>
      <table v-else class="list">
        <thead>
          <tr>
            <td class="textual">Charger name</td>
            <td class="textual">Serial ID</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in chargers" :key="c.id">
            <th><Abbreviated middle :value="c.name" :max-length="33" :to="{name: baseRoute + '-charger-details', params: {chargerId: c.id}}" /></th>
            <td class="textual"><Abbreviated middle :value="c.serialId" :max-length="22" class="last" /></td>
          </tr>
        </tbody>
      </table>
    </section>

    <section v-if="groupType === 'driver'" class="drivers details">
      <h3>Drivers in this group</h3>

      <p v-if="drivers.length === 0" class="empty">There are no drivers in this group.</p>
      <table v-else class="list">
        <thead>
          <tr>
            <td class="textual">Driver's name</td>
            <td class="textual">Cable ID</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in drivers" :key="d.id">
            <th><Abbreviated middle :value="d.name" :max-length="33" :to="{name: baseRoute + '-driver-details', params: {userId: d.id}}" /></th>
            <td class="textual"><Abbreviated middle :value="d.cable.id" :max-length="22" class="last" /></td>
          </tr>
        </tbody>
      </table>

    </section>
  </Sidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Abbreviated from 'elements/Abbreviated'
import Sidebar from 'elements/Sidebar'
import Overlay from 'elements/Overlay'
import GroupTags from 'elements/GroupTags'

export default {
  components: { Abbreviated, Sidebar, Overlay, GroupTags },
  props: {
    baseRoute: String,
  },
  data () {
    return {
      confirmDeletion: false,
    }
  },
  computed: {
    ...mapGetters(['units']),
    ...mapGetters('Groups', ['getGroupById', 'getParentsById']),
    ...mapGetters('Products', ['productsByGroupId']),
    ...mapGetters('Chargers', ['chargersByGroupId']),
    ...mapGetters('Users', ['usersByGroupId']),
    group () {
      return this.getGroupById(this.$route.params.groupId)
    },
    groupType () {
      return this.group.type === 'user'? 'driver' : 'charger'
    },
    parentIds () {
      return this.getParentsById(this.group.id).map(g => g.id)
    },
    objectSingular () {
      return `${this.groupType === 'driver' ? this.units.userSingular : this.units.deviceSingular} group`
    },
    products () {
      return this.productsByGroupId(this.group.id)
    },
    chargers () {
      return this.chargersByGroupId(this.group.id)
    },
    drivers () {
      return this.usersByGroupId(this.group.id)
    }
  },
  methods: {
    ...mapActions('Groups', ['deleteGroupById']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),

    deleteGroup () {
      this.deleteGroupById(this.group.id)
      .then(() => {
        this.$router.replace({name: this.baseRoute})
        this.showSuccessMessage('The group has been deleted')
      })
      .catch(() => {
        this.showErrorMessage('The group could not be deleted')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

header {
  h2 { 
    @include icon-left (19, 2);
    &::before { left: -5px; }
  }
}
section.packages {
  h3 {
    @include icon-left (17, 2);
    &::before { left: -5px; }
  }
}
section.chargers {
  h3 {
    @include icon-left (13, 2);
    &::before { left: -5px; }
  }
}
section.drivers {
  h3 {
    @include icon-left (16, 2);
    &::before { left: -5px; }
  }
}
.empty {
  font-weight: 500;
  color: $deemphasized-text;
}
.parents {
  margin-top: 12px;
}
</style>

<style lang="scss">
@import '~/common.scss';

section.details table.list .abbreviated abbr + span {
  box-shadow: none;
  // background: transparent;
}
</style>