import Vue from 'vue'
import { createProduct, updateProduct } from '~/api'

export const state = {
  products: {
    // "id": {
    //    id: "",
    //    rank: 0,
    //    public: true | false,
    //    name: "",
    //    type: "recurring" | "payg",
    //    groupIds: [""],
    //    description: "",
    //    currencyCode: "",
    //    pricePerKWh: { amount: 0.0, currency: "" },
    //    recurringFee: { amount: 0.0, currency: "" },
    //    recurringPeriod: "daily" | "weekly" | "monthly" | "yearly"
    //    joinFee: { amount: 0.0, currency: "" }
    //    plugInFee: { amount: 0.0, currency: "" }
    // }
  }
}

function byRank (a, b) { return a.rank != b.rank ? a.rank - b.rank : a.name < b.name ? -1 : 1 }

export const getters = {
  productCount: (_, getters) => getters.products.length,
  products: state => Object.values(state.products).sort(byRank),
  paygProducts: state => Object.values(state.products).filter(p => p.type === 'payg').sort(byRank),
  getProductById: state => id => state.products[id],
  productsByGroupId: (_, getters) => groupId => getters.products.filter(p => p.groupIds.indexOf(groupId) !== -1)
}

export const mutations = {
  reset (state) {
    state.products = {}
  },
  addProduct (state, product) {
    Vue.set(state.products, product.id, product)
  },
  patchProduct (state, product) {
    const old = state.products[product.id]

    if (old == undefined) {
      Vue.set(state.products, product.id, product)
    }
    else for (let prop in product) {
      if (product.hasOwnProperty(prop)) Vue.set(old, prop, product[prop])
    }
  },
}

export const actions = {
  createProduct ({ rootGetters, commit }, data) {
    return createProduct(rootGetters['Fleet/fleetId'], data).then((p) => {
      commit('addProduct', p)
      return p
    })
  },
  updateProduct ({ rootGetters, commit }, data) {
    return updateProduct(rootGetters['Fleet/fleetId'], data).then((p) => {
      commit('patchProduct', p)
      return p
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}