<template>
  <Sidebar :back="baseRoute" :key="'product-' + product.id">
    <header>
      <h2>{{product.name}}</h2>
    </header>

    <template v-slot:controls>
      <div class="choose-action">
        <p class="actions sheet">
          <router-link tag="button" class="action" :to="{name: baseRoute + '-edit-package', params: {productId: product.id}, query: {return: baseRoute + '-package-details'}}">Edit package</router-link>
        </p>
      </div>
    </template>

    <section class="package details">
      <ul>
        <li>
          <span class="label">Type</span><span class="invisible">:</span>
          {{product.type === 'recurring' ? 'Recurring' : 'Pay as you go'}}
          ({{product.public? 'Public' : 'Private'}})
        </li>
        <li>
          <span class="label">Rank</span><span class="invisible">:</span>
          {{product.rank}}
        </li>
        <li v-if="product.description" class="description">
          <span class="label">Description</span><span class="invisible">:</span>
          {{product.description}}
        </li>
      </ul>
    </section>

    <section class="pricing details">
      <h3>Pricing</h3>
      <ul>
        <li v-if="product.currencyCode">
          <span class="label">Currency</span><span class="invisible">:</span>
          {{product.currencyCode}}
        </li>
        <li v-if="product.pricePerKWh.amount != null">
          <span class="label">Rate</span><span class="invisible">:</span>
          <Currency :value="product.pricePerKWh.amount" :code="product.pricePerKWh.currency" :minor="true" :decimal="2" denominator="kWh" />
        </li>
        <li v-if="product.plugInFee.amount != null">
          <span class="label">Plug-in fee</span><span class="invisible">:</span>
          <Currency :value="product.plugInFee.amount" :code="product.plugInFee.currency" />
        </li>
      </ul>

      <ul v-if="product.type === 'recurring'">
        <li>
          <span class="label">Join fee</span><span class="invisible">:</span>
          <Currency :value="product.joinFee.amount" :code="product.joinFee.currency" />
        </li>
        <li>
          <span class="label">Recurring period</span><span class="invisible">:</span>
          {{product.recurringPeriod | capitalize}}
        </li>
        <li>
          <span class="label">Recurring fee</span><span class="invisible">:</span>
          <Currency :value="product.recurringFee.amount" :code="product.recurringFee.currency" />
        </li>
      </ul>
    </section>

    <section class="groups details">
      <h3>Driver groups</h3>

      <p><GroupTags :baseRoute="baseRoute" :ids="product.groupIds" type="user" /></p>
    </section>

    <section class="groups details">
      <h3>Charger groups</h3>

      <p><GroupTags :baseRoute="baseRoute" :ids="product.groupIds" type="device" /></p>
    </section>
  </Sidebar>
</template>

<script>
import { mapGetters } from 'vuex'

import Sidebar from 'elements/Sidebar'
import GroupTags from 'elements/GroupTags'
import Currency from 'elements/Currency'

export default {
  components: { Sidebar, GroupTags, Currency },
  props: {
    baseRoute: String
  },
  computed: {
    ...mapGetters('Products', ['getProductById']),
    product () {
      return this.getProductById(this.$route.params.productId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

header {
  h2 { 
    @include icon-left (17, 2);
    &::before { left: -5px; }
  }
}
section.package {
  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      flex: 0 1 calc(50% - 25px);
    }

    li.description {
      flex: 0 1 calc(100% - 25px);
    }
  }
}
section.pricing {
  h3 { @include icon-location (18, 2) }

  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      flex: 0 1 calc(33.33% - 25px);
    }
  }
}
</style>