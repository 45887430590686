<template>
  <div class="portal">
    <Menu id="menu" :style="{top: -this.menuOffset + 'px'}" />
    <router-view id="section" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { handleGeneralAPIErrors } from '~/index.js'

import Menu from 'elements/Menu.vue'

export default {
  components: { Menu },
  data () {
    return {
      menuHeight: 0,
      windowHeight: 0,
      scrollOffset: 0,
      menuOffset: 0,
    }
  },
  created () {
    this.trySwitchingTo(this.$route.params.fleetId)
  },
  mounted () {
    this.measureDimensionsAndOffsets()
    this.updateScrollPosition()

    window.addEventListener('resize', this.measureDimensionsAndOffsets)
    window.addEventListener('scroll', this.updateScrollPosition)
  },
  destroyed () {
    window.removeEventListener('resize', this.measureDimensionsAndOffsets)
    window.removeEventListener('scroll', this.updateScrollPosition)
  },
  watch: {
    $route(to, _) {
      this.trySwitchingTo(to.params.fleetId)
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetId', 'fleets']),
    ...mapGetters(['isSwitching'])
  },
  methods: {
    ...mapActions(['switchFleet']),
    trySwitchingTo (toFleetId) {
      const validFleetIds = Object.values(this.fleets).map(f => f.id)

      if (toFleetId != null && toFleetId != this.fleetId && validFleetIds.indexOf(toFleetId) !== -1) {
        this.switchFleet(toFleetId).catch(handleGeneralAPIErrors)
      }
    },
    measureDimensionsAndOffsets (e) {
      this.menuHeight = document.getElementById('menu').clientHeight
      this.windowHeight = document.getElementsByTagName('html')[0].clientHeight
    },
    updateScrollPosition (e) {
      const scrollOffset = document.getElementsByTagName('html')[0].scrollTop
      const scrollAmount = scrollOffset - this.scrollOffset

      // Menu is too large; need to scroll it
      if (this.menuHeight > this.windowHeight) {
        const menuScroll = this.menuOffset + scrollAmount
        
        this.menuOffset = Math.max(0, Math.min(this.menuHeight - this.windowHeight, menuScroll))
      }
      else {
        this.menuOffset = 0
      }

      this.scrollOffset = scrollOffset
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.portal {
  padding-left: $menu-width;
}
#menu {
  position: fixed;
  padding-top: (70px + 14px);
  padding-bottom: (60px + 14px);
  top: 0;
  left: 0;
  z-index: 3;
  width: $menu-width;

  &:after {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: $menu-width;
    background: $menu-background;
    content: '';

    .octopus.themed & {
      background: $octopus-menu-background;
      color: $octopus-menu-text;
    }
  }
}
#section {
  padding: 30px;
}
</style>