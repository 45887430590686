<template>
  <span v-if="abbreviated" class="abbreviated">
    <span class="tags"><span>{{firstGroup.name}}</span></span>
    <div class="tags">
      <template v-for="g in groups">
        <span v-if="[rootDeviceGroup.id, rootUserGroup.id].indexOf(g.id) !== -1" :key="g.id" class="root">{{g.name}}</span>
        <router-link v-else :key="g.id" :to="{name: baseRoute + '-group-details', params: {groupId: g.id}}">{{g.name}}</router-link>
      </template>
    </div> 
  </span>
  <span v-else class="tags">
    <template v-for="g in groups">
      <span v-if="[rootDeviceGroup.id, rootUserGroup.id].indexOf(g.id) !== -1" :key="g.id" class="root">{{g.name}}</span>
      <router-link v-else :key="g.id" :to="{name: baseRoute + '-group-details', params: {groupId: g.id}}">{{g.name}}</router-link>
    </template>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    ids: Array,
    baseRoute: String,
    type: {
      type: String,
      validator: function (value) {
        return value == null || [
          'device',
          'user',
        ].indexOf(value) !== -1
      }
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    sorted: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('Groups', ['getGroupById', 'rootUserGroup', 'rootDeviceGroup']),
    abbreviated () {
      return this.collapsed && Object.values(this.groups).length > 1
    },
    groups () {
      const groups = []

      this.ids.forEach(groupId => {
        const group = this.getGroupById(groupId)

        if (group == null || this.type != null && group.type != this.type) return

        groups.push(group)
      })

      if (groups.length === 0) {
        if (this.type !== 'device') groups.push(this.rootUserGroup)
        if (this.type !== 'user') groups.push(this.rootDeviceGroup)
      }

      return this.sorted ? groups.sort((a, b) => a.name < b.name ? -1 : 1) : groups
    },
    firstGroup () {
      return Object.values(this.groups).shift()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.tags {
  display: block;
  margin: -8px 0;
  line-height: 2.5;
}
.tags > span, .tags > a {
  display: inline-block;
  padding: 6px 6px;
  margin-right: 8px;
  border-radius: 3px;
  border: 2px solid $group-color;
  background-color: $group-color;
  color: $label-text;
  font-size: .875rem;
  line-height: 1.25;

  &:last-child {
    margin-right: 0;
  }
}

.tags > span.root {
  background-color: transparent;
  color: $label-text;
}

.tags > a:hover, .tags > a:focus {
  color: $primary-color;
}

.abbreviated {
  position: relative;

  > span {
    display: inline-block;
    position: relative;

    > span {
      box-shadow: 2px 2px $default-background;
    }

    &::after {
      position: absolute;
      top: 7px;
      right: -4px;
      bottom: -1px;
      z-index: -1;
      display: block;
      width: 100%;
      border-radius: 3px;
      background-color: $subgroup-color;
      content: "";
    }
  }
  > div {
    display: none;
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
  }

  &.last > div {
    left: auto;
    right: 0;
  }

  &:hover {
    > div {
      display: block;
      > span, > a {
        box-shadow: 0 1px 2px $dropshadow-color;
      }
    }
    > span {
      visibility: hidden;
    }
  }
}


</style>