<template>
  <main>
    <h1>Login</h1>
    <form @submit.prevent="authenticate" novalidate>
      <TextField required type="email" v-model.trim="email" :error="emailError" id="account-email" label="Email address" placeholder="Enter email…" />
      <TextField required type="password" v-model.trim="password" :error="passwordError" id="account-password" label="Password" placeholder="Enter password…" />

      <div class="field">
        <label class="checkbox"><input type="checkbox" v-model="persist" /><span> Keep me logged in</span></label>
      </div>

      <p class="actions"><button class="action" type="submit" :disabled="submitting">Let&rsquo;s go</button></p>
      <p class="links"><router-link :to="{name: 'forgotten-password'}">Forgot password?</router-link></p>
    </form>

    <div v-if="externalAuth" class="container">
      <span>Alternatively, you can sign in with</span>
      <div class="alternatives">
        <button @click="signInWith('google')" :disabled="submitting">
          <GoogleSVG />Google
        </button>
        <button @click="signInWith('facebook')" :disabled="submitting">
          <FacebookSVG />Facebook
        </button>
        <button :disabled="submitting" @click="signInWith('apple')">
          <AppleSVG />Apple
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TextField from 'elements/TextField'
import GoogleSVG from 'elements/GoogleSVG.vue'
import FacebookSVG from 'elements/FacebookSVG.vue'
import AppleSVG from 'elements/AppleSVG.vue'

import * as firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: { TextField, GoogleSVG, FacebookSVG, AppleSVG },
  data () {
    return {
      submitting: false,
      externalAuth: false,
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      persist: false
    }
  },
  created () {
    if (firebase.auth().currentUser != null) {
      this.goNext()
    }

    const query = this.$route.query

    if (query.email != null) {
      this.email = query.email
    }

    if ( query.response_type === 'code'
      && query.client_id != null
      && query.redirect_uri != null
    ) {
      this.externalAuth = true
    }
  },
  watch: {
    email(_, __) { this.emailError = '' },
    password(_, __) { this.passwordError = '' },
    // FIXME: If the fleetId is not NULL, the user has signed in; but there must be a cleaner way...
    fleetId (to, __) {
      if (to != null) this.goNext()
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetId', 'fleets', 'hasFeatures', 'hasSetup'])
  },
  methods: {
    ...mapActions('Notifications', ['showWarningMessage', 'showErrorMessage']),
    goNext () {
      // Handle capturing important settings
      if (this.hasFeatures.reimbursements && !this.hasSetup.directDebit) {
        this.$router.replace({name: 'direct-debit'})
      }
      else if (this.hasFeatures.reimbursementSettings && !this.hasSetup.reimbursementSettings) {
        this.$router.replace({name: 'reimbursement-settings'})
      }
      // Redirect straight to dashboard
      else if (this.$route.query.redirect == null) {
        this.$router.replace({name: 'dashboard', params: {fleetId: this.fleetId}})
      }
      // Handle internal redirects
      else {
        const targetPath = this.$route.query.redirect
        const pathParts = targetPath.match(/^(\/fleet\/)(.*?)(\/.*)/)
        
        // If not a fleet-specific path, redirect safely
        if (pathParts == null) {
          this.$router.replace(targetPath)
          return
        }
        
        // Determine, if the user has permissions to see a fleet-specific path
        const targetFleetId = pathParts[2]
        const defaultFleetId = this.fleetId
        const validFleetIds = Object.values(this.fleets).map(f => f.id)

        // validFleetIds.push(defaultFleetId)

        // If the fleet id is valid, redirect safely
        if (validFleetIds.indexOf(targetFleetId) !== -1) {
          this.$router.replace(targetPath)
        }
        // Otherwise, redirect to the same path with a default fleet id
        else {
          this.$router.replace(pathParts[1] + defaultFleetId + pathParts[3])
        }
      }
    },
    authenticate () {
      this.emailError = ''
      this.passwordError = ''

      if (this.email.length == 0) {
        this.emailError = 'Please enter your email address'
      }
      else if (this.password.length == 0) {
        this.passwordError = 'Please enter your password'
      }
      else {
        this.submitting = true
        firebase.auth().setPersistence(this.persist == true
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION
        )
        firebase.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        // NB! The watcher for fleetId does the redirection.
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-email':
              this.emailError = 'Email address appears to be invalid'
              break
            case 'auth/user-disabled':
              this.emailError = 'Email address has been disabled'
              break
            case 'auth/user-not-found':
              this.emailError = 'Email address is not in our records'
              break
            case 'auth/wrong-password':
              this.passwordError = 'Wrong password'
              break
          }

          if (this.emailError.length == 0 && this.passwordError.length == 0) {
            this.showErrorMessage(`Could not login: ${error.message}`)
          }
        })
        .finally(() => this.submitting = false)
      }
    },
    signInWith (name) {
      let provider

      switch (name) {
        case 'google':
          provider = new firebase.auth.GoogleAuthProvider()
          break

        case 'facebook':
          provider = new firebase.auth.FacebookAuthProvider()
          break

        case 'apple':
          provider = new firebase.auth.OAuthProvider("apple.com")
          break
      
        default:
          return
      }

      firebase.auth().signInWithRedirect(provider)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

h1 {
  margin-bottom: 46px;
}
p.actions {
  margin-bottom: 25px;
}
p.links {
  font-weight: 700;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  span {
    text-align: center;
  }
}

div.alternatives {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 16px;
    border-radius: 23px;
    border-width: 2px;
    font-weight: 700;
    cursor: pointer;
    &:disabled {
      opacity: 0.75;
      cursor: default;

      &:hover,
      &:focus {
        border-color: $border-color;
      }
    }
  }

  button svg {
    width: 16px;
    height: 16px;
  }

  button span {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>