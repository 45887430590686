<template>
  <ul>
    <li v-if="length > 0" :class="{applied: applied, passed: lengthPassed}">At least {{length}} character{{length > 1 ? 's' : ''}} long</li>
    <li v-if="letters" :class="{applied: applied, passed: letterPassed }">At least 1 {{uppercase? 'lowercase' : ''}} letter (a&ndash;z)</li>
    <li v-if="uppercase" :class="{applied: applied, passed: uppercasePassed }">At least 1 uppercase letter (A&ndash;Z)</li>
    <li v-if="numbers" :class="{applied: applied, passed: numberPassed }">At least 1 number (0&ndash;9)</li>
    <li v-if="special.length > 0" :class="{applied: applied, passed: specialPassed }">At least 1 special character ({{special.join(', ')}})</li>
  </ul>
</template>

<script>
export default {
  props: {
    password: String,

    length: { type: Number, default: 8},
    numbers: {type: Boolean, default: true},
    letters: {type: Boolean, default: true},
    uppercase: {type: Boolean, default: false},
    special: {type: Array, default: () => []}
  },
  watch: {
    password (_, __) {
      if (!this.applied) {
        this.$emit('change', false);
        return
      }

      const isStrong =
        (this.length == 0 || this.lengthPassed) &&
        (!this.letters || this.letterPassed) &&
        (!this.uppercase || this.uppercasePassed) &&
        (!this.numbers || this.numberPassed) &&
        (this.special.length == 0 || this.specialPassed)

      this.$emit('change', isStrong);
    }
  },
  computed: {
    applied () {
      return this.password.length > 0
    },
    lengthPassed () {
      return this.password.length >= this.length
    },
    numberPassed () {
      return /[0-9]/.test(this.password)
    },
    letterPassed () {
      if (this.uppercase)
        return /[a-z]/.test(this.password)
      else 
        return /[a-z]/i.test(this.password)
    },
    uppercasePassed () {
      return /[A-Z]/.test(this.password)
    },
    specialPassed () {
      return this.password.split('').reduce((r, c) => this.special.indexOf(c) > -1 || r, false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

ul {
  font-size: .8125rem;
}

li {
  position: relative;
  padding-left: 20px;
  margin-top: 8px;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $neutral-color;
    content: "";
  }

  &.applied::before { background: $error-color }
  &.applied.passed::before { background: $success-color }
}
</style>