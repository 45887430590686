<template>
  <Sidebar v-if="charger" :back="baseRoute" :key="'charger-' + charger.id">
    <header>
      <h2>{{charger.name}}</h2>
    </header>

    <template v-slot:controls>
      <transition name="bottom-sheet">
        <div v-if="!confirmRemoval" class="choose-action">
          <p class="actions sheet">
            <router-link tag="button" class="action" :to="{name: baseRoute + '-edit-charger', params: {chargerId: charger.id}, query: {return: baseRoute + '-charger-details'}}">Edit charger</router-link>
            <button class="action delete" @click="confirmRemoval = true">Remove</button>
          </p>
        </div>
      </transition>

      <transition name="bottom-sheet">
        <Overlay v-if="confirmRemoval" @close="confirmRemoval = false">
          <div class="confirm-removal sheet">
            <h3>Are you sure you want to remove this charger?</h3>
            <p>Removing the charger from the fleet may lose some of its data, including statistics. Users may not be able to use this charger.</p>
            <div class="actions">
              <button class="action" @click="removeCharger">Remove charger</button>
              <button class="cancel" @click="confirmRemoval = false">Cancel</button>
            </div>
          </div>
          <div class="shade" />
        </Overlay>
      </transition>
    </template>

    <section class="charger details">
      <ul>
        <li>
          <span class="label">Serial ID</span><span class="invisible">:</span>
          <var>{{charger.serialId}}</var>
        </li>
        <li v-if="charger.model">
          <span class="label">Charger model</span><span class="invisible">:</span>
          <button :class="{inline: true, open: specsVisible}" @click="specsVisible = !specsVisible">{{`${charger.model.make} ${charger.model.model}`}}</button>
        </li>
      </ul>
      <transition name="reveal"><ul class="specs" v-if="charger.model && specsVisible">
        <li v-if="charger.model.connector">
          <span class="label">Connector</span><span class="invisible">:</span>
          {{charger.model.connector}}
        </li>
        <li v-if="charger.model.maxPower">
          <span class="label">Max power</span><span class="invisible">:</span>
          <Amount :value="charger.model.maxPower" type="power" />
        </li>
        <li v-if="charger.model.phases">
          <span class="label">No. Phases</span><span class="invisible">:</span>
          {{charger.model.phases}}
        </li>
        <li v-if="charger.model.sockets">
          <span class="label">No. Sockets</span><span class="invisible">:</span>
          {{charger.model.sockets}}
        </li>
      </ul></transition>
      <ul>
        <li v-if="product">
          <span class="label">Default <abbr title="Pay As You Go">PAYG</abbr> package</span><span class="invisible">:</span>
          <router-link :to="{name: baseRoute + '-package-details', params: {productId: product.id}}">{{product.name}}</router-link>
        </li>
        <li v-if="charger.locationType">
          <span class="label">Type</span><span class="invisible">:</span>
          {{charger.locationType | capitalize}}
        </li>
      </ul>

      <aside :class="{reboot: true, error: hasErrors}">
        <transition name="inner-sheet">
        <div v-if="!confirmRebooting" class="command">
          <h3 v-if="hasErrors">Error</h3>
          <h3 v-else>Rebooting charger</h3>
          <p v-if="hasErrors">We&rsquo;ve noticed a problem with this charger. Rebooting the charger might help.</p>
          <p v-else>If you are experiencing an issue with your charger, rebooting it might help.</p>
          <button :disabled="rebooting" class="action secondary small" @click="confirmRebooting = true">{{rebooting? 'Rebooting' : 'Reboot'}}</button>
        </div>
        </transition>
        <transition name="inner-sheet">
        <Overlay v-if="confirmRebooting" class="confirm" @close="confirmRebooting = false">
          <h3>Confirmation is required</h3>
          <p>Are you sure you want to reboot this charger?</p>
          <div class="actions">
            <button class="action small" @click="rebootCharger(); confirmRebooting = false">Reboot charger</button>
            <button class="cancel small" @click="confirmRebooting = false">Cancel</button>
          </div>
        </Overlay>
        </transition>
      </aside>

    </section>

    <section v-if="charger.sockets" class="sockets details">
      <h3>Sockets</h3>

      <ol>
        <li v-for="s in charger.sockets" :key="s.id">
          <transition name="inner-sheet">
          <div v-if="!confirmUnlocking.includes(s.id)" class="command">
            <p><var class="id">{{s.externalId}}</var> <Socket class="status" name="1" :status="s.status" :labelled="true" /></p>
            <button :disabled="unlocking.includes(s.id)" v-if="s.status == 'charging'" class="action secondary small" @click="confirmUnlockingSocket(s.id)">{{unlocking.includes(s.id)? 'Unlocking' : 'Unlock'}}</button>
          </div>
          </transition>
          <transition name="inner-sheet">
          <Overlay v-if="confirmUnlocking.includes(s.id)" class="confirm" @close="closeUnlockingConfirmation(s.id)">
            <h3>Confirmation is required</h3>
            <p>Do you want to stop charging and unlock this socket?</p>
            <div class="actions">
              <button class="action small" @click="unlockSocket(s.id); closeUnlockingConfirmation(s.id)">Unlock socket</button>
              <button class="cancel small" @click="closeUnlockingConfirmation(s.id)">Cancel</button>
            </div>
          </Overlay>
          </transition>
        </li>
      </ol>
    </section>

    <section v-if="address || location" class="location details">
      <h3>Location</h3>

      <ul>
        <li v-if="address">
          <span class="label">Address</span><span class="invisible">:</span>
          {{address}}
        </li>
        <li v-if="location">
          <span class="label">Coordinates</span><span class="invisible">:</span>
          {{location}}
        </li>
      </ul>
    </section>

    <section v-if="charger.groupIds.length > 0" class="groups details">
      <h3>Charger groups</h3>

      <p><GroupTags type="device" :baseRoute="baseRoute" :ids="charger.groupIds" /></p>
    </section>
  </Sidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { fraction } from '~/filters'
import { unlockChargerSocket, rebootChargerHard } from '~/api'

import Amount from 'elements/Amount'
import Sidebar from 'elements/Sidebar'
import Socket from 'elements/Socket'
import GroupTags from 'elements/GroupTags'
import Overlay from 'elements/Overlay'

export default {
  components: { Amount, Sidebar, Socket, GroupTags, Overlay },
  props: {
    baseRoute: String
  },
  data () {
    return {
      specsVisible: false,
      confirmRemoval: false,
      confirmRebooting: false,
      rebooting: false,
      unlocking: [],
      confirmUnlocking: [],
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetId']),
    ...mapGetters('Products', ['getProductById']),
    ...mapGetters('Chargers', ['getChargerById']),
    product () {
      return this.getProductById(this.charger.paygProductId)
    },
    charger () {
      return this.getChargerById(this.$route.params.chargerId)
    },
    hasErrors () {
      return this.charger.sockets.reduce((r, s) => r || s.status === 'error', false)
    },
    address () {
      const result = []
      const address = this.charger.address

      if (address.streetNumber || address.streetName) result.push(address.streetNumber + ' ' + address.streetName)
      if (address.city) result.push(address.city)
      if (address.postcode) result.push(address.postcode)

      return result.map((p) => p.trim()).join(', ')
    },
    location () {
      const result = []
      const location = this.charger.location

      if (location.latitude) result.push(fraction(location.latitude, 6))
      if (location.longitude) result.push(fraction(location.longitude, 6))

      return result.map((p) => p.trim()).join(', ')
    }
  },
  methods: {
    ...mapActions('Chargers', ['deleteCharger']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),
    
    confirmUnlockingSocket (socketId) {
      this.confirmUnlocking.push(socketId)
    },
    closeUnlockingConfirmation (socketId) {
      const i = this.confirmUnlocking.indexOf(socketId)
      if (i > -1) this.confirmUnlocking.splice(i, 1)
    },
    unlockSocket (socketId) {
      this.unlocking.push(socketId)
      unlockChargerSocket(this.fleetId, socketId)
      .then(() => this.showSuccessMessage('The socket has been unlocked'))
      .catch(() => this.showErrorMessage('The socket could not be unlocked'))
      .finally(() => {
        const i = this.unlocking.indexOf(socketId)
        if (i > -1) this.unlocking.splice(i, 1)
      })
    },
    rebootCharger () {
      this.rebooting = true
      // FIXME: Rebooting charger by socket ID seems lame
      rebootChargerHard(this.fleetId, this.charger.sockets[0].id)
      .then(() => this.showSuccessMessage('The charger has been rebooted'))
      .catch(() => this.showErrorMessage('The charger could not be rebooted'))
      .finally(() => this.rebooting = false)
    },
    removeCharger () {
      this.deleteCharger(this.charger)
      .then(() => {
        this.$router.replace({name: this.baseRoute})
        this.showSuccessMessage('The charger has been removed')
      })
      .catch(() => {
        this.showErrorMessage('The charger could not be removed')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

header {
  h2 { 
    @include icon-left (13, 2);
    &::before { left: -5px; }
  }
}

aside.reboot, section.sockets > ol > li {
  h3 {
    position: absolute;
    left: -999em;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.2143;
  }

  overflow: hidden;
  position: relative;
  margin: 25px 0;
  background: $light-background;

  .command, .confirm {
    border-radius: 6px;
    padding: 12px 20px;
  }

  .command {
    padding-right: 125px;

    button {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -17px;
    }
  }

  .confirm {
    padding-right: 12px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    > .actions {
      position: relative;
      z-index: 4;
      display: flex;
      flex-flow: row nowrap;
      margin-left: 7px;

      > button + button { 
        margin-left: 7px;
      }
    }
  }

  &.error {
    .command, .confirm {
      background-color: $danger-background;
    }

    button {
      color: $light-text;
      border-color: $danger-color;
      background: $danger-color;

      &:disabled {
        border-color: $danger-disabled-color;
        background-color: $danger-disabled-color;
      }
    }

    button.cancel {
      background: $default-background;
      color: $danger-color;
    }
  }
}

section.sockets {
  h3 {
    @include icon-left (14, 3);
    &::before { left: -4px; }
  }

  ol {
    margin-top: 22px;
    margin-bottom: 22px;

    li {
      border: 0;
      border-radius: 6px;
      background: $light-background;
      margin: 0 0 8px;
    }
  }
  
  .command > p {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 34px;

    .id, .status {
      display: inline-block;
    }
    .id {
      min-width: 170px;
      color: $bold-text;
      font-size: 0.875rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    .status {
      margin-left: 32px;
    }
  }
}

section.charger {
  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      flex: 0 1 calc(50% - 25px);
    }
  }

  ul.specs {
    display: flex;
    flex-flow: row wrap;

    li {
      flex: 0 1 calc(25% - 25px);
    }
  }

  button.inline {
    position: relative;
    padding-right: 12px;

    &::after {
      position: absolute;
      right: 0;
      top: 8px;
      border-top: 6px solid $primary-color;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: ''
    }

    &.open::after {
      top: 7px;
      border-top: 0;
      border-bottom: 6px solid $primary-color;
    }
  }
}
</style>