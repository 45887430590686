<template>
  <WelcomeLayout>
    <transition name="dive" appear><main v-if="!hidden" class="form reverse static">
      <h1>Please enter your organisation&rsquo;s bank information</h1>
      <p class="instruction">We need this info to ensure your fleet drivers are paid on time.</p>
      <StripeIBAN actionLabel="Save and Continue" :skippable="true" @skipped="onSkip" @failed="onIBANFailure" @succeeded="onIBANSuccess" />
    </main></transition>
  </WelcomeLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import WelcomeLayout from 'layouts/Welcome'
import StripeIBAN from 'elements/StripeIBAN'

export default {
  components: { StripeIBAN, WelcomeLayout },
  data () {
    return {
      hidden: false,
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetId', 'hasFeatures', 'hasSetup']),
  },
  methods: {
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),
    ...mapActions('Fleet', ['updateBankDetails']),
    onSkip () {
      this.goNext(true)
    },
    onIBANSuccess (result) {
      this.hidden = true
      this.updateBankDetails()
      .then(() => {
        setTimeout(() => {
          this.goNext()
        }, 600)
      })
      .catch((error) => {
        this.showErrorMessage(`Could not set your payment method: ${error.message}`)
      })
    },
    onIBANFailure (error) {
      this.showErrorMessage(`Could not set your payment method: ${error.message}`)
    },
    goNext (skipped) {
      if (this.hasFeatures.reimbursementSettings && !this.hasSetup.reimbursementSettings) {
        this.$router.push({name: 'reimbursement-settings'})
      }
      else {
        if (skipped !== true) {
          this.showSuccessMessage(`Thank you for completing your profile!`)
        }
        this.$router.push({name: 'dashboard', params: {fleetId: this.fleetId}})
      }
    }
  }
}
</script>

