<template>
  <span :class="[status]">
    <span :class="{indicator: true, interactable: !labelled}">Socket {{name}}: </span>
    <span :class="{label: true, visible: labelled}">{{status}}</span>
  </span>
</template>

<script>
const validStatuses = [
  'available',  // green
  'reserved',   // blue
  'suspended',  // grey
  'preparing',  // blue animating
  'charging',   // blue animating
  'finishing',  // blue animating
  'unavailable',// grey
  'offline',    // grey
  'error',      // red
]

export default {
  props: {
    name: String,
    status: {
      type: String,
      validator: function (value) {
        return validStatuses.indexOf(value) !== -1
      }
    },
    labelled: Boolean
  },
  // data () {
  //   return {
  //     statusD: this.status
  //   }
  // },
  // methods: {
  //   nextStatus () {
  //     let index = validStatuses.indexOf(this.statusD)

  //     if (++index >= validStatuses.length) {
  //       index = 0
  //     }

  //     this.statusD = validStatuses[index]
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

$transition-duration: 0.1s;

span {
  position: relative;

  .indicator {
    overflow: hidden;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 3px solid $offline-color;
    background-color: rgba(255, 255, 255, 0.001);
    text-indent: -999em;
    vertical-align: -12%;
    transition-property: background-color, border-color;
    transition-duration: $transition-duration;

    &.interactable {
      cursor: help;
    }
  }

  &.available .indicator {
    border-color: $available-color;
  }
  &.reserved .indicator {
    border-color: $charging-color;
  }
  &.preparing .indicator,
  &.finishing .indicator,
  &.charging .indicator {
    background-color: $charging-color;
    border-color: $charging-color;

    &::before {
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: -2px;
      display: block;
      width: 40px;
      background-color: $light-background;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAoCAYAAAD+MdrbAAAAgElEQVR4AWOAgFEwCkbBKBgFiiknvwDxXSBeoJR6ypRU/SA9IL1QM74ADTzxH45TT/5VTjtZRqxhILUgPchmgA1Ex0qpJzsbGv4z4TIIJAdSA1OP30CEoaeALohXyzytpFdykRuEQWyQGEgOpAa3gZTjUQNHDRw1cNTAUQNHDQQA5YcEdaYOEiQAAAAASUVORK5CYII=);
      background-size: 10px 20px;
      background-position: 0 -3px;
      background-repeat: repeat-x;
      content: "";
      animation: 1.5s linear 0s infinite running scrolling;
      transform-origin: center;
    }
    &::after {
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: 8px;
      border: 3px solid $charging-color;
      background: transparent;
      content: "";
    }
  }
  &.error .indicator { 
    border: 0;
    background-color: $error-color;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      height: 1rem;
      width: 1rem;
      color: $light-text;
      font-size: .75rem;
      font-weight: 700;
      line-height: 1.35;
      text-align: center;
      text-indent: 0;
      content: "!";
    }

  }

  .label {
    position: absolute;
    left: -999em;
    margin-left: 2px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: capitalize;
    transition-property: color;
    transition-duration: $transition-duration;

    &.visible {
      position: static;
      vertical-align: 5%;
    }
  }

  &.available .label.visible { color: $available-color }
  &.charging .label.visible { color: $charging-color }
  &.reserved .label.visible { color: $charging-color }
  &.preparing .label.visible { color: $charging-color }
  &.finishing .label.visible { color: $charging-color }
  &.offline .label.visible { color: $deemphasized-text }
  &.unavailable .label.visible { color: $deemphasized-text }
  &.suspended .label.visible { color: $deemphasized-text }
  &.error .label.visible { color: $error-color }

  .indicator.interactable:hover + .label {
    left: -29px;
    top: 28px;
    width: 60px;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 1px 2px $dropshadow-color;
    background-color: $menu-background;
    text-align: center;

    &::before {
      position: absolute;
      top: -6px;
      left: 29px;
      border-bottom: 6px solid $menu-background;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      content: ""
    }
  }
}

@keyframes scrolling {
  $twist: 7deg;
  0% {
    transform: translateX(0) rotate(0)
  }
  25% {
    transform: translateX(-5px) rotate($twist / 2)
  }
  50% {
    transform: translateX(-10px) rotate($twist)
  }
  75% {
    transform: translateX(-15px) rotate($twist / 2)
  }
  100% {
    transform: translateX(-20px) rotate(0)
  }
}
</style>