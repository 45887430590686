<template>
  <span><span v-if="amount < 0" class="sign">&minus;</span><span v-if="before" :class="[type, 'unit']">{{before}}</span>{{amount | absolute | decimal(places)}}<span v-if="after" :class="[type, 'unit']">{{after}}</span><span class="unit" v-if="this.denominator">/{{this.denominator}}</span></span>
</template>

<script>
import { currencies } from '~/currencies.js'

export default {
  props: {
    code: {
      type: String,
      validator: function (value) {
        return Object.keys(currencies).indexOf(value) !== -1
      }
    },
    value: Number,
    minor: Boolean,
    decimal: Number,
    denominator: String,
  },
  computed: {
    currency () {
      return currencies[this.code]
    },
    symbol () {
      return this.minor? this.currency.minor : this.currency.major
    },
    amount () {
      return this.minor? this.value * this.currency.divisor : this.value
    },
    type () {
      return this.minor? 'currency' : 'currency_small'
    },
    before () {
      return this.symbol.prefix == true? this.symbol.symbol : ''
    },
    after () {
      return this.symbol.prefix == false? this.symbol.symbol : ''
    },
    places () {
      if (this.decimal) return this.decimal
      else return this.minor? 0 : Math.ceil(Math.log10(this.currency.divisor))
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  white-space: nowrap;
}
</style>