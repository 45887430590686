<template>
  <Dialog>
    <main :class="['form', dialogDepth]"><form @submit.prevent="save" novalidate>
      <section class="fieldset owner">
        <h2 class="legend">Driver details</h2>

        <TextField required type="text" id="full-name" v-model.trim="fullName" :error="fullNameError" label="Name" placeholder="Enter driver’s full name" />
        <TextField type="text" id="license-number" v-model.trim="driverLicense" :error="driverLicenseError" label="Driving license" placeholder="Enter driving license number" />
        <TextField required :disabled="!invited" type="email" id="email-address" v-model.trim="emailAddress" :error="emailAddressError" label="Email address" placeholder="Enter email address" />
        <TextField :disabled="!invited" type="text" id="cable-id" v-model.trim="cableId" :error="cableIdError" label="Cable ID" :placeholder="!!userId ? 'Unknown' : 'Enter cable ID'" />
        <template v-if="wholeHomeAddress.length > 0 || wholeOfficeAddress.length > 0">
          <TextField type="text" id="home-address-whole" v-model.trim="wholeHomeAddress" :error="wholeHomeAddressError" label="Whole home address" placeholder="DO NOT FILL: Use address fields below" />
          <TextField type="text" id="office-address-whole" v-model.trim="wholeOfficeAddress" :error="wholeOfficeAddressError" label="Whole office address" placeholder="DO NOT FILL: Use address fields below" />
        </template>
        <AddressField :required="false" id="home-address" v-model="homeAddress" :error="homeAddressError" label="Home address" />
        <AddressField :required="false" id="office-address" v-model="officeAddress" :error="officeAddressError" label="Office address" />

        <TextField type="text" id="phone" v-model.trim="phoneNumber" :error="phoneNumberError" label="Phone" placeholder="Enter phone number" />
        <TextField type="text" id="external-id" v-model.trim="externalId" :error="externalIdError" label="External user ID" placeholder="Enter external user ID" />
      </section>

      <section class="fieldset group">
        <h2 class="legend"><label for="user-group">Driver groups</label></h2>
        <div class="field groups">
          <GroupSelect class="options" type="user" multiple v-model="groupIds" />
          <button type="button" class="add-new" @click="openChildDialog('add-driver-group')">Add driver group</button>
        </div>
      </section>

      <section v-if="hasFeatures.reimbursementSettings" class="fieldset vertical reimbursement">
        <h2 class="legend">Reimbursements</h2>
        
        <RadioField
          required
          v-model="reimbursementSettlement"
          :error="reimbursementSettlementError"
          id="reimbursement-settlement"
          label="Settlement type"
          :options="{
            'bank': 'Direct bank transfer',
            'utility': 'Utility transfer'
          }"
        />

        <RadioField v-if="reimbursementSettlement === 'bank'"
          required
          v-model="reimbursementType"
          :error="reimbursementTypeError"
          id="reimbursement-type"
          label="Reimbursement type"
          :options="{
            'flat-rate': {label: 'Flat rate', legend: 'A set cost the organisation will reimbursement the driver.'},
            'mileage': {label: 'Mileage', legend: 'Driver submits a breakdown of personal and business miles on the app each month.'},
            'energy-cost': {label: 'Energy cost', legend: 'Exact rate driver pays for home electricity.'}
          }"
        />

        <div v-if="reimbursementType === 'flat-rate' && reimbursementSettlement === 'bank'" class="field amount">
          <label for="flat-rate-amount">Flat rate amount</label>
          <CurrencyInput
            id="flat-rate-amount"
            v-model="reimbursementflatRatePerKWh"
            :code="reimbursementflatRatePerKWhCurrency"
            unit-label="per kWh"
            :decimal="4"
            required
          />
          <transition name="show-error">
            <label class="error" for="flat-rate-amount" v-if="reimbursementflatRatePerKWhError.length > 0">{{reimbursementflatRatePerKWhError}}</label>
          </transition>
        </div>
      </section>

      <section v-if="invited" class="message">
        <p>Car, cable and tariff details will be set by the driver when signing up in the app.</p>
      </section>

      <section v-if="!invited" class="fieldset car">
        <h2 class="legend">Car details</h2>

        <TextField class="car-make" required disabled type="text" id="car-make" v-model.trim="carMake" :error="carMakeError" label="Car make" placeholder="Unknown" />
        <TextField class="car-model" required disabled type="text" id="car-model" v-model.trim="carModel" :error="carModelError" label="Car model" placeholder="Unknown" />
        <TextField class="car-year" required disabled type="text" id="car-year" v-model.trim="carYear" :error="carYearError" label="Year" placeholder="Unknown" />
        <TextField class="car-reg" type="text" id="car-reg" v-model.trim="carRegistration" :error="carRegistrationError" label="Registration number" placeholder="Enter car registration" />
        <TextField class="notes" type="text" id="notes" v-model.trim="carNotes" :error="carNotesError" label="Notes" placeholder="Optionally enter any notes" />
      </section>

      <section v-if="!invited" class="fieldset charging">
        <h2 class="legend">Charging</h2>

        <TextField required disabled type="text" id="home-tariff" v-model.trim="homeTariff" :error="homeTariffError" label="Home electricity tariff" placeholder="Unknown" />
        <TextField type="text" id="fuel-card" v-model.trim="fuelCard" :error="fuelCardError" label="Fuel card" placeholder="Enter fuel card" />
        <TextField type="text" id="rfid-number" v-model.trim="RFID" :error="RFIDError" label="RFID number" placeholder="Enter RFID number" />
      </section>

      <div class="choose-action">
        <p class="actions sheet">
          <button class="action" :disabled="saving" type="submit">{{userId != null ? 'Save changes' : 'Add driver'}}</button>
          <button class="action secondary" :disabled="saving" type="button" @click="closeDialog">Cancel</button>
        </p>
      </div>
    </form></main>

    <transition name="child-dialog">
      <GroupEditor
        v-if="isChildDialogOpened('add-driver-group')"
        :chain="parentChain()"
        :params="{
          groupType: 'driver'
        }"
        @complete="completeChildDialog"
        @close="closeChildDialog"
      />
    </transition>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { Dialog, DialogChainNode } from 'elements/Dialog'
import GroupSelect from 'elements/GroupSelect'
import CurrencyInput from 'elements/CurrencyInput'
import TextField from 'elements/TextField'
import RadioField from 'elements/RadioField'
import Parenthetical from 'elements/Parenthetical'

import AddressField from 'elements/FullAddressField'
import GroupEditor from 'sections/GroupEditor.vue'

export default {
  mixins: [DialogChainNode],
  components: {
    Dialog,
    GroupSelect,
    Parenthetical,
    CurrencyInput,
    TextField,
    RadioField,
    AddressField,

    GroupEditor,
  },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      saving: false,
      invited: true,
      openedDialog: null,
      
      externalId: '',
      fullName: '',
      emailAddress: '',
      driverLicense: '',
      phoneNumber: '',

      homeAddress: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        postcode: '',
        country: ''
      },
      wholeHomeAddress: '',

      officeAddress: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        postcode: '',
        country: ''
      },
      wholeOfficeAddress: '',

      externalIdError: '',
      fullNameError: '',
      emailAddressError: '',
      driverLicenseError: '',
      phoneNumberError: '',
      homeAddressError: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
      },
      wholeHomeAddressError: '',

      officeAddressError: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
      },
      wholeOfficeAddressError: '',

      groupIds: [],

      carMake: '',
      carModel: '',
      carYear: '',
      carRegistration: '',
      carNotes: '',

      carMakeError: '',
      carModelError: '',
      carYearError: '',
      carRegistrationError: '',
      carNotesError: '',

      homeTariff: '',
      cableId: '',
      fuelCard: '',
      RFID: '',

      homeTariffError: '',
      cableIdError: '',
      fuelCardError: '',
      RFIDError: '',

      reimbursementSettlement: '',
      reimbursementType: '',
      reimbursementflatRatePerKWh: 0,
      reimbursementflatRatePerKWhCurrency: 'GBP',

      reimbursementSettlementError: '',
      reimbursementTypeError: '',
      reimbursementflatRatePerKWhError: '',
    }
  },
  created () {
    if (this.userId == null) {
      if (this.hasFeatures.reimbursementSettings) {
        this.reimbursementSettlement = this.fleetReimbursementSettings.settlementType
        this.reimbursementType = this.fleetReimbursementSettings.reimbursementType
        this.reimbursementflatRatePerKWh = this.fleetReimbursementSettings.flatRatePerKWh.amount
        this.reimbursementflatRatePerKWhCurrency = this.fleetReimbursementSettings.flatRatePerKWh.currency
          || this.fleetCurrencyCode
          || this.currencyCode
      }
      return
    }

    const user = this.getUserById(this.userId)
    this.invited = user.invited

    this.externalId = user.externalId
    this.fullName = user.name
    this.emailAddress = user.email
    this.driverLicense = user.driverLicence
    this.phoneNumber = user.phone

    if (this.homeAddress != null) {
      this.homeAddress.street1 = user.homeAddress.street1
      this.homeAddress.street2 = user.homeAddress.street2
      this.homeAddress.city = user.homeAddress.city
      this.homeAddress.state = user.homeAddress.state
      this.homeAddress.postcode = user.homeAddress.postcode
      this.homeAddress.country = user.homeAddress.country
      
      this.wholeHomeAddress = user.homeAddress.wholeAddress
    }

    if (user.officeAddress != null) {
      this.officeAddress.street1 = user.officeAddress.street1
      this.officeAddress.street2 = user.officeAddress.street2
      this.officeAddress.city = user.officeAddress.city
      this.officeAddress.state = user.officeAddress.state
      this.officeAddress.postcode = user.officeAddress.postcode
      this.officeAddress.country = user.officeAddress.country

      this.wholeOfficeAddress = user.officeAddress.wholeAddress
    }

    this.carMake = user.car.make
    this.carModel = user.car.model
    this.carYear = user.car.year
    this.carRegistration = user.car.registration
    this.carNotes = user.car.notes

    this.homeTariff = user.tariff.name
    this.cableId = user.cable.id
    this.fuelCard = user.fuelCard
    this.RFID = user.RFID
    this.groupIds = user.groupIds.slice()

    if (this.hasFeatures.reimbursementSettings) {
      const rs = user.reimbursementSettings
      this.reimbursementSettlement = rs.settlementType
        || this.fleetReimbursementSettings.settlementType
      this.reimbursementType = rs.reimbursementType
        || this.fleetReimbursementSettings.reimbursementType
      this.reimbursementflatRatePerKWh = rs.flatRatePerKWh.amount
        || this.fleetReimbursementSettings.flatRatePerKWh.amount
      this.reimbursementflatRatePerKWhCurrency = rs.flatRatePerKWh.currency 
        || this.fleetReimbursementSettings.flatRatePerKWh.currency
        || this.fleetCurrencyCode
        || this.currencyCode
    }
  },
  watch: {
    // FIXME: Reset error on entry should be done on the component level
    externalId(_, __) { this.externalIdError = '' },
    fullName(_, __) { this.fullNameError = '' },
    emailAddress(_, __) { this.emailAddressError = '' },
    driverLicense(_, __) { this.driverLicenseError = '' },
    phoneNumber(_, __) { this.phoneNumberError = '' },
    'wholeHomeAddress': function (_, __) { this.wholeHomeAddressError = '' },
    'homeAddress.street1': function (_, __) { this.homeAddressError.street1 = '' },
    'homeAddress.street2': function (_, __) { this.homeAddressError.street2 = '' },
    'homeAddress.city': function (_, __) { this.homeAddressError.city = '' },
    'homeAddress.state': function (_, __) { this.homeAddressError.state = '' },
    'homeAddress.postcode': function (_, __) { this.homeAddressError.postcode = '' },
    'homeAddress.country': function (to, __) {
      if (to != 'US') this.homeAddressError.state = ''
      this.homeAddressError.country = ''
    },
    'wholeOfficeAddress': function (_, __) { this.wholeOfficeAddressError = '' },
    'officeAddress.street1': function (_, __) { this.officeAddressError.street1 = '' },
    'officeAddress.street2': function (_, __) { this.officeAddressError.street2 = '' },
    'officeAddress.city': function (_, __) { this.officeAddressError.city = '' },
    'officeAddress.state': function (_, __) { this.officeAddressError.state = '' },
    'officeAddress.postcode': function (_, __) { this.officeAddressError.postcode = '' },
    'officeAddress.country': function (to, __) { 
      if (to != 'US') this.officeAddress.state = ''
      this.officeAddressError.country = ''
    },
    carMake(_, __) { this.carMakeError = '' },
    carModel(_, __) { this.carModelError = '' },
    carYear(_, __) { this.carYearError = '' },
    carRegistration(_, __) { this.carRegistrationError = '' },
    carNotes(_, __) { this.carNotesError = '' },
    homeTariff(_, __) { this.homeTariffError = '' },
    cableId(_, __) { this.cableIdError = '' },
    fuelCard(_, __) { this.fuelCardError = '' },
    RFID(_, __) { this.RFIDError = '' },
    reimbursementSettlement(_, __) { this.reimbursementSettlementError = '' },
    reimbursementType(_, __) { this.reimbursementTypeError = '' },
    reimbursementflatRatePerKWh(_, __) { this.reimbursementflatRatePerKWhError = '' },
  },
  computed: {
    ...mapGetters(['currencyCode']),
    ...mapGetters('Fleet', ['fleetCurrencyCode', 'fleetId', 'hasFeatures', 'fleetReimbursementSettings']),
    ...mapGetters('Users', ['getUserById']),
    userId () {
      return this.params != null? this.params.userId : this.$route.params.userId
    },
    returnTarget () {
      return this.$route.query.return || 'driver-management'
    },
    isEmptyHomeAddress () {
      return this.homeAddress.street1.length == 0
        && this.homeAddress.street2.length == 0
        && this.homeAddress.city.length == 0
        && this.homeAddress.postcode.length == 0
        && this.homeAddress.country.length == 0
    },
    isEmptyOfficeAddress () {
      return this.officeAddress.street1.length == 0
        && this.officeAddress.street2.length == 0
        && this.officeAddress.city.length == 0
        && this.officeAddress.postcode.length == 0
        && this.officeAddress.country.length == 0
    }
  },
  methods: {
    ...mapActions('Users', ['createUser', 'updateUser', 'updateUserEmail']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),

    completeChildDialog (payload) {
      if (this.isChildDialogOpened('add-driver-group')) {
        if (this.groupIds.indexOf(payload.id) === -1) {
          this.groupIds.push(payload.id)
        }
      }

      this.closeChildDialog()
    },

    clearErrors () {
      this.externalIdError = ''
      this.fullNameError = ''
      this.emailAddressError = ''
      this.driverLicenseError = ''
      this.phoneNumberError = ''
      this.homeAddressError.street1 = ''
      this.homeAddressError.street2 = ''
      this.homeAddressError.city = ''
      this.homeAddressError.state = ''
      this.homeAddressError.postcode = ''
      this.homeAddressError.country = ''
      this.officeAddressError.street1 = ''
      this.officeAddressError.street2 = ''
      this.officeAddressError.city = ''
      this.officeAddressError.state = ''
      this.officeAddressError.postcode = ''
      this.officeAddressError.country = ''
      this.carMakeError = ''
      this.carModelError = ''
      this.carYearError = ''
      this.carRegistrationError = ''
      this.carNotesError = ''
      this.homeTariffError = ''
      this.cableIdError = ''
      this.fuelCardError = ''
      this.RFIDError = ''
      this.reimbursementSettlementError = ''
      this.reimbursementTypeError = ''
      this.reimbursementflatRatePerKWhError = ''
    },

    areFieldsValid () {
      if (this.fullName.length == 0) {
        this.fullNameError = 'Please enter driver’s full name'
      }

      if (this.invited) {
        if (this.emailAddress.length == 0) {
          this.emailAddressError = 'Please enter driver’s email address'
        }
      }

      if (this.wholeHomeAddress.length > 0) {
        this.wholeHomeAddressError = 'Please use the address fields below'
      }

      if (this.wholeOfficeAddress.length > 0) {
        this.wholeOfficeAddressError = 'Please use the address fields below'
      }

      if (!this.isEmptyHomeAddress) {
        if (this.homeAddress.street1.length == 0) this.homeAddressError.street1 = 'Please fill in the street address'
        if (this.homeAddress.city.length == 0) this.homeAddressError.city = 'Please fill in the city'
        if (this.homeAddress.postcode.length == 0) this.homeAddressError.postcode = 'Please fill in the postcode'
        if (this.homeAddress.country.length == 0) this.homeAddressError.country = 'Please select a country'
        if (this.homeAddress.country == 'US' && this.homeAddress.state.length == 0) this.homeAddressError.state = 'Please select a state'
      }

      if (!this.isEmptyOfficeAddress) {
        if (this.officeAddress.street1.length == 0) this.officeAddressError.street1 = 'Please fill in the street address'
        if (this.officeAddress.city.length == 0) this.officeAddressError.city = 'Please fill in the city'
        if (this.officeAddress.postcode.length == 0) this.officeAddressError.postcode = 'Please fill in the postcode'
        if (this.officeAddress.country.length == 0) this.officeAddressError.country = 'Please select a country'
        if (this.officeAddress.country == 'US' && this.officeAddress.state.length == 0) this.officeAddressError.state = 'Please select a state'
      }


      if (this.hasFeatures.reimbursementSettings) {
        if (this.reimbursementSettlement.length == 0) {
          this.reimbursementSettlementError = 'Please choose settlement type'
        }
        else if (this.reimbursementSettlement === 'bank') {
          if (this.reimbursementType.length == 0) {
            this.reimbursementTypeError = 'Please choose reimbursement type'
          }
          else if (this.reimbursementType === 'flat-rate') {
            if (this.reimbursementflatRatePerKWh == 0) {
              this.reimbursementflatRatePerKWhError = 'Please enter the rate'
            }
          }
        }
      }

      return this.externalIdError.length == 0
        && this.fullNameError.length == 0
        && this.emailAddressError.length == 0
        && this.driverLicenseError.length == 0
        && this.phoneNumberError.length == 0
        && this.homeAddressError.street1.length == 0
        && this.homeAddressError.street2.length == 0
        && this.homeAddressError.city.length == 0
        && this.homeAddressError.state.length == 0
        && this.homeAddressError.postcode.length == 0
        && this.homeAddressError.country.length == 0
        && this.wholeHomeAddressError.length == 0
        && this.officeAddressError.street1.length == 0
        && this.officeAddressError.street2.length == 0
        && this.officeAddressError.city.length == 0
        && this.officeAddressError.state.length == 0
        && this.officeAddressError.postcode.length == 0
        && this.officeAddressError.country.length == 0
        && this.wholeOfficeAddressError.length == 0
        && this.carMakeError.length == 0
        && this.carModelError.length == 0
        && this.carYearError.length == 0
        && this.carRegistrationError.length == 0
        && this.carNotesError.length == 0
        && this.homeTariffError.length == 0
        && this.cableIdError.length == 0
        && this.fuelCardError.length == 0
        && this.RFIDError.length == 0
        && this.reimbursementSettlementError.length == 0
        && this.reimbursementTypeError.length == 0
        && this.reimbursementflatRatePerKWhError.length == 0
    },

    save () {
      this.clearErrors()

      if (!this.areFieldsValid()) {
        this.showErrorMessage(`Validation failed. Please correct all errors!`)
        return
      }

      this.saving = true

      const data = {
        externalId: this.externalId,
        name: this.fullName,
        email: this.emailAddress,
        driverLicence: this.driverLicense,
        fuelCard: this.fuelCard,
        RFID: this.RFID,
        groupIds: this.groupIds,
        phone: this.phoneNumber,
        homeAddress: this.isEmptyHomeAddress? null : this.homeAddress,
        officeAddress: this.isEmptyOfficeAddress? null : this.officeAddress,
      }

      let updateEmail = false

      if (this.userId != null) {
        data.id = this.userId

        data.car = {
          registration: this.carRegistration,
          notes: this.carNotes
        }

        const user = this.getUserById(this.userId)
        updateEmail = user.invited && user.email != data.email
      }

      if (this.invited) {
        data.cableId = this.cableId
      }

      if (this.hasFeatures.reimbursementSettings) {
        data.reimbursementSettings = {
          settlementType: this.reimbursementSettlement,
          reimbursementType: this.reimbursementType,
          flatRatePerKWh: {
            currency: this.fleetCurrencyCode || this.currencyCode,
            amount: this.reimbursementflatRatePerKWh
          }
        }
      }

      const action = this.userId == null ? this.createUser : this.updateUser
      const successVerb = this.userId == null ? 'added' : 'updated'
      const failureVerb = this.userId == null ? 'add' : 'update'

      if (updateEmail) {
        this.updateUserEmail({
          userId: data.id,
          emailAddress: data.email
        })
        .then(() => {
          action(data)
          .then(() => {
            this.showSuccessMessage(`Invitation has been sent to the driver successfully`)
            this.$router.push({name: this.returnTarget})
          })
        })
        .catch(error => {
          if (error.field === 'email') {
            this.emailAddressError = error.message
          }
          else if (error.field === 'cableId') {
            this.cableIdError = error.message
          }

          this.showErrorMessage(`Could not update driver’s email address: ${error.message}`)
          this.saving = false
        })
      }
      else {
        action(data)
        .then((user) => {
          this.showSuccessMessage(`The driver has been ${successVerb} successfully`)
          this.completeDialog(user)
        })
        .catch((error) => {
          if (error.field === 'email') {
            this.emailAddressError = error.message
          }
          else if (error.field === 'cableId') {
            this.cableIdError = error.message
          }
          
          this.showErrorMessage(`Could not ${failureVerb} the driver: ${error.message}`)
          this.saving = false
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset {
  &.car .legend { @include icon-location (14, 2) }
  &.group .legend { @include icon-location (19, 2) }
  &.charging .legend { @include icon-location (15, 2) }
  &.reimbursement .legend { @include icon-location (13, 3) }

  .ownership-owned > label[for] {
    margin-bottom: 20px;
  }
}

main.form .fieldset.reimbursement {
  .reimbursement-types {
    display: flex;

    > * {
      flex: 0 1 calc(33.33% - 16.66px);
    }

    label.radio > span {
      &::before, &::after {
        top: 9px;
      }
      strong {
        display: block;
        margin-top: 0px;
        margin-bottom: 4px;
        font-weight: 500;
        font-size: .875rem;
      }
      em {
        display: block;
        font-style: normal;
        font-weight: 300;
      }
    }
  }
  .amount {
    flex: 0 1 calc(40% - 16.66px);
  }
}

main.form .fieldset.car {
  .car-make, .car-model {
    flex: 0 1 calc(40% - 16.66px);
    margin-right: 25px;
  }
  .car-year {
    flex: 0 1 calc(20% - 16.66px);
    margin-right: 0;
  }
  .car-reg, .leasing-company {
    flex: 0 1 calc(40% - 16.66px);
    margin-right: 25px;
  }
  .ownership-owned, .leasing-notes, .notes {
    flex: 0 1 calc(60% - 8.33px);
    margin-right: 0;
  }
}
</style>