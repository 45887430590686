<template>
  <WelcomeLayout ref="container" :show-image="hasImage" :use-image-layout="hasImageLayout">
    <transition name="slide"
      @before-leave="(from) => checkDirection(from, false)"
      @before-enter="(to) => {checkDirection(to, true); beforeContainerFit()}"
      @enter="(entered) => enterContainerFit(entered)"
      @after-enter="hasImageLayout = hasImage; afterContainerFit()"
      @cancel-enter="hasImageLayout = hasImage; afterContainerFit()"
    >
      <router-view class="form" />
    </transition>
  </WelcomeLayout>
</template>

<script>
import { WelcomeLayout, ContainerFit } from 'layouts/Welcome'

export default {
  mixins: [ContainerFit],
  components: { WelcomeLayout },
  data () {
    return {
      hasImageLayout: null
    }
  },
  created () {
    this.hasImageLayout = this.hasImage

    this.handleRedirects()
  },
  watch: {
    $route(_, __) {
      this.handleRedirects()
    }
  },
  computed: {
    hasImage () {
      return ['forgotten-password', 'reset-password'].indexOf(this.$route.name) === -1
    }
  },
  methods: {
    handleRedirects () {
      // FIXME: Should work when changing hash
      if (this.$route.name != 'authentication') return
      // 
      // Redirect any Firebase-specific email links to respective sections
      // 
      else if (this.$route.query.mode == 'resetPassword' && this.$route.query.oobCode != null) {
        this.$router.push({name: 'reset-password', params: {code: this.$route.query.oobCode}})
      }
      // else if (this.$route.query.mode == 'recoverEmail') {
        // TODO: Handle email recovery
      // }
      // else if (this.$route.query.mode == 'verifyEmail') {
        // TODO: Handle email varificition
      // }
      else this.$router.replace({name: 'login'})

      this.hasImageLayout = this.hasImage
    },
    checkDirection (element, entering) {
      if (this.$route.name == 'login') element.classList.add('reverse')
      else element.classList.remove('reverse')

      if (!entering) return

      element.classList.add(this.hasImage? 'to-image' : 'to-no-image')
    }
  }
}
</script>
