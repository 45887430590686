export const rootAPI = {
  baseURL: 'https://api-qa3.ohme.io/',
}

export const APIv1 = {
  baseURL: rootAPI.baseURL + 'v1/',
}

export const stripe = {
  apiKey: 'pk_test_51JQ89qDJ2lev1C735xMUJjUgHAT3KLHs60KGofD1sf4dTGnNZ6yIRltHikeAkS8McVg11Sg73me9PPYZEpSlyoX400ptYDCNJD'
}

export const firebase = {
  apiKey: 'AIzaSyATNF8MEFJDB6k6iZ-zlfOAEqhUt5kXiCw',
  authDomain: 'ohme-bc550.firebaseapp.com',
  databaseURL: 'https://ohme-bc550.firebaseio.com',
  projectId: 'ohme-bc550',
  storageBucket: 'ohme-bc550.appspot.com',
  messagingSenderId: '948226738903',
  appId: '1:948226738903:web:60cc6d26fc96b8a81bfe92'
}

export const quickstatBase = 'https://fleet-dashboard-qa.ohme-ev.com/'
