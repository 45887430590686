<template>
  <span 
    @click="$refs.input.focus()"
    :class="{
      focus: focused,
      disabled: disabled,
      input: true,
      prefix: !!this.prefix,
      suffix: !!this.suffix
    }">
    <span v-if="prefix" class="prefix">{{prefix}}</span>
    <input ref="input" type="text" 
      :required="required"
      :disabled="disabled"
      :id="id" 
      :placeholder="smartFraction(0)"
      :value="amount"
      @input="input"
      @change="change"
      @blur="focused = false"
      @focus="focused = true"
    />
    <span v-if="suffix" class="suffix">{{suffix}}</span>
    <span v-if="unitLabel" class="unit">{{unitLabel}}</span>
  </span>
</template>

<script>
import { currencies } from '~/currencies.js'
import { fraction } from '~/filters.js'

export default {
  props: {
    code: {
      type: String,
      validator: function (value) {
        return Object.keys(currencies).indexOf(value) !== -1
      }
    },
    value: Number,
    minor: Boolean,
    decimal: Number,

    id: String,
    disabled: Boolean,
    required: Boolean,
    unitLabel: String,
  },
  data () {
    return {
      focused: false,
      maxLength: 100
    }
  },
  mounted () {
    this.input({target: this.$refs.input})
  },
  computed: {
    currency () {
      return currencies[this.code]
    },
    symbol () {
      return this.minor? this.currency.minor : this.currency.major
    },
    amount () {
      return this.value == 0? '' : 
        this.smartFraction(this.minor? this.value * this.currency.divisor : this.value)
    },
    prefix () {
      return this.symbol.prefix == true? this.symbol.symbol : ''
    },
    suffix () {
      return this.symbol.prefix == false? this.symbol.symbol : ''
    },
    places () {
      if (this.decimal) return this.decimal
      else return this.currencyPlaces
    },
    currencyPlaces () {
      return this.minor? 0 : Math.ceil(Math.log10(this.currency.divisor))
    }
  },
  methods: {
    smartFraction(value) {
      const p1 = Math.pow(10, this.currencyPlaces)
      const p2 = Math.pow(10, this.places)

      const v1 = Math.floor(value * p1) / p1
      const v2 = Math.floor(value * p2) / p2

      return v1 < v2? v2.toFixed(this.places) : v1.toFixed(this.currencyPlaces)
    },
    input (e) {
      return

      // FIXME: implement character limit accounting for cursor position
      // - if cursor is before decimal point set maxLength to allowed + 1
      // - if cursor is after decimal point set maxLength to allowed
      const v = e.target.value
      const d = v.indexOf('.')
      
      if (d < 0) {
        e.target.maxLength = v.length + 1
      }
      else {
        e.target.maxLength = (d + 1) + this.places
      }
    },
    change (e) {
      let value = parseFloat(e.target.value)

      if (value < 0) value = Math.abs(value)

      value = parseFloat(this.smartFraction(value))

      if (this.minor) value /= this.currency.divisor

      this.$emit('input', isNaN(value) ? 0 : value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.input {
  display: inline-block;
  box-sizing: border-box;
	margin: 0;
  padding: 0px 12px;
  border: 2px solid $input-border-color;
  border-radius: 4px;
  background: $default-background;
  color: $default-text;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;

  display: flex;
  flex-flow: row nowrap;

  &.focus, &:hover {
    border-color: $outline-color;
  }

  &.disabled {
    border-color: $input-border-color;
    color: $inactive-text;
  }

  span {
    display: inline-block;
    box-sizing: border-box;
    padding: 11px 1px;
    pointer-events: none;
    white-space: nowrap;
  }

  input {
    border: 0;
    padding: 11px 1px;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $default-text;
    }
    &:focus::placeholder {
      color: $deemphasized-text;
    }
    &:disabled::placeholder {
      color: $inactive-text;
    }
  }

  &.suffix input {
    text-align: right;
  }

  .unit {
    padding-left: 6px;
    color: $deemphasized-text;
    font-size: .6875rem;
    line-height: 2;
    text-transform: uppercase;
  }
}

</style>