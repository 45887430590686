<template>
  <Sidebar :back="baseRoute" :key="'stats' + user.id">
    <header class="user">
      <Portrait :src="user.photo" class="portrait" />
      <h2>{{user.name}}</h2>

      <router-link class="switch details" :to="{
        name: baseRoute + '-driver-details',
        params: { userId: user.id }
      }">View driver details</router-link>
    </header>

    <section class="stats">
      <header>
        <h3>By location</h3>

        <ul>
          <li><button :class="{current: locationTab === 'cost'}" @click="locationTab = 'cost'">Cost</button></li>
          <li><button :class="{current: locationTab === 'distance'}" @click="locationTab = 'distance'">{{units.distancePlural}}</button></li>
          <li><button :class="{current: locationTab === 'cpd'}" @click="locationTab = 'cpd'">Cost per {{units.distanceSingular}}</button></li>
        </ul>
      </header>

      <table>
        <thead>
          <tr>
            <td class="invisible">Locations</td>
            <td>Week</td>
            <td>Month</td>
            <td>Year</td>
          </tr>
        </thead>
        <tfoot v-if="locationTab === 'cost'">
          <tr>
            <th>Total</th>
            <td><Amount :value="totalWeekCost" type="currency" /></td>
            <td><Amount :value="totalMonthCost" type="currency" /></td>
            <td><Amount :value="totalYearCost" type="currency" /></td>
          </tr>
        </tfoot>
        <tfoot v-if="locationTab === 'distance'">
          <tr>
            <th>Total</th>
            <td><Amount :value="totalWeekDistance" type="distance" /></td>
            <td><Amount :value="totalMonthDistance" type="distance" /></td>
            <td><Amount :value="totalYearDistance" type="distance" /></td>
          </tr>
        </tfoot>
        <tfoot v-if="locationTab === 'cpd'">
          <tr>
            <th>Average</th>
            <td><Amount :value="averageWeekCPD" type="currency_small" /></td>
            <td><Amount :value="averageMonthCPD" type="currency_small" /></td>
            <td><Amount :value="averageYearCPD" type="currency_small" /></td>
          </tr>
        </tfoot>
        <tbody v-if="locationTab === 'cost'">
          <tr v-for="l in locations" :key="l.label">
            <th>{{l.label}}</th>
            <td><Amount :value="l.cost.week" type="currency" /></td>
            <td><Amount :value="l.cost.month" type="currency" /></td>
            <td><Amount :value="l.cost.year" type="currency" /></td>
          </tr>
        </tbody>
        <tbody v-if="locationTab === 'distance'">
          <tr v-for="l in locations" :key="l.label">
            <th>{{l.label}}</th>
            <td><Amount :value="l.distance.week" type="distance" /></td>
            <td><Amount :value="l.distance.month" type="distance" /></td>
            <td><Amount :value="l.distance.year" type="distance" /></td>
          </tr>
        </tbody>
        <tbody v-if="locationTab === 'cpd'">
          <tr v-for="l in locations" :key="l.label">
            <th>{{l.label}}</th>
            <td><Amount :value="l.cpd.week" type="currency_small" /></td>
            <td><Amount :value="l.cpd.month" type="currency_small" /></td>
            <td><Amount :value="l.cpd.year" type="currency_small" /></td>
          </tr>
        </tbody>
      </table>

      <aside v-hide-empty="locationPieces.map(p => p.value)">
        <h3>This <label><select v-model="locationPeriod">
          <option v-for="p in ['week', 'month', 'year']" :key="p" :value="p">{{p}}</option>
        </select> <span><span>{{locationPeriod}}</span></span></label></h3>

        <canvas ref="locationPieChart" width="220" height="220"></canvas>

        <ul>
          <li v-for="l in locationPieces" :key="l.label"><span class="label">{{l.label}}</span> <Amount :value="l.value" type="percentage" /></li>
        </ul>
      </aside>
    </section>

    <section class="stats">
      <header>
        <h3>Business use</h3>

        <ul>
          <li><button :class="{current: usageTab === 'cost'}" @click="usageTab = 'cost'">Cost</button></li>
          <li><button :class="{current: usageTab === 'percent'}" @click="usageTab = 'percent'">Percent</button></li>
        </ul>
      </header>

      <table>
        <thead>
          <tr>
            <td class="invisible">Use cases</td>
            <td>Week</td>
            <td>Month</td>
            <td>Year</td>
          </tr>
        </thead>
        <tfoot v-if="usageTab === 'cost'">
          <tr>
            <th>Total</th>
            <td><Amount :value="totalWeekCost" type="currency" /></td>
            <td><Amount :value="totalMonthCost" type="currency" /></td>
            <td><Amount :value="totalYearCost" type="currency" /></td>
          </tr>
        </tfoot>
        <tfoot v-if="usageTab === 'percent'">
          <tr>
            <th>Total</th>
            <td><Amount :value="1" type="percentage" /></td>
            <td><Amount :value="1" type="percentage" /></td>
            <td><Amount :value="1" type="percentage" /></td>
          </tr>
        </tfoot>
        <tbody v-if="usageTab === 'cost'">
          <tr v-for="u in usage" :key="u.label">
            <th>{{u.label}}</th>
            <td><Amount :value="u.cost.week" type="currency" /></td>
            <td><Amount :value="u.cost.month" type="currency" /></td>
            <td><Amount :value="u.cost.year" type="currency" /></td>
          </tr>
        </tbody>
        <tbody v-if="usageTab === 'percent'">
          <tr v-for="u in usage" :key="u.label">
            <th>{{u.label}}</th>
            <td><Amount :value="u.percent.week" type="percentage" /></td>
            <td><Amount :value="u.percent.month" type="percentage" /></td>
            <td><Amount :value="u.percent.year" type="percentage" /></td>
          </tr>
        </tbody>
      </table>

      <aside v-hide-empty="usagePieces.map(p => p.value)">
        <h3>This <label><select v-model="usagePeriod">
          <option v-for="p in ['week', 'month', 'year']" :key="p" :value="p">{{p}}</option>
        </select> <span><span>{{usagePeriod}}</span></span></label></h3>

        <canvas ref="usagePieChart" width="220" height="220"></canvas>

        <ul>
          <li v-for="l in usagePieces" :key="l.label"><span class="label">{{l.label}}</span> <Amount :value="l.value" type="percentage" /></li>
        </ul>
      </aside>
    </section>

  </Sidebar>
</template>

<script>
import { mapGetters } from 'vuex'

import Amount from 'elements/Amount'
import Portrait from 'elements/Portrait'
import Sidebar from 'elements/Sidebar'

export default {
  components: { Amount, Portrait, Sidebar },
  props: {
    baseRoute: String
  },
  mounted () {
    this.drawPieChart(this.$refs.locationPieChart, this.locationPieces)
    this.drawPieChart(this.$refs.usagePieChart, this.usagePieces)
  },
  updated () {
    this.drawPieChart(this.$refs.locationPieChart, this.locationPieces)
    this.drawPieChart(this.$refs.usagePieChart, this.usagePieces)
  },
  data () {
    return {
      locationTab: 'cost',
      locationPeriod: 'week',
      usageTab: 'cost',
      usagePeriod: 'week',
    }
  },
  computed: {
    ...mapGetters(['units', 'theme']),
    ...mapGetters('Users', ['getUserById']),
    user () {
      return this.getUserById(this.$route.params.userId)
    },
    locations () {
      const s = this.user.statsByLocationAndPeriod

      return [
        {
          label: 'Home',
          cost: { week: s.home.week.cost, month: s.home.month.cost, year: s.home.year.cost },
          distance: { week: s.home.week.distance, month: s.home.month.distance, year: s.home.year.distance },
          cpd: { week: s.home.week.cpd, month: s.home.month.cpd, year: s.home.year.cpd }
        },
        {
          label: 'Work',
          cost: { week: s.work.week.cost, month: s.work.month.cost, year: s.work.year.cost },
          distance: { week: s.work.week.distance, month: s.work.month.distance, year: s.work.year.distance },
          cpd: { week: s.work.week.cpd, month: s.work.month.cpd, year: s.work.year.cpd }
        },
        {
          label: 'Destination',
          cost: { week: s.destination.week.cost, month: s.destination.month.cost, year: s.destination.year.cost },
          distance: { week: s.destination.week.distance, month: s.destination.month.distance, year: s.destination.year.distance },
          cpd: { week: s.destination.week.cpd, month: s.destination.month.cpd, year: s.destination.year.cpd }
        },
      ]
    },
    usage () {
      const s = this.user.statsByUsageAndPeriod

      return [
        {
          label: 'Personal',
          cost: { week: s.personal.week.cost, month: s.personal.month.cost, year: s.personal.year.cost },
          percent: { week: s.personal.week.percent, month: s.personal.month.percent, year: s.personal.year.percent },
        },
        {
          label: 'Business',
          cost: { week: s.business.week.cost, month: s.business.month.cost, year: s.business.year.cost },
          percent: { week: s.business.week.percent, month: s.business.month.percent, year: s.business.year.percent },
        },
      ]
    },
    locationPieces () {
      return this.pieces(this.locations, this.locationTab, this.locationPeriod)
    },
    usagePieces () {
      return this.pieces(this.usage, 'cost', this.usagePeriod)
    },
    totalWeekCost () {
      return this.locations.reduce((sum, l) => sum + l.cost.week, 0)
    },
    totalMonthCost () {
      return this.locations.reduce((sum, l) => sum + l.cost.month, 0)
    },
    totalYearCost () {
      return this.locations.reduce((sum, l) => sum + l.cost.year, 0)
    },
    totalWeekDistance () {
      return this.locations.reduce((sum, l) => sum + l.distance.week, 0)
    },
    totalMonthDistance () {
      return this.locations.reduce((sum, l) => sum + l.distance.month, 0)
    },
    totalYearDistance () {
      return this.locations.reduce((sum, l) => sum + l.distance.year, 0)
    },
    totalWeekCPD () {
      return this.locations.reduce((sum, l) => sum + l.cpd.week, 0)
    },
    totalMonthCPD () {
      return this.locations.reduce((sum, l) => sum + l.cpd.month, 0)
    },
    totalYearCPD () {
      return this.locations.reduce((sum, l) => sum + l.cpd.year, 0)
    },
    averageWeekCPD () {
      return this.totalWeekDistance > 0 ? this.totalWeekCost / this.totalWeekDistance : 0
    },
    averageMonthCPD () {
      return this.totalMonthDistance > 0 ? this.totalMonthCost / this.totalMonthDistance : 0
    },
    averageYearCPD () {
      return this.totalYearDistance > 0 ? this.totalYearCost / this.totalYearDistance : 0
    },
  },
  methods: {
    pieces (data, param, period) {
      let total

      switch (period) {
        case 'week':
          total = param === 'cost' ? this.totalWeekCost : param === 'distance' ? this.totalWeekDistance : this.totalWeekCPD
          break
        case 'month':
          total = param === 'cost' ? this.totalMonthCost : param === 'distance' ? this.totalMonthDistance : this.totalMonthCPD
          break
        case 'year':
          total = param === 'cost' ? this.totalYearCost : param === 'distance' ? this.totalYearDistance : this.totalYearCPD
          break
      }

      return data.map(function (l) {
        return {
          label: l.label,
          value: total > 0 ? l[param][period] / total : 0
        }
      })
    },
    drawPieChart (canvas, data) {
      const width = canvas.width
      const height = canvas.height

      const c = canvas.getContext('2d')

      c.clearRect(0, 0, width, height)

      const colors = this.theme === 'octopus' ?
        ['#E850F9', '#5BF8FF', '#0046AF', '#737575'] :
        ['#0046AF', '#32C4B6', '#EA603B', '#737575']

      const centerX = width / 2
      const centerY = height / 2

      const innerRadius = 82
      const outerRadius = 108

      let arcStart = 0
      let arcEnd = -Math.PI / 2

      // Draw each piece of the pie
      c.globalCompositeOperation = 'source-over'

      data.forEach(function (piece, i) {
        const pieceArc = 2 * Math.PI * piece.value

        arcStart = arcEnd
        arcEnd = arcEnd + pieceArc

        c.beginPath()

        c.moveTo(centerX, centerY)
        c.arc(centerX, centerY, outerRadius, arcStart, arcEnd)
        c.lineTo(centerX, centerY)

        c.fillStyle = colors[i % (colors.length - 1)]
        c.fill()
      })

      // Draw a hole inside the pie
      c.beginPath()
      c.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI)
      c.globalCompositeOperation = 'destination-out'
      c.fillStyle = 'white'
      c.fill()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

section.stats {
  header {
    position: relative;
    margin-bottom: 34px;

    ul {
      position: absolute;
      top: 0;
      right: 0;
    }

    li {
      float: left;
      border-bottom: 1px solid $separator-color;
    }

    button {
      border: 0;
      padding: 2px 20px;
      margin-bottom: -1px;
      border-bottom: 2px solid transparent;
      background: transparent;
      color: $bold-text;
      font-size: .875rem;
      font-weight: 700;
      line-height: 1.571428571;
      text-transform: uppercase;

      .octopus.themed & { color: $octopus-bold-text }

      &.current {
        color: $primary-color;
        border-bottom-color: $primary-color;

        .octopus.themed & { color: $primary-color }
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 30px;
  }

  aside {
    position: relative;
    padding: 15px 0 15px 180px;

    h3 {
      margin-bottom: 14px;
      font-size: 1.125rem;
      line-height: 1.5;

      label {
        position: relative;
        display: inline-block;
        padding-bottom: 2px;
        margin-bottom: -2px;

        > span {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          background: $default-background;
          pointer-events: none;

          > span {
            position: relative;
            display: inline-block;
            border-bottom: 2px dashed $primary-color;

            &::after {
              position: absolute;
              top: 50%;
              right: -15px;
              display: block;
              border-top: 6px solid $primary-color;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              margin-top: -2px;
              content: ''
            }
          }
        }

        select {
          padding: 0;
          width: 5.5em;

          &:focus {
            outline: 0;

            + span > span {
              body.outline-focused-elements & {
                border-bottom-style: solid;
              }
            }
          }
        }
      }
    }

    canvas {
      position: absolute;
      top: 50%;
      left: 85px;
      display: block;
      width: 110px;
      height: 110px;
      margin: -55px 0 0 -55px;
    }

    ul {
      display: flex;
      flex-flow: row nowrap;
      line-height: 1.5;

      li {
        margin-right: 40px;
      }

      .label {
        display: block;
        font-size: .875rem;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;

        &::after {
          display: inline-block;
          margin-left: 5px;
          vertical-align: 11%;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background: grey;
          content: ''
        }
      }

      li:nth-child(1) .label::after {
        background-color: $primary-color;
        .octopus.themed & { background-color: $octopus-primary-color }
      }
      li:nth-child(2) .label::after {
        background-color: $secondary-color;
        .octopus.themed & { background-color: $octopus-secondary-color }
      }
      li:nth-child(3) .label::after {
        background-color: $tertiary-color;
        .octopus.themed & { background-color: $primary-color }
      }
    }
  }
}
</style>