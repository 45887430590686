<template>
  <div class="field address">
    <label :for="id">{{label}} <Parenthetical v-if="!required" class="hint">Optional</Parenthetical></label>
    <label class="hidden" :for="id">street address line 1</label>
    <input
      :id="id"
      type="text"
      :required="required"
      :disabled="disabled"
      :class="{first: true, street: true, erroneous: error.street1.length > 0}"
      placeholder="Street address line 1"
      :aria-labelledby="id + '-street1'"
      :value="value.street1"
      @input="input({street1: $event.target.value})" />
    <label class="hidden" :for="id + '-street2'">street address line 2</label>
    <input
      :id="id + '-street2'"
      type="text"
      :required="false"
      :disabled="disabled"
      :class="{street: true, erroneous: error.street2.length > 0}"
      placeholder="Street address line 2"
      :value="value.street2"
      @input="input({street2: $event.target.value})" />
    <label class="hidden" :for="id + '-city'">city</label>
    <input
      :id="id + '-city'"
      type="text"
      :required="required"
      :disabled="disabled"
      :class="{city: true, erroneous: error.city.length > 0}"
      placeholder="City"
      :value="value.city"
      @input="input({city: $event.target.value})" />
    <label class="hidden" :for="id + '-postcode'">postcode</label>
    <input
      :id="id + '-postcode'"
      type="text"
      :required="required"
      :disabled="disabled"
      :class="{postcode: true, erroneous: error.postcode.length > 0}"
      placeholder="Postcode"
      :value="value.postcode"
      @input="input({postcode: $event.target.value})" />
    <transition name="show-error">
      <ul class="errors">
        <li v-if="error.street1.length > 0"><label class="error" :for="id">{{error.street1}}</label></li>
        <li v-if="error.street2.length > 0"><label class="error" :for="id + '-street2'">{{error.street2}}</label></li>
        <li v-if="error.city.length > 0"><label class="error" :for="id + '-city'">{{error.city}}</label></li>
        <li v-if="error.postcode.length > 0"><label class="error" :for="id + '-postcode'">{{error.postcode}}</label></li>
      </ul>
    </transition>
  </div>
</template>

<script>
import Parenthetical from 'elements/Parenthetical'

function addressFields (value) {
  return value.hasOwnProperty('street1')
    && value.hasOwnProperty('street2')
    && value.hasOwnProperty('city')
    && value.hasOwnProperty('postcode')
}

export default {
  components: { Parenthetical },
  props: {
    value: {
      type: Object,
      validator: addressFields
    },
    label: String,
    error: {
      type: Object,
      validator: addressFields
    },
    id: String,
    required: Boolean,
    disabled: Boolean
  },
  methods: {
    input (patch) {
      // TODO: Trim each address line
      this.$emit('input', {...this.value, ...patch})
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  position: absolute;
  left: -999em;
}
.address {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
}
.errors {
  margin-top: 10px;
}
input {
  margin-top: 8px;

  &.first {
    margin-top: 0;
  }

  &.city {
    flex: 0 1 calc(60% - 2.66px);
    margin-right: 8px;
  }
  &.postcode {
    flex: 0 1 calc(40% - 5.33px);
    margin-right: 0;
  }
}
</style>

<style lang="scss">
.field label[for="fleet-address"] {
  margin-top: 0;
}
</style>