<template>
  <Dialog>
    <main :class="['form', dialogDepth]"><form @submit.prevent="save" novalidate>
      <section class="fieldset charger">
        <h2 class="legend">Charger details</h2>

        <SelectField
          :disabled="isMakeDropdownDisabled"
          required 
          id="charger-make"
          label="Charger make"
          placeholder="Select charger make"
          :options="chargerMakes"
          v-model="chargerMake"
          :error="chargerMakeError"
        />
        <SelectField
          :disabled="isModelDropdownDisabled"
          required
          id="charger-model"
          label="Charger model"
          :placeholder="chargerMake == '' ? 'Select make first' : 'Select charger model'"
          :options="chargerModels"
          :option-value="(o) => o.id"
          :option-name="(o) => o.model"
          v-model="chargerModelId"
          :error="chargerModelIdError"
        />
        <TextField
          required
          type="text"
          id="charger-name"
          label="Name"
          placeholder="Enter charger name"
          v-model.trim="name"
          :error="nameError"
        />
        <TextField
          :disabled="chargerId != null"
          required
          type="text"
          id="serial-id"
          label="Serial ID"
          placeholder="Enter charger serial ID"
          v-model.trim="serialId"
          :error="serialIdError"
        />
        <div class="field groups">
          <label for="charger-group">Charger groups <Parenthetical class="hint">Optional</Parenthetical></label>
          <GroupSelect class="options" type="device" multiple v-model="groupIds" />
          <button type="button" class="add-new" @click="openChildDialog('add-charger-group')">Add charger group</button>
        </div>
        <SelectField
          v-if="hasFeatures.paygProducts"
          required
          id="charger-package"
          label="Default Pay As You Go package"
          placeholder="Select default package"
          :options="paygProducts"
          :option-value="(o) => o.id"
          :option-name="(o) => o.name"
          v-model="paygProductId"
          :error="paygProductIdError"
        >
          <button type="button" class="add-new" @click="openChildDialog('add-package')">Add package</button>
        </SelectField>
      </section>

      <transition name="reveal"><section v-if="chargerModel" class="fieldset sockets">
        <h2 class="legend">Sockets</h2>
        
        <TextField
          v-for="i in chargerModel.sockets"
          :key="i"
          required
          type="text"
          :id="'socket-'+i+'-id'"
          :label="'Socket '+i+' ID'"
          :placeholder="'Enter socket '+i+' ID'"
          v-model.trim="socketIds[i-1]"
          :error="socketIdErrors[i-1]"
        />
      </section></transition>

      <section class="fieldset location">
        <h2 class="legend"><label for="location-type">Location</label></h2>
        
        <div class="group">
          <AddressField
            required
            id="charger-address"
            label="Address"
            v-model="address"
            :error="addressError"
          />
        </div>
        <div class="group">
          <TextField
            type="text"
            id="address-latitude"
            label="Latitude"
            :placeholder="0 | fraction(6)"
            v-model.trim="location.latitude"
            :error="locationError.latitude"
          />
          <TextField
            type="text"
            id="address-longitude"
            label="Longitude"
            :placeholder="0 | fraction(6)"
            v-model.trim="location.longitude"
            :error="locationError.longitude"
          />
        </div>
        <RadioField
          required
          id="location-type"
          class="location-type"
          label="Location type"
          :options="{
            'workplace': {label: 'Workplace', legend: 'EV charging at your business'},
            'public': {label: 'Public', legend: 'EV charging for public use'},
            'private': {label: 'Private', legend: 'EV charging for private use'}
          }"
          orientation="horizontal"
          v-model="locationType"
          :error="locationTypeError"
        />
      </section>

      <div class="choose-action">
        <p class="actions sheet">
          <button class="action" :disabled="saving" type="submit">{{chargerId != null ? 'Save changes' : 'Add charger'}}</button>
          <button class="action secondary" :disabled="saving" type="button" @click="closeDialog">Cancel</button>
        </p>
      </div>
    </form></main>

    <transition name="child-dialog">
      <GroupEditor
        v-if="isChildDialogOpened('add-charger-group')"
        :chain="parentChain()"
        :params="{
          groupType: 'charger'
        }"
        @complete="completeChildDialog"
        @close="closeChildDialog"
      />
      <ProductEditor
        v-else-if="isChildDialogOpened('add-package')"
        :chain="parentChain()"
        :params="{
          type: 'payg'
        }"
        @complete="completeChildDialog"
        @close="closeChildDialog"
      />
    </transition>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { fraction } from '~/filters'

import { Dialog, DialogChainNode } from 'elements/Dialog'
import GroupSelect from 'elements/GroupSelect'
import RadioField from 'elements/RadioField'
import SelectField from 'elements/SelectField'
import AddressField from 'elements/AddressField'
import TextField from 'elements/TextField'
import Parenthetical from 'elements/Parenthetical'

import GroupEditor from 'sections/GroupEditor.vue'
import ProductEditor from 'sections/ProductEditor.vue'

export default {
  mixins: [DialogChainNode],
  components: {
    Dialog,
    GroupSelect,
    RadioField,
    SelectField,
    AddressField,
    TextField,
    Parenthetical,

    GroupEditor,
    ProductEditor
  },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      saving: false,

      chargerMake: '',
      chargerModelId: '',

      chargerMakeError: '',
      chargerModelIdError: '',

      name: '',
      serialId: '',
      paygProductId: '',
      socketIds: [],
      groupIds: [],

      nameError: '',
      serialIdError: '',
      paygProductIdError: '',
      socketIdErrors: [],

      locationType: '',
      locationTypeError: '',

      address: {
        street1: '',
        street2: '',
        city: '',
        postcode: ''
      },
      addressError: {
        street1: '',
        street2: '',
        city: '',
        postcode: ''
      },

      location: {
        latitude: '',
        longitude: '',
      },
      locationError: {
        latitude: '',
        longitude: '',
      }
    }
  },
  created () {
    if (this.chargerId == null) return

    const charger = this.getChargerById(this.chargerId)

    if (charger.model != null) {
      this.chargerMake = charger.model.make
      this.chargerModelId = charger.model.id
    }

    this.name = charger.name
    this.serialId = charger.serialId
    this.groupIds = charger.groupIds.slice()
    this.paygProductId = charger.paygProductId
    charger.sockets.forEach((c, i) => {
      this.socketIds[i] = c.externalId 
      this.socketIdErrors[i] = ''
    })
    this.locationType = charger.locationType

    this.address.street1 = charger.address.streetNumber
    this.address.street2 = charger.address.streetName
    this.address.city = charger.address.city
    this.address.postcode = charger.address.postcode

    if (charger.location.latitude) this.location.latitude = fraction(charger.location.latitude, 6)
    if (charger.location.longitude) this.location.longitude = fraction(charger.location.longitude, 6)
  },
  watch: {
    chargerMake (_, prev) {
      if (prev != '') this.chargerModelId = ''
      this.chargerMakeError = ''
    },
    chargerModelId (_, __) { this.chargerModelIdError = '' },
    name (_, __) { this.nameError = '' },
    serialId (_, __) { this.serialIdError = '' },
    // groupIds (_, __) { this.groupIdsError = '' },
    paygProductId (_, __) { this.paygProductIdError = '' },
    socketIds (ids, __) {
      ids.forEach((id, i) => {
        if (id.length != 0) this.socketIdErrors[i] = ''
      })
    },
    locationType (_, __) { this.locationTypeError = '' },
    'chargerModel.sockets' (n, __) {
      for (let i = 0; i < n; i++) {
        if (this.socketIds[i] == null) this.socketIds[i] = ''
        if (this.socketIdErrors[i] == null) this.socketIdErrors[i] = ''
      }
    },
    'address.street1' (_, __) { this.addressError.street1 = '' },
    'address.street2' (_, __) { this.addressError.street2 = '' },
    'address.city' (_, __) { this.addressError.city = '' },
    'address.postcode' (_, __) { this.addressError.postcode = '' },
    'location.latitude' (_, __) { this.locationError.latitude = '' },
    'location.longitude' (_, __) { this.locationError.longitude = '' },
  },
  computed: {
    ...mapGetters('Fleet', ['hasFeatures']),
    ...mapGetters('Products', ['paygProducts']),
    ...mapGetters('Chargers', ['getChargerById', 'chargerMakes', 'chargerModelsByMake', 'getChargerModelById']),
    isMakeDropdownDisabled () {
      return this.chargerId != null
    },
    isModelDropdownDisabled () {
      return this.isMakeDropdownDisabled || this.chargerModels.length === 0
    },
    chargerModels () {
      if (!this.chargerMake) return []
      else return this.chargerModelsByMake(this.chargerMake)
    },
    chargerModel () {
      if (!this.chargerModelId) return
      else return this.getChargerModelById(this.chargerModelId)
    },
    chargerId () {
      return this.params != null? this.params.chargerId : this.$route.params.chargerId
    },
    returnTarget () {
      return this.$route.query.return || 'charger-management'
    },
  },
  methods: {
    ...mapActions('Chargers', ['createCharger', 'updateCharger']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),

    completeChildDialog (payload) {
      if (this.isChildDialogOpened('add-charger-group')) {
        if (this.groupIds.indexOf(payload.id) === -1) {
          this.groupIds.push(payload.id)
        }
      }
      else if (this.isChildDialogOpened('add-package')) {
        this.paygProductId = payload.id
      }

      this.closeChildDialog()
    },

    clearErrors () {
      this.chargerMakeError = ''
      this.chargerModelIdError = ''
      this.nameError = ''
      this.serialIdError = ''
      this.paygProductIdError = ''
      this.socketIdErrors = this.socketIdErrors.map(e => '')
      this.locationTypeError = ''
      this.addressError.street1 = ''
      this.addressError.street2 = ''
      this.addressError.city = ''
      this.addressError.postcode = ''
      this.locationError.latitude = ''
      this.locationError.longitude = ''
    },

    areFieldsValid () {
      // Validate Make/Model
      if (this.chargerMake.length == 0)
        this.chargerMakeError = 'Please select charger make'
      else if (this.chargerModelId.length == 0)
        this.chargerModelIdError = 'Please select charger model'
      
      // Validate other fields
      if (this.name.length == 0)
        this.nameError = 'Please enter charger name'

      if (this.serialId.length == 0)
        this.serialIdError = 'Please enter serial ID'
      
      if (this.hasFeatures.paygProducts && this.paygProductId.length == 0)
        this.paygProductIdError = 'Please select default package'
      
      // Validate socket IDs
      if (this.chargerModel) for (let i = 0; i < this.chargerModel.sockets; i++) {
        if (this.socketIds[i].length == 0) this.socketIdErrors[i] = `Please enter socket ${i+1} ID`
      }

      // Validate Address
      if (this.locationType.length == 0)
        this.locationTypeError = 'Please select location type'

      if (this.address.street1.length == 0 && this.address.street2.length == 0) 
        this.addressError.street1 = 'Please fill in the street address'

      if (this.address.city.length == 0)
        this.addressError.city = 'Please fill in the city'

      if (this.address.postcode.length == 0)
        this.addressError.postcode = 'Please fill in the postcode'

      // TODO: Validate Lat/Long

      return this.chargerMakeError.length == 0
        && this.chargerModelIdError.length == 0
        && this.nameError.length == 0
        && this.serialIdError.length == 0
        && this.paygProductIdError.length == 0
        && this.socketIdErrors.reduce((r, e) => r && e.length == 0, true)
        && this.locationTypeError.length == 0
        && this.addressError.street1.length == 0
        && this.addressError.street2.length == 0
        && this.addressError.city.length == 0
        && this.addressError.postcode.length == 0
        && this.locationError.latitude.length == 0
        && this.locationError.longitude.length == 0
    },

    save () {
      this.clearErrors()

      if (!this.areFieldsValid()) {
        this.showErrorMessage(`Validation failed. Please correct all errors!`)
        return
      }

      this.saving = true

      // Common data
      const data = {
        name: this.name,
        groupIds: this.groupIds,
        paygProductId: this.paygProductId,
        locationType: this.locationType,
        address: {
          streetNumber: this.address.street1,
          streetName: this.address.street2,
          city: this.address.city,
          postcode: this.address.postcode,
        }
      }

      if (this.location.latitude && this.location.longitude) {
        data.location = {
          latitude: parseFloat(this.location.latitude),
          longitude: parseFloat(this.location.longitude),
        }
      }

      // Data to update charger
      if (this.chargerId != null) {
        const charger = this.getChargerById(this.chargerId)
        data['sockets'] = []
        charger.sockets.forEach((s, i) => {
           data['sockets'][i] = s
           data['sockets'][i].externalId = this.socketIds[i]
        })
      }
      // Data to create charger
      else {
        data['chargerModelId'] = this.chargerModelId
        data['serialId'] = this.serialId
        data['socketIds'] = []
        for (let i = 0; i < this.chargerModel.sockets; i++) {
          data['socketIds'][i] = this.socketIds[i]
        }
      }

      const action = this.chargerId == null ? this.createCharger : this.updateCharger
      const successVerb = this.chargerId == null ? 'added' : 'updated'
      const failureVerb = this.chargerId == null ? 'add' : 'update'

      action(data).then((charger) => {
        this.showSuccessMessage(`The charger has been ${successVerb} successfully`)
        this.completeDialog(charger)
      }).catch((error) => {
        this.showErrorMessage(`Could not ${failureVerb} the charger: ${error.message}`)
        this.saving = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset {
  &.charger .legend { @include icon-location (13, 2) }
  &.location .legend { @include icon-location (20, 2) }
  &.sockets {
    .legend { @include icon-location (14, 3) }
    .field {
      flex: 0 1 calc(33.33% - 16.66px);
      margin-right: 25px;

      &:nth-child(2n+1) {
        margin-right: 25px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .location-type {
    flex: 0 1 100%;
    margin-right: 0;

    + .group {
      margin-right: 25px;

      + .group {
        margin-right: 0;
      }
    }
  }
}
</style>
