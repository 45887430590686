<template>
  <Dialog>
    <main :class="['form', dialogDepth]"><form @submit.prevent="save">
      <section class="fieldset package">
        <h2 class="legend">Package details</h2>

        <div class="field name-field">
          <label for="package-name">Package name</label>
          <input required type="text" id="package-name" v-model.trim="name" placeholder="Enter a package name" />
        </div>

        <div class="field rank-field">
          <label for="package-rank">Package rank</label>
          <input required type="number" min="0" max="1000" id="package-rank" v-model.trim="rank" placeholder="0" />
        </div>

        <div class="field">
          <label for="public-package">Is this package public?</label>
          <label class="radio"><input required type="radio" name="public" v-model="publicPackage" value="yes" id="public-package" /> <span>Yes</span></label>
          <label class="radio"><input required type="radio" name="public" v-model="publicPackage" value="no" /> <span>No</span></label>
        </div>

        <div class="field">
          <label for="package-type">Payment type</label>
          <label class="radio"><input required :disabled="!hasFeatures.recurringProducts || productId != null || (params != null && params.type != null)" type="radio" name="type" v-model="type" value="recurring" id="package-type" /> <span>Recurring</span></label>
          <label class="radio"><input required :disabled="!hasFeatures.paygProducts || productId != null || (params != null && params.type != null)" type="radio" name="type" v-model="type" value="payg" /> <span>Pay as you go</span></label>
        </div>

        <div class="field drivers">
          <label for="driver-groups">Available to</label>
          <label class="radio"><input required :disabled="publicPackage === 'yes'" type="radio" name="user-group" v-model="allowedUserGroups" value="all" id="driver-groups" /> <span>All fleet drivers</span></label>
          <label class="radio"><input required :disabled="publicPackage === 'yes' || userGroups.length < 1" type="radio" name="user-group" v-model="allowedUserGroups" value="selected" /> <span>Selected driver groups</span></label>
          <GroupSelect class="options" type="user" multiple v-model="userGroupIds" :disabled="allowedUserGroups === 'all' || publicPackage === 'yes'" />
          <button type="button" class="add-new" @click="openChildDialog('add-driver-group')" :disabled="allowedUserGroups === 'all' || publicPackage === 'yes'">Add driver group</button>
        </div>

        <div class="field chargers">
          <label for="device-groups">Allowed for</label>
          <label class="radio"><input required type="radio" name="device-group" v-model="allowedDeviceGroups" value="all" id="device-groups" /> <span>All chargers</span></label>
          <label class="radio"><input required :disabled="deviceGroups.length < 1" type="radio" name="device-group" v-model="allowedDeviceGroups" value="selected" /> <span>Selected charger groups</span></label>
          <GroupSelect class="options" type="device" multiple v-model="deviceGroupIds" :disabled="allowedDeviceGroups === 'all'" />
          <button type="button" class="add-new" @click="openChildDialog('add-charger-group')" :disabled="allowedDeviceGroups === 'all'">Add charger group</button>
        </div>

        <div class="field">
          <label for="package-description">Package description <Parenthetical class="hint">Optional</Parenthetical></label>
          <textarea type="text" id="package-description" v-model.trim="description" placeholder="Enter a package description" />
        </div>
      </section>

      <section class="fieldset pricing">
        <h2 class="legend">Pricing</h2>

        <section>
          <div class="field">
            <label for="package-currency">Currency</label>
            <select :disabled="productId != null" v-model="currencyCode" id="package-currency">
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </select>
          </div>

          <div class="field">
            <label for="package-power-price">Rate</label>
            <CurrencyInput
              id="package-power-price"
              v-model="pricePerKWh"
              :code="currencyCode"
              unit-label="per kWh"
              :decimal="4"
              :disabled="productId != null"
            />
          </div>

          <div class="field">
            <label for="package-plug-in-fee">Plug-in fee</label>
            <CurrencyInput
              id="package-plug-in-fee"
              v-model="plugInFee"
              :code="currencyCode"
              :decimal="2"
              :disabled="productId != null"
            />
          </div>
        </section>

        <section v-if="type == 'recurring'">
          <div class="field">
            <label for="package-join-fee">Join fee</label>
            <CurrencyInput
              id="package-join-fee"
              v-model="joinFee"
              :code="currencyCode"
              :decimal="2"
              :disabled="productId != null"
            />
          </div>

          <div class="field">
            <label for="package-period">Recurring period</label>
            <select :disabled="productId != null" required v-model="recurringPeriod" id="package-period">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div class="field">
            <label for="package-recurring-fee">Recurring fee</label>
            <CurrencyInput
              id="package-recurring-fee"
              v-model="recurringFee"
              :code="currencyCode"
              :decimal="2"
              required
              :disabled="productId != null"
            />
          </div>
        </section>

      </section>

      <div class="choose-action">
        <p class="actions sheet">
          <button class="action" :disabled="saving" type="submit">{{productId != null ? 'Save changes' : 'Add package'}}</button>
          <button class="action secondary" :disabled="saving" type="button" @click="closeDialog">Cancel</button>
        </p>
      </div>
    </form></main>

    <transition name="child-dialog">
      <GroupEditor
        v-if="isChildDialogOpened('add-driver-group') || isChildDialogOpened('add-charger-group')"
        :chain="parentChain()"
        :params="{
          groupType: isChildDialogOpened('add-charger-group') ? 'charger' : 'driver'
        }"
        @complete="completeChildDialog"
        @close="closeChildDialog"
      />
    </transition>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { Dialog, DialogChainNode } from 'elements/Dialog'
import GroupSelect from 'elements/GroupSelect'
import CurrencyInput from 'elements/CurrencyInput'
import Parenthetical from 'elements/Parenthetical'

import GroupEditor from 'sections/GroupEditor.vue'

export default {
  mixins: [DialogChainNode],
  components: {
    Dialog,
    Parenthetical,
    GroupSelect,
    CurrencyInput,

    GroupEditor
  },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      saving: false,
      openedDialog: null,

      allowedUserGroups: 'all', // "all" | "selected"
      allowedDeviceGroups: 'all', // "all" | "selected"

      name: '',
      rank: 500,
      publicPackage: 'yes',
      description: '',
      type: 'recurring', // "recurring" | "payg"
      userGroupIds: [],
      deviceGroupIds: [],

      currencyCode: 'GBP',
      pricePerKWh: 0,
      plugInFee: 0,

      joinFee: 0,
      recurringPeriod: 'monthly', // "daily" | "weekly" | "monthly" | "yearly"
      recurringFee: 0,
    }
  },
  created () {
    if (this.params != null && this.params.type != null) {
      this.type = this.params.type
    }

    if (this.productId == null) {
      if (!this.hasFeatures.recurringProducts && this.hasFeatures.paygProducts) {
        this.type = 'payg'
      }
      return
    }

    const product = this.getProductById(this.productId)

    this.name = product.name
    this.rank = product.rank
    this.publicPackage = product.public? 'yes' : 'no'
    this.description = product.description
    this.type = product.type

    this.currencyCode = product.currencyCode
    this.pricePerKWh = product.pricePerKWh.amount
    this.plugInFee = product.plugInFee.amount

    this.joinFee = product.joinFee.amount
    this.recurringPeriod = product.recurringPeriod
    this.recurringFee = product.recurringFee.amount

    this.userGroupIds = []
    this.deviceGroupIds = []

    product.groupIds.forEach(groupId => {
      const group = this.getGroupById(groupId)
      if (group == null) return
      if (group.type === 'user')
        this.userGroupIds.push(groupId)
      else
        this.deviceGroupIds.push(groupId)
    })

    if (this.userGroupIds.length > 0) this.allowedUserGroups = 'selected'
    if (this.deviceGroupIds.length > 0) this.allowedDeviceGroups = 'selected'
  },
  computed: {
    ...mapGetters('Fleet', ['hasFeatures']),
    ...mapGetters('Groups', ['getGroupById', 'rootUserGroup', 'rootDeviceGroup', 'userGroups', 'deviceGroups']),
    ...mapGetters('Products', ['getProductById']),
    productId () {
      return this.params != null? this.params.productId : this.$route.params.productId
    },
    returnTarget () {
      return this.$route.query.return || 'package-management'
    },
  },
  methods: {
    ...mapActions('Products', ['createProduct', 'updateProduct']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),

    completeChildDialog (payload) {
      if (this.isChildDialogOpened('add-charger-group')) {
        if (this.deviceGroupIds.indexOf(payload.id) === -1) {
          this.deviceGroupIds.push(payload.id)
        }
      }
      else if (this.isChildDialogOpened('add-driver-group')) {
        if (this.userGroupIds.indexOf(payload.id) === -1) {
          this.userGroupIds.push(payload.id)
        }
      }

      this.closeChildDialog()
    },

    save () {
      this.saving = true

      const data = {
        name: this.name,
        rank: parseInt(this.rank, 10),
        public: this.publicPackage === 'yes',
        description: this.description,
        type: this.type,
        currencyCode: this.currencyCode,
        pricePerKWh: {
          currency: this.currencyCode,
          amount: parseFloat(this.pricePerKWh)
        },
        plugInFee: {
          currency: this.currencyCode,
          amount: parseFloat(this.plugInFee)
        },
        joinFee: {
          currency: this.currencyCode,
          amount: parseFloat(this.joinFee)
        },
        recurringPeriod: this.recurringPeriod,
        recurringFee: {
          currency: this.currencyCode,
          amount: parseFloat(this.recurringFee)
        }
      }

      let groupIds = []

      if (this.publicPackage === 'yes') groupIds.push(this.rootUserGroup.id)
      else if (this.allowedUserGroups === 'all') groupIds.push(this.rootUserGroup.id)
      else if (this.userGroupIds.length > 0) Array.prototype.push.apply(groupIds, this.userGroupIds)

      if (this.allowedDeviceGroups === 'all') groupIds.push(this.rootDeviceGroup.id)
      else if (this.deviceGroupIds.length > 0) Array.prototype.push.apply(groupIds, this.deviceGroupIds)

      data.groupIds = groupIds

      if (this.productId != null) {
        data.id = this.productId
      }

      const action = this.productId == null ? this.createProduct : this.updateProduct
      const successVerb = this.productId == null ? 'added' : 'updated'
      const failureVerb = this.productId == null ? 'add' : 'update'

      action(data).then((product) => {
        this.showSuccessMessage(`"${data.name}" package has been ${successVerb}`)
        this.completeDialog(product)
      }).catch((error) => {
        this.showErrorMessage(`Could not ${failureVerb} the package: ${error.message}`)
        this.saving = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset {
  &.package .legend { @include icon-location (17, 2) }
  &.pricing .legend { @include icon-location (18, 2) }
  
  .field .options {
    margin-top: 12px;
  }

  &.package {
    .name-field {
      flex: 0 1 calc(80% - 16.66px);
      margin-right: 25px;
    }
    .rank-field {
      flex: 0 1 calc(20% - 8.33px);
      margin-right: 0;
    }
  }
}
</style>