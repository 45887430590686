<template>
  <main>
    <p class="links">
      <router-link class="back" :to="{name: 'login'}">Back to login</router-link>
    </p>
    <h1>Reset password</h1>

    <transition name="reveal-envelope" mode="out-in">
      <div v-if="sent" class="step" key="sent" >
        <p class="instruction">A message has been sent to your inbox with instructions on how to reset your password.</p>
        <div class="envelope" />
      </div>
      <div v-else class="step" key="form">
        <p class="instruction">Please enter your email address, we will send you a reset link to your inbox!</p>

        <form @submit.prevent="submitEmail" novalidate>
          <TextField required type="email" v-model.trim="email" :error="emailError" id="recovery-email" label="Email address" placeholder="Enter email address…" />

          <p><button class="action" type="submit" :disabled="submitting">Reset password</button></p>
        </form>
      </div>
    </transition>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import TextField from 'elements/TextField'

import * as firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: { TextField },
  data () {
    return {
      submitting: false,
      sent: false,
      email: '',
      emailError: '',
    }
  },
  watch: {
    email(_, __) { this.emailError = '' },
  },
  methods: {
    ...mapActions('Notifications', ['showSuccessMessage', 'showErrorMessage']),
    submitEmail () {
      this.emailError = ''

      if (this.email.length == 0) {
        this.emailError = 'Please enter your email address'
      }
      else {
        this.submitting = true

        firebase.auth().sendPasswordResetEmail(this.email, {
          url: window.location.protocol + '//' + window.location.host + window.location.pathname + '#authentication/login'
        })
        .then(() => {
          this.sent = true
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-email':
              this.emailError = 'Email address appears to be invalid'
              break
            case 'auth/user-not-found':
              this.emailError = 'Email address is not in our records'
              break
          }

          if (this.emailError.length == 0) {
            this.showErrorMessage(`Could send email: ${error.message}`)
          }

          this.submitting = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.step .envelope {
  position: relative;
  width: 122px;
  height: 122px;
  margin-bottom: 41px;
  
  &::before, &::after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-image: url(/images/icons.png);
    background-size: $icon-sheet-width $icon-sheet-height;
    content: '';
  }
  &::before {
    top: 0px;
    height: 30px;
    background-position: -835px -2px;
    animation: 3s infinite linear irradiate ;
    transform-origin: 50% 60px;
  }
  &::after {
    bottom: 0px;
    height: 82px;
    background-position: -835px -42px;
    animation: 2s 1s infinite jiggle;
  }
}
.reveal-envelope-enter-active, .reveal-envelope-leave-active {
  transition-property: opacity;
  transition-duration: $page-transition-duration;
}
.reveal-envelope-leave {
  opacity: 1;
}
.reveal-envelope-leave-to {
  opacity: 0;
}
.reveal-envelope-enter {
  opacity: 0;
}

@keyframes irradiate {
  0% {
    opacity: 0;
    transform: scale(0.85)
  }
  50% {
    opacity: 1;
    transform: scale(1.0)
  }
  100% {
    opacity: 0;
    transform: scale(1.15)
  }
}

@keyframes jiggle {
  55%, 95% { transform: rotate(-0.5deg); }
  60%, 90% { transform: rotate(1deg); }
  65%, 75%, 85% { transform: rotate(-2deg); }
  70%, 80% { transform: rotate(2deg); }
  // 10%, 90% { transform: rotate(-1deg); }
  // 20%, 80% { transform: rotate(2deg); }
  // 30%, 50%, 70% { transform: rotate(-4deg); }
  // 40%, 60% { transform: rotate(4deg); }
}
</style>