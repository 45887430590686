<template>
  <form class="wrapper" @submit.prevent>
    <button @click="open = true">{{periodLabel}}</button>
    <div @click.self="open = false" v-if="open" class="backdrop" />
    <ul v-if="open" class="dropdown">
      <li v-for="(p, v) in periods" :key="v">
        <label class="radio"><input type="radio" name="period" v-model="currentPeriod" :value="v"> <span>{{p}}</span></label>
      </li>
      <li v-if="currentPeriod === 'custom'" class="date">
        <label for="start-date">Start date</label>
        <div class="field">
          <input type="date" v-model="startDate" id="start-date">
        </div>

        <label for="end-date">End date</label>
        <div class="field">
          <input type="date" v-model="endDate" id="end-date">
        </div>
      </li>
    </ul>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters(['periods', 'periodLabel', 'periodStart', 'periodEnd']),
    startDate: {
      get () {
        return this.periodStart
      },
      set (value) {
        this.changePeriodCustom({
          start: value,
          end: this.endDate
        })
      }
    },
    endDate: {
      get () {
        return this.periodEnd
      },
      set (value) {
        this.changePeriodCustom({
          start: this.startDate,
          end: value
        })
      }
    },
    currentPeriod: {
      get () {
        return this.$store.state.Global.period
      },
      set (value) {
        if (value !== 'custom') this.changePeriodPredefined(value)
        else this.changePeriodCustom({
          start: this.startDate,
          end: this.endDate
        })
      }
    }
  },
  methods: {
    ...mapActions(['changePeriodPredefined', 'changePeriodCustom'])
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.wrapper {
  position: relative;
  display: inline-block;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: $transparent-color;
}

button {
  position: relative;
  display: block;
  margin: 0;
  padding: 10px 12px 10px 34px;
  border: 0;
  background: $menu-background;
  background: rgba(240, 243, 243, 0.6);
  color: $primary-color;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.25;
  text-transform: uppercase;

  @include icon-left(7, 1);

  &::before { left: 3px }
}

.dropdown {
  position: absolute;
  z-index: 4;
  min-width: 200px;
  padding: 20px;
  background: $overlay-background;
  box-shadow: 0 0 4px $dropshadow-color;
  color: $bold-text;
  font-size: .875rem;
  
  li + li {
    margin-top: 20px;
  }

  label > input {
    margin-right: 6px;
  }

  li.date {
    padding-left: 22px;

    label {
      display: block;
      margin-bottom: 6px;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    div + label {
      margin-top: 20px;
    }

    input {
      text-transform: uppercase;
    }
  }

  label {
    white-space: nowrap;
  }
}

</style>