<template>
  <main>
    <header>
      <h1>Charger management</h1>

      <div class="actions">
        <router-link class="action" tag="button" :to="{name: 'charger-management-add-charger'}">Add charger</router-link>
      </div>

      <ManagementTabs type="charger" />
    </header>

    <aside v-if="groups.length > 0" class="categories">
      <h2 class="chargers" @click="groupFilter = null"><span class="amount">{{chargerCount}}</span> Charger{{chargerCount === 1 ? '' : 's'}}</h2>
      <Scroller>
        <ul>
          <li v-for="g in groups" :key="g.groupId" :class="{current: groupFilter === g.groupId}"><button @click="filterGroup(g.groupId)">
            <span class="category">{{g.name}}</span><span class="invisible">: </span>
            <span class="amount">{{g.count}} charger{{g.count === 1 ? '' : 's'}}</span>
          </button></li>
        </ul>
      </Scroller>
    </aside>

    <table class="big full-width controlled" ref="table">
      <thead>
        <tr>
          <td class="textual">Charger name</td>
          <td class="textual">Sockets</td>
          <td v-if="isCorporate" class="textual"><abbr title="Pay As You Go">PAYG</abbr> Package</td>
          <td class="textual">Groups</td>
          <td v-if="isCorporate" class="textual">Serial ID</td>
        </tr>
      </thead>
      <tbody ref="table-body">
        <tr class="filters">
          <td class="textual"><input type="search" :value="nameFilter" @input="changeNameFilter" placeholder="Search…" /></td>
          <td class="textual">&nbsp;</td>
          <td v-if="isCorporate" class="textual"><input type="search" :value="packageFilter" @input="changePackageFilter" placeholder="Search…" /></td>
          <td class="textual"><GroupSelect hide-empty type="device" v-model="groupFilter" placeholder="Select…" /></td>
          <td v-if="isCorporate" class="textual"><input type="search" :value="cableIdFilter" @input="changeCableIdFilter" placeholder="Search…" /></td>
        </tr>
        <tr v-if="visibleChargers.length == 0">
          <td :colspan="isCorporate ? 5 : 3" class="empty">{{isFiltered? 'No records matching the criteria were found' : 'No data to display'}}</td>
        </tr>
        <tr v-else v-for="(c, i) in visibleChargers" :key="c.id">
          <th><Abbreviated middle :value="c.name" :max-length="27" :to="{name: 'charger-management-charger-details', params: {chargerId: c.id}}" /></th>
          <template v-if="isRowHidden(i)">
            <td :colspan="isCorporate ? 4 : 2">&nbsp;</td>
          </template>
          <template v-else>
            <td class="textual" @click="$router.push({name: 'charger-management-charger-details', params: {chargerId: c.id}})">
              <Socket v-for="(s, i) in c.sockets" :key="s.id" :name="'' + (i+1)" :status="s.status" />
            </td>
            <td v-if="isCorporate" class="textual">
              <Abbreviated v-if="c.paygProductId" :value="packageName(c.paygProductId)" :to="{name: 'charger-management-package-details', params: {productId: c.paygProductId}}" :max-length="17" />
            </td>
            <td class="textual groups">
              <GroupTags type="device" baseRoute="charger-management" :ids="c.groupIds" :collapsed="true" :class="!isCorporate? 'last' : ''" />
            </td>
            <td v-if="isCorporate" class="textual"><Abbreviated middle :value="c.serialId" :max-length="17" class="last" /></td>
          </template>
        </tr>
      </tbody>
    </table>

    <transition name="modal"
      @before-leave="beforeModalLeaves"
      @before-enter="beforeModalEnters"
      @enter="whenModalEnters"
      @leave="whenModalLeaves"
      @after-leave="afterModalLeaves"
      @after-enter="afterModalEnters"
    >
      <router-view />
    </transition>
  </main>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters } from 'vuex'

import Abbreviated from 'elements/Abbreviated'
import ManagementTabs from 'elements/ManagementTabs'
import GroupSelect from 'elements/GroupSelect'
import Amount from 'elements/Amount'
import Socket from 'elements/Socket'
import GroupTags from 'elements/GroupTags'
import Scroller from 'elements/Scroller'

import { HiddenRows, ModalOwner } from '~/mixins'

export default {
  mixins: [HiddenRows, ModalOwner],
  components: { Abbreviated, ManagementTabs, GroupSelect, Amount, Socket, GroupTags, Scroller },
  data () {
    return {
      nameFilter: null,
      packageFilter: null,
      groupFilter: null,
      cableIdFilter: null,
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetName', 'isCorporate']),
    ...mapGetters('Chargers', ['chargers']),
    ...mapGetters('Products', ['getProductById']),
    ...mapGetters('Groups', ['getGroupById']),
    ...mapGetters('Groups', ['deviceGroupFilterIds']),
    groups () {
      const groups = {}

      this.chargers.forEach(charger => {
        charger.groupIds.forEach(groupId => {
          const g = this.getGroupById(groupId)
          if (g == null) return

          if (groups[groupId] != null)
            groups[groupId].count++
          else groups[groupId] = {
            name: g.name,
            groupId: groupId,
            count: 1,
          }
        })
      })

      return Object.values(groups)
    },
    chargerCount () {
      return this.chargers.length
    },
    visibleChargers () {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.trim().toLowerCase()) !== -1
      }

      const deviceGroupIds = this.deviceGroupFilterIds(this.groupFilter)

      return this.chargers.filter(charger => {
        let pass = true

        if (this.nameFilter != null) pass &= matches(this.nameFilter, charger.name)
        if (this.packageFilter != null) pass &= charger.paygProductId != "" && matches(this.packageFilter, this.packageName(charger.paygProductId))
        if (this.groupFilter) pass &= charger.groupIds.reduce((result, id) => result || deviceGroupIds.indexOf(id) != -1, false)
        if (this.cableIdFilter != null) pass &= matches(this.cableIdFilter, charger.serialId)

        return pass
      })
    },
    isFiltered () {
      return this.nameFilter != null
        || this.packageFilter != null
        || this.groupFilter
        || this.cableIdFilter != null
    },
  },
  methods: {
    changeNameFilter: debounce(function (e) { this.nameFilter = e.target.value }, 300),
    changePackageFilter: debounce(function (e) { this.packageFilter = e.target.value === ''? null : e.target.value }, 300),
    changeCableIdFilter: debounce(function (e) { this.cableIdFilter = e.target.value }, 300),

    packageName (id) {
      return this.getProductById(id).name
    },

    filterGroup (group) {
      this.nameFilter = null
      this.packageFilter = null
      this.groupFilter = group
      this.cableIdFilter = null
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

</style>