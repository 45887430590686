<template>
  <ul>
    <li v-for="(i, k) in items" :key="k"><router-link :to="{name: i.route}">{{i.name}} <Parenthetical v-if="i.count != null" class="count">{{i.count}}</Parenthetical></router-link></li>
  </ul>
</template>

<script>
import Parenthetical from 'elements/Parenthetical'

export default {
  props: {
    items: Array
  },
  components: { Parenthetical },
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

ul {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;
  
  li {
    border-bottom: 2px solid $separator-color;
  }

  a {
    display: block;
    min-width: 12em;
    border: 0;
    padding: 12px;
    margin-bottom: -2px;
    border-bottom: 2px solid transparent;
    font-size: 1.375rem;
    font-weight: 400;
    color: $bold-text;
    text-align: center;

    &.current {
      font-weight: 700;
      border-bottom-color: $primary-color;
    }

    .count {
      font-size: 0.75rem;
      font-weight: 100;
      vertical-align: 30%;
    }
  }

  .octopus.themed & {
    a {
      padding: 10px;
      font-size: .9375rem;
      text-transform: uppercase;
      border-radius: 8px 8px 0 0;

      &.current-root {
        font-weight: 500;
        color: $light-text;
        background: $primary-color;
      }

      .count {
        display: none;
      }
    }
  }
}
</style>