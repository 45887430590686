<template>
  <main>
    <p class="links">
      <router-link class="back" :to="{name: 'login'}">Back to login</router-link>
    </p>
    <h1>Reset password</h1>

    <p class="instruction">Please enter your new password and confirm it.</p>

    <form @submit.prevent="changePassword" novalidate>
      <TextField required type="password" v-model.trim="newPassword" :error="newPasswordError" id="new-password" label="New password" placeholder="Enter a new password…">
        <PasswordStrength :password="newPassword" @change="(strong) => passwordIsStrong = strong" />
      </TextField>
      <TextField required type="password" v-model.trim="confirmPassword" :error="confirmPasswordError" id="confirm-password" label="Confirm password" placeholder="Confirm your new password…" />

      <p><button class="action" type="submit" :disabled="submitting">Reset password</button></p>
    </form>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import TextField from 'elements/TextField'
import PasswordStrength from 'elements/PasswordStrength'

import * as firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: { TextField, PasswordStrength },
  data () {
    return {
      submitting: false,
      email: '',
      newPassword: '',
      confirmPassword: '',
      passwordIsStrong: false,
      newPasswordError: '',
      confirmPasswordError: '',
    }
  },
  watch: {
    newPassword(_, __) { this.newPasswordError = '' },
    confirmPassword(_, __) { this.confirmPasswordError = '' },
  },
  created () {
    firebase.auth()
    .verifyPasswordResetCode(this.$route.params.code)
    .then((email) => this.email = email)
    .catch((error) => this.handleResetError(error))
  },
  methods: {
    ...mapActions('Notifications', ['showSuccessMessage', 'showErrorMessage']),
    handleResetError(error) {
      switch (error.code) {
        case 'auth/expired-action-code':
          this.showErrorMessage(`Reset password link has expired`)
          this.$router.replace({name: 'forgotten-password'})
          break
        case 'auth/invalid-action-code':
          this.showErrorMessage(`Reset password link is invalid`)
          this.$router.replace({name: 'forgotten-password'})
          break
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          this.showErrorMessage(`Password cannot be reset for this user`)
          this.$router.replace({name: 'login'})
          break
      }
    },
    changePassword () {
      this.newPasswordError = ''
      this.confirmPasswordError = ''

      if (this.newPassword.length === 0)
        this.newPasswordError = 'Fill in your new password please'
      else if (!this.passwordIsStrong)
        this.newPasswordError = 'Password does not satisfy the criteria:'
      else if (this.newPassword != this.confirmPassword)
        this.confirmPasswordError = 'Password does not match'
      else {
        this.submitting = true
        firebase.auth()
        .confirmPasswordReset(this.$route.params.code, this.newPassword)
        .then(() => {
          this.showSuccessMessage('Password has been reset')
          this.$router.replace({name: 'login', query: {email: this.email}})
        })
        .catch((error) => {
          if (error.code == 'auth/weak-password') {
            this.newPasswordError = 'Password is too weak'
          }
          else this.handleResetError(error)

          if (this.newPasswordError.length == 0) {
            this.showErrorMessage(`Could reset password: ${error.message}`)
          }

          this.submitting = false
        })
      }
    }
  }
}
</script>
