<template>
  <span><span v-if="amount < 0" class="sign">&minus;</span><span v-if="before" :class="[type, 'unit']">{{before}}</span>{{amount | absolute | decimal(places)}}<span v-if="after" :class="[type, 'unit']">{{after}}</span></span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return [
          'device',
          'user',
          'charge',
          'currency',
          'currency_small',
          'distance',
          'weight',
          'energy',
          'power',
          'percentage'
        ].indexOf(value) !== -1
      }
    },
    value: Number,
    decimal: Number,
  },
  computed: {
    ...mapGetters(['units']),
    amount () {
      switch (this.type) {
        case 'percentage': return this.value * 100
        case 'weight': return this.value > 1000 ? this.value / 1000 : this.value
        case 'power':
        case 'energy': return this.value > 1000 * 1000 ? this.value / (1000 * 1000) : this.value > 1000 ? this.value / 1000 : this.value
        case 'currency_small': return this.value * 100
        default: return this.value
      }
    },
    before () {
      switch (this.type) {
        case 'currency': return this.units.currency
        case 'currency_small':
          switch (this.units.currency) {
            case '$': return '¢'
            default: return null
          }
      }
    },
    after () {
      switch (this.type) {
        case 'device': return this.value == 1? ' ' + this.units.deviceSingular : ' ' + this.units.devicePlural
        case 'user': return this.value == 1? ' ' + this.units.userSingular : ' ' + this.units.userPlural
        case 'charge': return this.value == 1? ' ' + this.units.chargeSingular : ' ' + this.units.chargePlural
        case 'distance': return this.units.distance
        case 'weight': return this.value > 1000 ? this.units.kWeight : this.units.weight
        case 'power': return this.value > 1000 * 1000 ? this.units.mPower : this.value > 1000 ? this.units.kPower : this.units.power
        case 'energy': return this.value > 1000 * 1000 ? this.units.mEnergy : this.value > 1000 ? this.units.kEnergy : this.units.energy
        case 'percentage': return '%'
        case 'currency_small':
          switch (this.units.currency) {
            case '£': return 'p'
            default: return null
          }
      }
    },
    places () {
      if (this.decimal) return this.decimal
      else switch (this.type) {
        case 'currency': return 2
        case 'energy': return 2
        case 'power': return 1
        default: return 0
      }
    }
  }
}
</script>