<template>
  <section v-if="fleetCurrentWeek.distance || loadingStats">
    <h2>Current {{ period }}</h2>
    <ul>
      <li>
        <span class="stat">
          <span class="label">Estimate {{ units.distancePlural }}</span
          ><span class="invisible">:</span>
          <Amount v-if="!loadingStats" class="value" :value="distance" type="distance" :decimal="2" />
          <span v-else class="value">
            <Loading />
          </span>
        </span>
        <Change v-if="!loadingStats" class="increase" :value="distanceChange" />
      </li>
      <li>
        <span class="stat">
          <span class="label">Total cost</span><span class="invisible">:</span>
          <Amount v-if="!loadingStats" class="value" :value="cost" type="currency" />
          <span v-else class="value">
            <Loading />
          </span>
        </span>
        <Change v-if="!loadingStats" class="increase" :value="costChange" />
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Amount from "elements/Amount";
import Change from "elements/Change";
import Loading from "elements/SVGs/Loading";

export default {
  components: { Amount, Change, Loading },
  props: {
    period: {
      required: true,
      validator: function(value) {
        return ["week", "month"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapGetters(["units"]),
    ...mapGetters("Stats", ["fleetCurrentWeek", "fleetCurrentMonth"]),
    ...mapState("Stats", ["loadingStats"]), // new loadingStats - show default if loadingStats is true
    distance() {
      return this.period === "week" ? this.fleetCurrentWeek.distance.total : this.fleetCurrentMonth.distance.total;
    },
    distanceChange() {
      return this.period === "week"
        ? this.fleetCurrentWeek.distance.totalChange
        : this.fleetCurrentMonth.distance.totalChange;
    },
    cost() {
      return this.period === "week" ? this.fleetCurrentWeek.cost.total : this.fleetCurrentMonth.cost.total;
    },
    costChange() {
      return this.period === "week" ? this.fleetCurrentWeek.cost.totalChange : this.fleetCurrentMonth.cost.totalChange;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~/common.scss";

h2 {
  font-size: 0.75rem;
  line-height: 1.25;
  font-weight: 700;
  text-transform: uppercase;
}

section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $section-border-color;
  border-radius: 4px;
  padding: 20px 30px;
}

ul {
  display: flex;
  flex-flow: row nowrap;
  margin-left: 30px;
}

li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & + li {
    margin-left: 30px;
    border-left: 1px solid $border-color;
    padding-left: 30px;
  }
}

.stat {
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: left;
  text-align: left;

  .value {
    color: $bold-text;
    .octopus.themed & {
      color: $octopus-bold-text;
    }
    font-size: 1.375rem;
    font-weight: 700;
  }

  .label {
    font-size: 0.75rem;
    font-weight: 300;
    white-space: nowrap;
  }
}

.increase {
  margin-left: 20px;
}

@media screen and (max-width: 1440px) {
  section {
    display: block;
    padding: 12px 20px 15px;
  }

  h2 {
    text-align: center;
    margin-bottom: 8px;
  }

  ul {
    margin-left: 0;
    justify-content: center;

    li {
      margin-right: 5%;
      margin-left: 0;
      padding-left: 5%;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .increase {
    margin-left: 10px;
  }
}
</style>
