<template>
  <div class="field">
    <label :for="id">{{label}} <Parenthetical v-if="!required" class="hint">Optional</Parenthetical></label>
    <div class="wrapper">
      <select
        :id="id"
        :required="required"
        :disabled="disabled"
        :class="{erroneous: error.length > 0}"
        :value="value"
        @change="$emit('input', $event.target.value)"
      >
        <option></option>
        <option v-for="o in options" :value="optionValue(o)" :key="optionValue(o)">{{optionName(o)}}</option>
      </select>
      <span v-if="value == ''" class="placeholder">{{placeholder}}</span>
    </div>
    <transition name="show-error">
      <label class="error" :for="id" v-if="error.length > 0">{{error}}</label>
    </transition>
    <slot />
  </div>
</template>

<script>
import Parenthetical from 'elements/Parenthetical'

export default {
  components: {Parenthetical},
  props: {
    value: String,
    label: String,
    error: String,
    id: String,
    required: Boolean,
    disabled: Boolean,
    placeholder: String,
    options: Array,
    optionValue: {
      type: Function,
      default: (o) => o
    },
    optionName: {
      type: Function,
      default: (o) => o
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.wrapper {
  position: relative;
}
.placeholder {
  position: absolute;
  top: 2px;
  left: 2px;
  display: inline-block;
  padding: 11px 15px;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: $placeholder-color;
  pointer-events: none;
}
</style>