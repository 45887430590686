<template>
  <span><img :src="source" alt="" /></span>
</template>

<script>
import portrait from '~/images/portrait.png'

export default {
  props: {
    src: {
      type: String,
    }
  },
  computed: {
    source () {
      return this.src || portrait
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

span {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $alt-background;
  overflow: hidden;

  vertical-align: middle;

  img {
    max-width: 100%;
  }
}
</style>