<template>
  <time :datetime="datetime">{{label}}</time>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    timestamp: Number,
    format: {
      type: String,
      default: 'LLL d, yyyy'
    }
  },
  computed: {
    datetime () {
      return format(this.timestamp, "yyyy-MM-dd'T'HH:mm:ss")
    },
    label () {
      return format(this.timestamp, this.format)
    }
  }
}
</script>