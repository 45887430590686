<template>
  <main class="form">
    <header>
      <h1>Settings</h1>

      <SettingsTabs />
    </header>

    <form @submit.prevent="saveName" novalidate>
      <section class="fieldset owner">
        <h2 class="legend">Your details</h2>

        <TextField required type="text" v-model.trim="fullName" :error="fullNameError" id="full-name" label="Name" placeholder="Enter your name…" @input="checkDirty" />
        <TextField required disabled type="email" v-model.trim="emailAddress" :error="emailAddressError" id="email-address" label="Email address" placeholder="Enter email address…" />

        <div class="field actions">
          <button class="action" :disabled="!changingName || savingName" type="submit">Save changes</button>
        </div>
      </section>
    </form>

    <form @submit.prevent="savePassword" novalidate>
      <section class="fieldset password">
        <h2 class="legend">Your password</h2>

        <TextField required type="password" v-model.trim="newPassword" :error="newPasswordError" id="new-password" label="New password" placeholder="Enter a new password…" @input="checkDirty">
          <PasswordStrength :password="newPassword" @change="(strong) => passwordIsStrong = strong" />
        </TextField>
        <TextField required type="password" v-model.trim="confirmPassword" :error="confirmPasswordError" id="confirm-password" label="Confirm password" placeholder="Confirm your new password…" @input="checkDirty" />

        <transition name="reveal">
          <TextField v-if="changingPassword" required type="password" v-model.trim="passwordVarificationForPassword" :error="passwordVarificationForPasswordError" id="verify-password-for-password" label="Old password" placeholder="Enter your password…" />
        </transition>

        <transition name="reveal">
          <p v-if="changingPassword" class="field note">
            Password varification is required for the account password change.
          </p>
        </transition>

        <div class="field actions">
          <button class="action" :disabled="!changingPassword || savingPassword" type="submit">Change password</button>
        </div>
      </section>
    </form>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextField from 'elements/TextField'
import PasswordStrength from 'elements/PasswordStrength'
import SettingsTabs from 'elements/SettingsTabs'

import * as firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: { SettingsTabs, TextField, PasswordStrength },
  data () {
    return {
      fullName: '',
      emailAddress: '',
      newPassword: '',
      confirmPassword: '',
      passwordIsStrong: false,

      fullNameError: '',
      emailAddressError: '',
      newPasswordError: '',
      confirmPasswordError: '',

      changingName: false,
      changingPassword: false,

      savingName: false,
      savingPassword: false,

      passwordVarificationForPassword: '',
      passwordVarificationForPasswordError: '',
    }
  },
  created () {
    this.fullName = this.name
    this.emailAddress = this.email
  },
  watch: {
    fullName(_, __) { this.fullNameError = '' },
    newPassword(_, __) { this.newPasswordError = '' },
    confirmPassword(_, __) { this.confirmPasswordError = '' },
    passwordVarificationForPassword(_, __) { this.passwordVarificationForPasswordError = '' },
  },
  computed: {
    ...mapGetters('Account', ['name', 'email'])
  },
  methods: {
    ...mapActions('Account', ['updateDetails']),
    ...mapActions('Notifications', ['showSuccessMessage']),
    checkDirty () {
      this.changingName = this.fullName != this.name
      this.changingPassword = this.newPassword.length > 0 || this.confirmPassword.length > 0
    },
    saveName () {
      this.fullNameError = ''

      if (this.fullName.length == 0) {
        this.fullNameError = 'Fill in your name please'
      }
      else {
        this.savingName = true
        this.updateDetails({
          name: this.fullName
        })
        .then(()=> {
          this.checkDirty()
          this.showSuccessMessage('Your account name has been successfully updated')
        })
        .catch(error => this.fullNameError = error.message)
        .finally(() => this.savingName = false)
      }
    },
    clearPasswordErrors () {
      this.newPasswordError = ''
      this.confirmPasswordError = ''
      this.passwordVarificationForPasswordError = ''
    },
    arePasswordFieldsValid () {
      if (this.newPassword.length === 0)
        this.newPasswordError = 'Fill in your new password please'
      else if (!this.passwordIsStrong)
        this.newPasswordError = 'Password does not satisfy the criteria:'

      if (this.confirmPassword.length === 0)
        this.confirmPasswordError = 'Confirm your new password please'
      else if (this.newPassword.length > 0 && this.newPassword != this.confirmPassword) 
        this.confirmPasswordError = 'Password does not match'

      if (this.passwordVarificationForPassword.length === 0)
        this.passwordVarificationForPasswordError = 'Fill in your current password please'

      return this.newPasswordError.length == 0 &&
        this.confirmPasswordError.length == 0 &&
        this.passwordVarificationForPasswordError.length == 0
    },
    savePassword () {
      this.clearPasswordErrors()

      if (!this.arePasswordFieldsValid()) return
      
      this.savingPassword = true

      const credential = firebase.auth.EmailAuthProvider.credential(
        this.email,
        this.passwordVarificationForPassword
      )
      const user = firebase.auth().currentUser

      user.reauthenticateWithCredential(credential)
      .then(() => {
        user.updatePassword(this.newPassword)
        .then(() => {
          this.newPassword = ''
          this.confirmPassword = ''
          this.passwordVarificationForPassword = ''
          this.checkDirty()
          this.showSuccessMessage('Your account password has been successfully changed')
        })
        .catch((error) => {
          if (error.code == 'auth/weak-password') {
            this.newPasswordError = 'Password is too weak'
          }
          else {
            this.showErrorMessage(`Could not change password: ${error.message}`)
          }
        })
        .finally(() => this.savingPassword = false)
      })
      .catch((error) => {
        if (error.code == 'auth/wrong-password') {
          this.passwordVarificationForPasswordError = 'Wrong password'
        }
        else {
          this.showErrorMessage(`Could not change password: ${error.message}`)
        }

        this.savingPassword = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset {
  &.password .legend { @include icon-location (24, 2) }
}
.reveal-enter-active, .reveal-leave-active {
  transition: opacity .35s;
}
.reveal-enter, .reveal-leave-to {
  opacity: 0;
}
</style>