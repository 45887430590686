<template>
  <WelcomeLayout>
    <transition name="dive" appear><main v-if="!hidden" class="form reverse static">
      <h1>Reimbursement settings</h1>
      <p class="instruction">How would you like to reimburse your&nbsp;drivers?</p>
      <form @submit.prevent="saveReimbursement" novalidate>
        <div class="fieldset vertical">
          <RadioField
            required
            v-model="reimbursementSettlement"
            :error="reimbursementSettlementError"
            id="reimbursement-settlement"
            label="Settlement type"
            :options="{
              'bank': 'Direct bank transfer',
              'utility': 'Utility transfer'
            }"
          />

          <RadioField v-if="reimbursementSettlement === 'bank'"
            required
            v-model="reimbursementType"
            :error="reimbursementTypeError"
            id="reimbursement-type"
            label="Reimbursement type"
            :options="{
              'flat-rate': {label: 'Flat rate', legend: 'A set cost the organisation will reimbursement the driver.'},
              'mileage': {label: 'Mileage', legend: 'Driver submits a breakdown of personal and business miles on the app each month.'},
              'energy-cost': {label: 'Energy cost', legend: 'Exact rate driver pays for home electricity.'}
            }"
            orientation="vertical"
          />

          <div v-if="reimbursementType === 'flat-rate' && reimbursementSettlement === 'bank'" class="field amount">
            <label for="flat-rate-amount">Flat rate amount</label>
            <CurrencyInput
              id="flat-rate-amount"
              v-model="reimbursementflatRatePerKWh"
              :code="reimbursementflatRatePerKWhCurrency"
              unit-label="per kWh"
              :decimal="4"
              required
            />
            <transition name="show-error">
              <label class="error" for="flat-rate-amount" v-if="reimbursementflatRatePerKWhError.length > 0">{{reimbursementflatRatePerKWhError}}</label>
            </transition>
          </div>
          <div class="field actions horizontal">
            <button class="action" :disabled="savingReimbursement" type="submit">{{'Save and Continue'}}</button>
            <button class="action secondary" :disabled="savingReimbursement" @click.prevent="onSkip">Do this later</button>
          </div>
        </div>
      </form>
    </main></transition>
  </WelcomeLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import WelcomeLayout from 'layouts/Welcome'
import RadioField from 'elements/RadioField'
import CurrencyInput from 'elements/CurrencyInput'

export default {
  components: { WelcomeLayout, RadioField, CurrencyInput },
  data () {
    return {
      hidden: false,
      savingReimbursement: false,

      reimbursementSettlement: '',
      reimbursementType: '',
      reimbursementflatRatePerKWh: 0,
      reimbursementflatRatePerKWhCurrency: 'GBP',

      reimbursementSettlementError: '',
      reimbursementTypeError: '',
      reimbursementflatRatePerKWhError: '',
    }
  },
  created () {
    this.reimbursementSettlement = this.fleetReimbursementSettings.settlementType
    this.reimbursementType = this.fleetReimbursementSettings.reimbursementType
    this.reimbursementflatRatePerKWh = this.fleetReimbursementSettings.flatRatePerKWh.amount
    this.reimbursementflatRatePerKWhCurrency = this.fleetReimbursementSettings.flatRatePerKWh.currency 
      || this.fleetCurrencyCode 
      || this.currencyCode
  },
  watch: {
    reimbursementSettlement(_, __) { this.reimbursementSettlementError = '' },
    reimbursementType(_, __) { this.reimbursementTypeError = '' },
    reimbursementflatRatePerKWh(_, __) { this.reimbursementflatRatePerKWhError = '' },
  },
  computed: {
    ...mapGetters(['currencyCode']),
    ...mapGetters('Fleet', ['fleetCurrencyCode', 'fleetId', 'fleetReimbursementSettings']),
  },
  methods: {
    ...mapActions('Fleet', ['updateReimbursementSettings']),
    ...mapActions('Notifications', ['showSuccessMessage', 'showErrorMessage']),

    checkDirtyReimbursement () {
      this.isDirtyReimbursement = true
    },

    clearReimbursementErrors () {
      this.reimbursementSettlementError = ''
      this.reimbursementTypeError = ''
      this.reimbursementflatRatePerKWhError = ''
    },

    areReimbursementFieldsValid () {
      if (this.reimbursementSettlement.length == 0) {
        this.reimbursementSettlementError = 'Please choose settlement type'
      }
      else if (this.reimbursementSettlement === 'bank') {
        if (this.reimbursementType.length == 0) {
          this.reimbursementTypeError = 'Please choose reimbursement type'
        }
        else if (this.reimbursementType === 'flat-rate') {
          if (this.reimbursementflatRatePerKWh == 0) {
            this.reimbursementflatRatePerKWhError = 'Please enter the rate'
          }
        }
      }

      return this.reimbursementSettlementError.length == 0
        && this.reimbursementTypeError.length == 0
        && this.reimbursementflatRatePerKWhError.length == 0
    },

    saveReimbursement () {
      this.clearReimbursementErrors()

      if (!this.areReimbursementFieldsValid()) return

      this.savingReimbursement = true
      this.updateReimbursementSettings({
        settlementType: this.reimbursementSettlement,
        reimbursementType: this.reimbursementType,
        flatRatePerKWh: {
          amount: this.reimbursementflatRatePerKWh,
          currency: this.reimbursementflatRatePerKWhCurrency
        }
      })
      .then(() => {
        this.hidden = true
        setTimeout(() => {
          this.goNext()
        }, 600)
      })
      .catch((error) => {
        this.showErrorMessage(`Could not save changes: ${error.message}`)
      })
      .finally(() => this.savingReimbursement = false)
    },
    onSkip () {
      this.goNext(true)
    },
    goNext (skipped) {
      if (skipped != true) {
        this.showSuccessMessage(`Thank you for completing your profile!`)
      }
      this.$router.push({name: 'dashboard', params: {fleetId: this.fleetId}})
    }
  }
}
</script>


<style lang="scss" scoped>
@import '~/common.scss';

.fieldset.vertical .field.amount {
  flex: 0 1 calc(50% - 12.50px);
}
</style>