<template>
  <main>
    <header>
      <h1>Transactions</h1>
      <DateFilter class="filter" />
    </header>
    <table class="big wide" ref="table">
      <thead>
        <tr>
          <td class="textual">Date</td>
          <td class="textual">Status</td>
          <td class="textual">Description</td>
          <td>Amount</td>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>Total:</td>
          <td>
            <Currency v-if="transactionsTotal" :value="-transactionsTotal.amount" :code="transactionsTotal.currency" />
            <span v-else>&ndash;</span>
          </td>
          <td class="textual">&nbsp;</td>
        </tr>
      </tfoot>
      <tbody ref="table-body">
        <tr v-if="transactions.length == 0">
          <td colspan="5" class="empty">{{'No data to display'}}</td>
        </tr>
        <tr v-else v-for="t in transactions" :key="t.createdOn" :class="{error: t.status === 'failed'}">
          <td class="textual"><DateTime :timestamp="t.createdOn" /></td>
          <td class="textual status">{{t.status}}</td>
          <td class="textual description">{{t.description}}</td>
          <td><Currency class="total" :value="-t.amount.amount" :code="t.amount.currency" /></td>
          <td class="textual">
            <button v-if="t.invoiceId" @click="downloadInvoice($event, t.invoiceId)" class="download">Invoice</button>
          </td>
        </tr>
      </tbody>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DateFilter from 'elements/DateFilter'
import DateTime from 'elements/DateTime'
import Currency from 'elements/Currency'

import { Downloader } from '~/mixins'

export default {
  mixins: [Downloader],
  components: { DateTime, DateFilter, Currency },
  computed: {
    ...mapGetters('Reimbursements', ['transactions', 'transactionsTotal']),
  },
  methods: {
    ...mapActions('Reimbursements', ['exportTransactionInvoice']),
    downloadInvoice(e, invoiceId) {
      this.download(e.target, 'invoice.pdf', () => 
        this.exportTransactionInvoice(invoiceId)
      , null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

tr.error {
  td {
    background: $error-background;
  }
  .description {
    font-style: normal;
    font-weight: 500;
    color: $error-color;
  }
}

.status {
  text-transform: capitalize;
  font-weight: 500;
  color: $bold-text;

}
.total {
  font-weight: 500;
  color: $bold-text;
}

.download {
  position: relative;
  padding: 0;
  padding-left: 28px;
  border: 0;
  font-size: 1rem;
  color: $primary-color;
  text-transform: capitalize;

  &:disabled {
    color: inherit;
  }

  @include icon-left (7, 2);
}

</style>