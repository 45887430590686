import Vue from "vue";

export const state = {
  fleet: [],
  fleetWeek: {},
  fleetMonth: {},
  loadingStats: false,
};

export const getters = {
  fleetMonthly: (state) => state.fleet,
  fleetCurrentWeek: (state) => state.fleetWeek,
  fleetCurrentMonth: (state) => state.fleetMonth,
};

export const mutations = {
  reset(state) {
    state.fleet = [];
    state.fleetWeek = {};
    state.fleetMonth = {};
  },
  updateMonthly(state, monthly) {
    Vue.set(state, "fleet", monthly);
  },
  updateCurrentWeek(state, week) {
    Vue.set(state, "fleetWeek", week);
  },
  updateCurrentMonth(state, month) {
    Vue.set(state, "fleetMonth", month);
  },
  updateLoadingStats(state, status) {
    Vue.set(state, "loadingStats", status);
  },
};

export const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
