import {
  lightFormat,
  parseISO,
  isValid,
  startOfToday,
  endOfToday,
  startOfDay,
  endOfDay,
  getTime,
  subDays,
  subMonths,
} from "date-fns";

import {
  resetAuthToken,
  getMakeLogos,
  getAccount,
  getFleet,
  getFleetSetupState,
  getFleetDetails,
  getDirectDebitInfo,
  getFleetReimbursementSettings,
  getDummyReimbursementSettings,
  getAdministrableFleets,
  getChargerModels,
  getChargers,
  getStatusOfChargerSockets,
  getProducts,
  getGroups,
  getUsers,
  getAdmins,
  getStatistics,
  getTransactions,
  getReimbursements,
  exportUserData,
  exportUserStatistics,
  exportUserStatisticsSummary,
  startFleetFeed,
  attachFleetFeedListener,
  stopFleetFeed,
} from "~/api";

import { currencies } from "~/currencies.js";

const periods = {
  today: "Today",
  "7 days": "Last 7 days",
  "30 days": "Last 30 days",
  "90 days": "Last 90 days",
  "6 months": "Last 6 months",
  "12 months": "Last 12 months",
  custom: "Custom range",
};

const defaultPeriod = "12 months";

export const state = {
  feed: null,

  initializing: false,
  switching: false,
  loading: false,

  makes: {},

  currency: "£",
  useImperialUnits: true,

  period: null,
  periodFrom: null,
  periodUntil: null,

  theme: "default",
};

export const getters = {
  isInitialized: (state) => !state.initializing,
  isSwitching: (state) => state.switching,
  isLoading: (state) => state.loading,

  makeLogos: (state) => state.makes,

  currencyCode: (state) => {
    const symbol = state.currency;
    const codes = Object.keys(currencies);

    for (let i = 0; i < codes.length; i++) {
      const code = codes[i];
      const currency = currencies[code];

      if (currency.major.symbol === symbol) {
        return code;
      }
    }

    // FIXME
    throw new Error('Unknown currency symbol: "' + symbol + '"');
  },

  periodFrom: (state) => state.periodFrom,
  periodUntil: (state) => state.periodUntil,

  periods: (_) => periods,
  periodLabel: (state) => periods[state.period],
  periodStart: (state) => lightFormat(state.periodFrom, "yyyy-MM-dd"),
  periodEnd: (state) => lightFormat(state.periodUntil, "yyyy-MM-dd"),

  units: (state) => {
    return {
      ...(state.useImperialUnits
        ? {
            distance: "mi",
            distanceSingular: "mile",
            distancePlural: "miles",
          }
        : {
            distance: "km",
            distanceSingular: "kilometer",
            distancePlural: "kilometers",
          }),
      currency: state.currency,
      weight: "g",
      kWeight: "kg",
      mWeight: "t",
      energy: "Wh",
      kEnergy: "kWh",
      mEnergy: "MWh",
      power: "W",
      kPower: "kW",
      mPower: "MW",
      chargeSingular: "charge",
      chargePlural: "charges",
      userSingular: "driver",
      userPlural: "drivers",
      deviceSingular: "charger",
      devicePlural: "chargers",
    };
  },
  theme: (state) => state.theme,
};

export const mutations = {
  setInitializingStatus(state, value) {
    state.initializing = !!value;
  },
  setSwitchingStatus(state, value) {
    state.switching = !!value;
  },
  setLoadingStatus(state, value) {
    state.loading = !!value;
  },
  setMakes(state, makes) {
    state.makes = makes;
  },
  setCurrency(state, currency) {
    state.currency = currency;
  },
  setMetricSystem(state, useMetric) {
    state.useImperialUnits = !useMetric;
  },
  setPredefinedPeriod(state, value) {
    if (!Object.keys(periods).includes(value) || value === "custom") return;

    state.period = value;
    state.periodUntil = getTime(endOfToday());

    switch (value) {
      case "today":
        state.periodFrom = getTime(startOfToday());
        break;
      case "7 days":
        state.periodFrom = getTime(subDays(startOfToday(), 7));
        break;
      case "30 days":
        state.periodFrom = getTime(subDays(startOfToday(), 30));
        break;
      case "90 days":
        state.periodFrom = getTime(subDays(startOfToday(), 90));
        break;
      case "6 months":
        state.periodFrom = getTime(subMonths(startOfToday(), 6));
        break;
      case "12 months":
        state.periodFrom = getTime(subMonths(startOfToday(), 12));
        break;
    }
  },
  setCustomPeriod(state, { from, until }) {
    state.period = "custom";
    state.periodFrom = from;
    state.periodUntil = until;
  },
  setTheme(state, theme) {
    state.theme = theme === "default" ? null : theme;
  },
  startFeed(state, fleetId) {
    if (state.feed != null) stopFleetFeed(state.feed);
    state.feed = startFleetFeed(fleetId);
  },
  stopFeed(state) {
    stopFleetFeed(state.feed);
    state.feed = null;
  },
};

export const actions = {
  showLoading({ commit }) {
    commit("setLoadingStatus", true);
  },
  hideLoading({ commit }) {
    commit("setLoadingStatus", false);
  },
  setup({ commit, dispatch }, auth) {
    // Get account and dictionaries first
    return Promise.all([getAccount(), getMakeLogos(), getAdministrableFleets()]).then(function([
      account,
      makes,
      administrableFleets,
    ]) {
      commit("setPredefinedPeriod", defaultPeriod);

      commit("Account/signIn", {
        uid: auth.uid,
        displayName: auth.displayName,
        email: auth.email,
        photoURL: auth.photoURL,
      });

      commit("setMakes", makes);
      commit("setCurrency", account.currency);
      commit("setMetricSystem", account.metric);

      administrableFleets.forEach((f) => commit("Fleet/addFleet", f));

      return dispatch("switchFleet", account.fleetId);
    });
  },
  switchFleet({ commit, dispatch }, fleetId) {
    commit("setSwitchingStatus", true);
    commit("startFeed", fleetId);

    commit("Groups/reset");
    commit("Users/reset");
    commit("Admins/reset");
    commit("Stats/reset");
    commit("Chargers/reset");
    commit("Products/reset");
    commit("Fleet/reset");
    commit("Reimbursements/reset");

    return getFleet(fleetId)
      .then(function(fleet) {
        return dispatch("loadFleet", fleet);
      })
      .finally(function() {
        commit("setInitializingStatus", false);
        commit("setSwitchingStatus", false);
      });
  },
  loadFleet({ commit, state }, fleet) {
    const fleetId = fleet.id;
    const features = fleet.features;

    commit("Stats/updateLoadingStats", true);

    // Get fleet data
    return Promise.all([
      getGroups(fleetId),
      getFleetSetupState(fleetId),
      getFleetDetails(fleetId),
      getDirectDebitInfo(fleetId),
      features.reimbursementSettings ? getFleetReimbursementSettings(fleetId) : getDummyReimbursementSettings(),
      getUsers(fleetId),
      getAdmins(fleetId),
      getChargerModels(fleetId),
      getChargers(fleetId),
      getStatusOfChargerSockets(fleetId),
      getProducts(fleetId),
      getTransactions(fleetId),
      getReimbursements(fleetId),
    ]).then(function([
      groups,
      setup,
      fleetDetails,
      bankDetails,
      reimbursementSettings,
      users,
      admins,
      chargerModels,
      chargers,
      socketStatus,
      products,
      transactions,
      reimbursements,
    ]) {
      // HACK - call getStatistics once user data has been returned
      getStatistics(fleetId, state.useImperialUnits, state.periodFrom, state.periodUntil)
        .then((stats) => {
          commit("Users/updateStatistics", stats.users);
          commit("Stats/updateMonthly", stats.fleet);
          commit("Stats/updateCurrentWeek", stats.fleetWeek);
          commit("Stats/updateCurrentMonth", stats.fleetMonth);

          commit("Stats/updateLoadingStats", false);
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to load statistics");
          commit("Stats/updateLoadingStats", false);
        });

      commit("setTheme", fleetId !== "09013dd9-ae63-4b4f-9f63-3f2b1884b086" ? "default" : "octopus");
      commit("Fleet/setFleet", fleet);
      commit("Groups/setRootUserGroupId", fleet.rootUserGroupId);
      commit("Groups/setRootDeviceGroupId", fleet.rootDeviceGroupId);

      commit("Fleet/setFleetDetails", fleetDetails);
      commit("Fleet/setFleetDirectDebitSetup", setup.directDebit);
      commit("Fleet/setFleetReimbursementSetup", reimbursementSettings.settlementType !== "");
      commit("Fleet/setFleetBankDetails", bankDetails);
      commit("Fleet/setReimbursementSettings", reimbursementSettings);

      groups.forEach((g) => commit("Groups/addGroup", g));
      users.forEach((u) => commit("Users/addUser", u));
      admins.forEach((a) => commit("Admins/addAdmin", a));
      chargerModels.forEach((m) => commit("Chargers/addChargerModel", m));
      products.forEach((p) => commit("Products/addProduct", p));
      transactions.forEach((t) => commit("Reimbursements/addTransaction", t));
      reimbursements.forEach((r) => commit("Reimbursements/addReimbursements", r));

      chargers.forEach((c) => {
        commit("Chargers/addCharger", c);
      });
      socketStatus.forEach((ss) => {
        commit("Chargers/updateSocketStatus", ss);
      });

      // Attach WebSocket listener
      attachFleetFeedListener(state.feed, (message) => {
        switch (message.type) {
          case "socket_status":
            commit("Chargers/updateSocketStatus", message.body);
            break;

          case "reconnecting":
            // TODO: Treat this as about to go offline
            break;
          case "reconnected":
            getStatusOfChargerSockets(fleetId).then((socketStatus) =>
              socketStatus.forEach((ss) => commit("Chargers/updateSocketStatus", ss))
            );
            // FIXME: Uncaught exceptions may be thrown here!
            break;

          case "offline":
            // TODO: Implement global offline status indication
            break;
          case "online":
            // TODO: Implement global offline status indication
            break;
        }
      });
    });
  },

  teardown({ commit }) {
    commit("stopFeed");
    resetAuthToken().then(function() {
      commit("Account/signOut");

      commit("setTheme", "default");
      commit("Groups/reset");
      commit("Users/reset");
      commit("Admins/reset");
      commit("Stats/reset");
      commit("Chargers/reset");
      commit("Products/reset");
      commit("Fleet/reset");
      commit("Fleet/resetList");
      commit("Reimbursements/reset");

      commit("setInitializingStatus", false);
    });
  },
  reloadStatistics({ commit, state, rootGetters }) {
    commit("setLoadingStatus", true);
    getStatistics(rootGetters["Fleet/fleetId"], state.useImperialUnits, state.periodFrom, state.periodUntil)
      .then(function(stats) {
        commit("Users/updateStatistics", stats.users);
      })
      .finally(function() {
        commit("setLoadingStatus", false);
      });
  },
  reloadUsers({ commit, rootGetters }) {
    commit("setLoadingStatus", true);
    getUsers(rootGetters["Fleet/fleetId"])
      .then(function(users) {
        users.forEach((u) => commit("Users/patchUser", u));
      })
      .finally(function() {
        commit("setLoadingStatus", false);
      });
  },
  reloadAdmins({ commit, rootGetters }) {
    commit("setLoadingStatus", true);
    getAdmins(rootGetters["Fleet/fleetId"])
      .then(function(admins) {
        admins.forEach((a) => commit("Admins/patchAdmin", a));
      })
      .finally(function() {
        commit("setLoadingStatus", false);
      });
  },
  changePeriodPredefined({ commit, dispatch }, period) {
    commit("setPredefinedPeriod", period);
    dispatch("reloadStatistics");
  },
  changePeriodCustom({ commit, dispatch }, { start, end }) {
    const startDate = parseISO(start);
    const endDate = parseISO(end);

    if (!isValid(startDate) || !isValid(endDate)) return;

    commit("setCustomPeriod", {
      from: getTime(startOfDay(startDate)),
      until: getTime(endOfDay(endDate)),
    });
    dispatch("reloadStatistics");
  },
  exportUserStatistics({ state, rootGetters }) {
    return exportUserStatistics(rootGetters["Fleet/fleetId"], "", "xlsx", state.periodFrom, state.periodUntil);
  },
  exportUserStatisticsForUserIds({ state, rootGetters }, selectedUserIds) {
    return exportUserStatistics(
      rootGetters["Fleet/fleetId"],
      selectedUserIds,
      "xlsx",
      state.periodFrom,
      state.periodUntil
    );
  },
  exportUserStatisticsSummary({ state, rootGetters }) {
    return exportUserStatisticsSummary(rootGetters["Fleet/fleetId"], "xlsx", state.periodFrom, state.periodUntil);
  },
  exportUserData({ rootGetters }) {
    return exportUserData(rootGetters["Fleet/fleetId"], "csv");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
