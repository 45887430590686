<template>
  <main class="form">
    <header>
      <h1>Add a new organisation</h1>
    </header>

    <form @submit.prevent="save">
      <section class="fieldset organisation">
        <h2 class="legend">Fleet details</h2>

        <div class="field">
          <label for="organisation-name">New organisation name</label>
          <input required type="text" id="organisation-name" v-model.trim="name" placeholder="Enter name of a new organisation" />
        </div>

        <div class="field">
          <label for="parent-organisation">Parent organisation</label>
          <input disabled type="text" id="parent-organisation" :value="fleetName" placeholder="Enter name of a new organisation" />
        </div>

        <div class="field groups">
          <label for="user-group">Root driver group</label>
          <GroupSelect required id="user-group" type="user" v-model="rootUserGroupParentId" placeholder="Select root driver group" />
        </div>

        <div class="field groups">
          <label for="device-group">Root charger group</label>
          <GroupSelect required id="device-group" type="device" v-model="rootDeviceGroupParentId" placeholder="Select root charger group" />
        </div>
      </section>

      <button class="action" :disabled="saving" type="submit">Add organisation</button>
    </form>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GroupSelect from 'elements/GroupSelect'

export default {
  components: { GroupSelect },
  data () {
    return {
      saving: false,

      name: '',
      rootUserGroupParentId: '',
      rootDeviceGroupParentId: '',
    }
  },
  created () {
    if (this.groupId == null) return

    const group = this.getGroupById(this.groupId)

    this.name = group.name
    this.parentId = group.parentId
  },
  computed: {
    ...mapGetters('Fleet', ['fleetName']),
  },
  methods: {
    ...mapActions('Fleet', ['createFleet']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),
    save () {
      this.saving = true

      const data = {
        name: this.name,
        rootUserGroupParentId: this.rootUserGroupParentId,
        rootDeviceGroupParentId: this.rootDeviceGroupParentId
      }

      this.createFleet(data).then((newFleet) => {
        this.showSuccessMessage(`The organisation has been added successfully`)
        this.$router.push({name: 'dashboard', params: {fleetId: newFleet.id}})
      }).catch((error) => {
        this.showErrorMessage(`Could not add the organisation: ${error.message}`)
        this.saving = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset.organisation {
  .legend { @include icon-location (14, 2) }
}
</style>