<template>
  <div v-if="isInitialized" id="app" :class="{
    'themed': theme != null,
    'octopus': theme == 'octopus',
    'authenticating': isAuthenticating,
    'loading': isLoading || isSwitching
  }">
    <Notifications />
    <router-view id="view" />
  </div>
  <div v-else id="app" class="loading" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Notifications from 'elements/Notifications.vue'

export default {
  name: 'Partner-portal',
  components: { Notifications },
  mounted () {
    function handleFirstTab (e) {
      if (e.key === 'Tab' || e.keyCode === 9) {
        document.body.classList.add('outline-focused-elements')
        window.removeEventListener('keydown', handleFirstTab)
      }
    }

    window.addEventListener('keydown', handleFirstTab)

    // Start notification timer
    this.tick()
  },
  computed: {
    ...mapGetters(['isInitialized', 'isSwitching', 'isLoading', 'theme']),
    isAuthenticating () {
      return [
        'invitation',
        'direct-debit',
        'authentication',
        'login',
        'forgotten-password',
        'reset-password'
      ].indexOf(this.$route.name) > -1
    }
  },
  methods: {
    ...mapActions('Notifications', ['tick']),
  }
}
</script>

<style lang="scss">
@import 'normalize.css';
@import '~/common.scss';
@import 'src/fonts/stylesheet';

html, body, #app {
  height: 100%;
}


// 
// Layout
// 

#notifications {
  position: fixed;
  left: $menu-width + $section-padding;
  top: $section-padding - 5px;
  right: $section-padding;
  z-index: 6;

  .authenticating &, .locked & {
    left: $section-padding;
  }
}

// 
// Modal sections: Sidebar and Dialogs
// 

// Lock the main scrollbar when modal dialog and sidebars are visible
body {
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    display: block;
    background: rgba(0, 13, 33, 0.14);
    content: '';
    pointer-events: none;
    transition: opacity .75s;
    opacity: 0;
  }
}
body.locked {
  overflow: hidden;

  &::before {
    opacity: 1;
  }
}

// Common stuff
.sidebar, .dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  overflow: scroll;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: $transparent-color;
  }
}

.dialog {
  left: 0;

  &.child-dialog-enter-active, &.child-dialog-leave-active {
    transition-duration: $section-transition-duration;
    transition-property: transform, opacity;
  }
  &.child-dialog-enter, &.child-dialog-leave-to {
    opacity: 0;
    transform: translateX(+120%)
  }
  
  > dialog {
    position: static;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    box-sizing: border-box;
    min-height: 100%;
    border: 0;
    padding: 20px 10px;
    background: transparent;
    color: $default-text;

    > .form {
      width: 670px;
      margin: 0 auto;
      transition: transform 0.20s;

      > form {
        border-radius: 5px;
        box-shadow: 0 3px 12px 0px $dropshadow-color;
        background: $overlay-background;
      }

      &.stacked {

        &.level-1 {
          transform: translateX(-110%);
        }
        &.level-2 {
          transform: translateX(-220%);
        }
        &.level-3 {
          transform: translateX(-330%);
        }
        &.level-4 {
          transform: translateX(-440%);
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          display: block;
          background: $overlay-color;
          content: ""
        }
      }
    }
  }

  .choose-action {
    position: sticky;
    bottom: 0;

    > .actions {
      box-sizing: border-box;
      margin: 0 auto;
      padding: 15px;
      border-top: 1px solid $border-color;
      border-radius: 0 0 5px 5px;
      background: $action-background;

      display: flex;
      flex-flow: row nowrap;

      > * {
        flex-grow: 1;
      }
      > * + * {
        margin-left: 15px;
      }
    }
  }
}

.sidebar {
  width: 545px;
  background: $overlay-background;
  box-shadow: -1px 0 4px $dropshadow-color;

  .close {
    @include button(4, 0);

    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;

    &:hover, &:focus {
      @include icon-location(4, 1);
    }
  }

  article {
    padding: 30px;
  }

  header h2 {
    position: relative;
    padding-left: 35px;
    padding-right: 20px;
  }


  .choose-action, .confirm-removal {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    box-sizing: border-box;
    width: 545px;
    margin: 0;
  }
  .confirm-removal {
    z-index: 4;

    + .shade {
      position: fixed;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      width: 545px;
      background: $overlay-color;
      content: "";
      pointer-events: none;
    }

    h3, p {
      padding: 0 15px;
    }
    h3 {
      font-size: 1.125rem;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
  }

  .choose-action > .actions, .confirm-removal {
    padding: 15px;
    border-top: 1px solid $border-color;
    background: $action-background;
  }

  .choose-action > .actions, .confirm-removal > .actions {
    display: flex;
    flex-flow: row nowrap;
    > * {
      flex-grow: 1;
    }
    > * + * {
      margin-left: 15px;
    }
  }

  section {
    margin: 30px -30px;
    padding: 0 30px;

    & + section {
      border-top: 1px solid $border-color;
      padding-top: 30px;
    }

    &:last-child {
      padding-bottom: 50px;
    }
  }

  header + section.details {
    margin-top: 0;
  }

  section.details {
    h3 {
      position: relative;
      padding-left: 35px;

      @include icon-left (16, 2);

      &::before { left: -7px; }
      .car &::before { left: -5px; }
    }

    &.groups h3 {
      @include icon-location (19, 2);
      &::before { left: -5px; }
    }

    &.location h3 {
      @include icon-location (20, 2);
      &::before { left: -4px; }
    }

    ul {
      margin-top: -3px;
      margin-right: -25px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        margin-right: 25px;
        margin-top: 25px;
      }

      .label {
        display: block;
        margin-bottom: 5px;
        color: $label-text;
        .octopus.themed & { color: $octopus-bold-text }
        font-size: .875rem;
        font-weight: 700;
        text-transform: none;
      }

      img {
        display: inline-block;
        height: 24px;
        margin-right: 4px;
        vertical-align: middle;
      }
    }

    table.list {
      width: 100%;
      width: calc(100% + 30px);
      margin-top: 18px;
      margin-left: -15px;
      margin-right: -15px;
      border-collapse: separate;
      border-spacing: 0 8px;

      thead, tbody {
        td, th {
          padding: 12px;
        }
        tr > *:first-child {
          padding-left: 15px;
        }
        tr > *:last-child {
          padding-right: 15px;
        }
      }

      thead {
        td {
          border-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      tbody {
        td, th {
          border: 0;
          background: $light-background;
        }
        td {
          font-weight: 500;
        }
        tr > *:first-child {
          border-radius: 6px 0 0 6px;
        }
        tr > *:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }

    p {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

//
// Dialog & Sidebar transitions
//

// Sidebar <> Main
.sidebar.modal-enter-active.from-main,
.sidebar.modal-leave-active.to-main {
  transition-duration: $section-transition-duration;
  transition-property: transform;
}
.sidebar.modal-enter.from-main,
.sidebar.modal-leave-to.to-main {
  transform: translateX(100%)
}

// Dialog <> Main
.dialog.modal-enter-active.from-main,
.dialog.modal-leave-active.to-main {
  transition-duration: $section-transition-duration, $section-transition-prepare;
  transition-property: transform, opacity;
  transform-origin: top center;

  & main > form > * {
    transition-duration: $section-transition-prepare;
    transition-property: opacity;
    transition-delay: ($section-transition-duration - $section-transition-prepare);
  }
}
.dialog.modal-leave-active.to-main {
  transition-duration: $section-transition-duration, $section-transition-prepare;
  transition-delay: 0s, ($section-transition-duration - $section-transition-prepare);

  & main > form > * {
    transition-delay: 0s;
  }
}
.dialog.modal-enter.from-main,
.dialog.modal-leave-to.to-main {
  opacity: 0;
  transform: translate(calc(50% - 135px), 0) scale(.31);

  & main > form > * {
    opacity: 0;
  }
}

// Sidebar <> Sidebar
.sidebar.modal-enter-active.from-sidebar,
.sidebar.modal-leave-active.to-sidebar {
  &, article {
    transition-duration: $section-transition-duration;
    transition-property: opacity;
  }
  article + * {
    transition-duration: $section-transition-duration;
    transition-property: transform;
  }
}
.sidebar.modal-enter.from-sidebar {
  opacity: 1;

  article {
    opacity: 0;
  }
}
.sidebar.modal-enter.from-sidebar,
.sidebar.modal-leave-to.to-sidebar {
  article + * {
    transform: translateY(200px);
  }
}

// Sidebar >> Dialog
.sidebar.modal-leave-active.to-dialog {
  z-index: 90;
  &, article {
    transition-duration: $section-transition-prepare;
    transition-property: opacity;
  }
  article + * {
    transition-duration: $section-transition-duration;
    transition-property: transform;
  }
}
.sidebar.modal-leave-to.to-dialog {
  opacity: 1;

  article {
    opacity: 0;
  }
  article + * {
    transform: translateY(200px);
  }
}
.dialog.modal-enter-active.from-sidebar {
  transition-duration: $section-transition-duration;
  transition-property: top, left;
  transition-delay: $section-transition-prepare;

  & main > form > * {
    transition-duration: $section-transition-prepare;
    transition-property: opacity;
    transition-delay: $section-transition-duration;
  }
  & main > form {
    transition-duration: $section-transition-duration;
    transition-property: height;
    transition-delay: $section-transition-prepare;
  }
}
.dialog.modal-enter.from-sidebar {
  top: -20px;
  left: calc(100% - 555px);

  & main > form > * {
    opacity: 0;
  }
}

// Dialog >> Sidebar
.sidebar.modal-enter-active.from-dialog {
  $instant: 0.01s;
  transition-duration: $instant;
  transition-property: opacity;
  transition-delay: ($section-transition-duration + $section-transition-prepare / 2 - $instant);
}
.sidebar.modal-enter.from-dialog {
  opacity: 0;
}
.dialog.modal-leave-active.to-sidebar {
  transition-duration: $section-transition-duration;
  transition-property: top, left;
  transition-delay: $section-transition-prepare / 2;
  z-index: 90;

  & main > form > * {
    transition-duration: $section-transition-prepare;
    transition-property: opacity;
  }
  & main > form {
    transition-duration: $section-transition-duration;
    transition-property: height;
    transition-delay: $section-transition-prepare / 2;
  }
}
.dialog.modal-leave-to.to-sidebar {
  top: -20px;
  left: calc(100% - 555px);

  & main > form > * {
    opacity: 0;
  }
}


// 
// Common widgets
// 

@keyframes rotate {
  from { transform: rotate(0deg); }
  to   { transform: rotate(360deg); }
}

// Loading indicator
#app.loading::after, #app.loading::before {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;

  display: block;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  border-radius: 50%;
  box-shadow: 0 3px 0 0 $primary-color;
  transform-origin: 24px 26px;
  animation: 1.2s linear infinite rotate;
  content: "";
}

#app.loading::before {
  animation-delay: -0.20s;
}

#app.loading #view {
  &::before {
    position: fixed;
    top: 0;
    left: $menu-width;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    background: rgba(255, 255, 255, 0.4);
    content: '';
  }
}
#app.authenticating.loading #view::before {
  left: 0;
}



// Content header
main > header {
  position: relative;
  margin-bottom: 40px;

  & > h1 {
    display: inline-block;
    margin-right: 24px;
    vertical-align: middle;
  }

  & > .filter {
    display: inline-block;
    vertical-align: middle;
  }

  & > .action, & > .actions {
    position: absolute;
    top: 0;
    right: 0;
  }

  & > .actions > button {
    margin-left: 15px;
  }

  .octopus.themed & {
    > h1 {
      display: block;
      margin: (-$section-padding) (-$section-padding) $section-padding;
      padding: 15px $section-padding;
      background: $octopus-header-background url(/images/logo-octopus.png) no-repeat 100% 50%;
      color: $light-text;
      font-weight: 500;
    }
    & > .action, & > .actions {
      top: 90px;
    }
  }
}

// Sidebar header
header.user {
  position: relative;
  top: -9px;

  display: flex;
  align-items: center;

  .portrait {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  h2 {
    max-width: 180px;
    padding-left: 0;
    padding-right: 0;
  }

  .switch {
    position: absolute;
    top: 16px;
    right: 30px;
    display: inline-block;
    padding: 7px;
    padding-left: 32px;
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;

    @include icon-left(13, 1);

    .octopus.themed & { color: $octopus-default-text }

    &.stats {
      @include icon-left(14, 1);
    }
  }
}

// Form header and fieldsets
main.form {
  margin: 0 auto;
  max-width: 674px;

  header {
    margin-bottom: 26px;

    h1 {
      display: block;
    }

    .back {
      position: relative;
      display: inline-block;
      padding-left: 16px;
      font-size: .8125rem;
      font-weight: 700;

      @include icon-left (16, 0);
      &::before { left: -10px; }
    }
  }

  .octopus.themed & {
    margin: 0;
    max-width: none;

    header ~ *, header > ul {
      box-sizing: border-box;
      margin: 0 auto;
      max-width: 674px;
    }

    header .back {
      position: absolute;
      top: 8px;
      color: $light-text;

      + h1 {
        padding-top: 23px;
        padding-bottom: 7px;
      }
    }
  }

  .fieldset {
    .legend {
      padding-left: 35px;

      @include icon-left (16, 2);
      &::before { left: -5px; }
    }

    display: flex;
    flex-flow: row wrap;
    align-content: stretch;

    .field, > .group {
      flex: 0 1 calc(50% - 12.5px);
      margin-right: 25px;

      &:nth-child(2n+1) {
        margin-right: 0;
      }
    }

    > .group {
      .field {
        margin-right: 0;
      }
    }

    &.vertical {
      .field, > .group {
        flex: 0 1 100%;
        margin-right: 0;
      }
    }
    
    section {
      display: flex;
      flex: 0 1 100%;
      
      .field {
        flex: 0 1 calc(33.33% - 16.66px);
        margin-right: 25px;

        &:nth-child(2n+1) {
          margin-right: 25px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .radio {
      font-size: .8125rem;

      & + .radio {
        margin-left: 10px;
      }
    }
  }

  .message {
    border-radius: 4px;
    padding: 30px;
    margin: 0 30px 30px;
    background: #f8fbfd;
    font-weight: 300;
    text-align: center;
  }

  .note {
    padding-top: 40px;
    font-weight: 300;
  }

  .compound-field {
    display: flex;
    overflow: hidden;
    border: 1px solid $border-color;

    select, textarea, input[type="text"], input[type="password"], input[type="email"], input[type="date"], input[type="number"] {
      border: 0;
    }
  }
}



// 
// Utility classes
// 

.invisible {
  overflow: hidden;
  text-indent: -999em;
  text-align: left;
}
span.invisible {
  position: absolute;
  left: -999em;
  text-align: left;
}

// 
// Typography
// 

html {
  background: $default-background;
  color: $default-text;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;

  #app.octopus.themed {
    color: $octopus-default-text;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: $bold-text;

  .octopus.themed & {
    color: $octopus-bold-text;
  }
}

h1 {
  font-size: 2rem;
}

h2, h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

ul, ol, dl, p {
  margin: 0;
}

ul, ol {
  list-style: none;
  padding-left: 0;
}

.unit {
  font-size: .6875em;
}

abbr[title] {
  text-decoration: none;
}

var {
  font-style: normal;
  // font-family: monospace;
  white-space: nowrap;
}


// 
// Inputs
// 

a {
  text-decoration: none;
  color: $primary-color;
}

button, select, textarea, input[type="search"], input[type="text"], input[type="password"], input[type="email"], input[type="date"], input[type="number"] {
  display: inline-block;
  box-sizing: border-box;
	margin: 0;
  padding: 11px 15px;
  border: 1px solid $border-color;
  border-radius: 0;
  background-color: $default-background;
  color: $default-text;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;

  // FIXME: The GroupSelect cannot set this dynamically
  & + span.value, & + span.placeholder {
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
  }

  // FIXME: iOS will zoom in when font size is below 16px
  // padding: 8px 15px;
  // font-size: 1rem;

  &:focus, &:hover {
    border-color: $outline-color;

    &:disabled {
      border-color: $input-border-color;
    }
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $default-text;
    -webkit-box-shadow: inset 0 0 0 1000px $autofill-color;
    box-shadow: inset 0 0 0 1000px $autofill-color;
  }

  &:disabled {
    // cursor: default;
    color: $inactive-text;
  }
}

input[type="search"] {
  outline-offset: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAXUlEQVR42p2PsQ2AQBDDsgQr8TAAS9IcNAz1S4CUAqFvrGDpypxsqR275rr/nLdar0mtej6u7q1Zzi194M0Hp0TqA29Koj7ilFDdQAqoQwqqQwqoYwqrcwqocwqpPyANy1lOFaotAAAAAElFTkSuQmCC),
    linear-gradient(to bottom, #fff 0%,#fff 100%);
  background-repeat: no-repeat, repeat;
  background-size: 8px 6px, 100%;
  background-position: right 15px top 50%, 0 0;

  option {
    font-size: normal;
  }

  &::-ms-expand {
    display: none;
  }

  &.empty {
    color: $placeholder-color;
  }

  &[multiple] {
    padding-right: 15;
    background: transparent;
  }
}

:focus {
  outline-color: $outline-color;
}

::placeholder {
  color: $placeholder-color;
}

body:not(.outline-focused-elements) {
  button, input, select, textarea {
    &:focus {
      outline: none;
    }
  }
}

button {
  cursor: pointer;
  white-space: nowrap;

  &.action, &.confirm, &.cancel, &.add-new {
    display: inline-block;
    min-width: 208px;
    padding: 13px 18px;
    border: 2px solid $primary-color;
    border-radius: 4px;
    background: $primary-color;
    color: $light-text;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25;
    text-transform: uppercase;

    .octopus.themed & {
      border-color: $octopus-primary-color;
      background-color: $octopus-primary-color;
    }

    &:disabled {
      cursor: default;
      background: $inactive-color;
      border-color: $inactive-color;

      .octopus.themed & {
        background-color: $octopus-inactive-color;
        border-color: $octopus-inactive-color;
      }
    }
  }

  &.cancel, &.secondary, &.add-new {
    background: $default-background;
    color: $primary-color;

    .octopus.themed & {
      background: $default-background;
      color: $octopus-primary-color;
    }

    &:disabled {
      background: $default-background;
      border-color: $inactive-color;
      color: $inactive-color;

      .octopus.themed & {
        border-color: $octopus-inactive-color;
        color: $octopus-inactive-color;
      }
    }
  }

  &.delete {
    border-color: $danger-color;
    background: $danger-background;
    color: $danger-color;

    .octopus.themed & {
      background: $default-background;
      color: $octopus-primary-color;
    }

    &:disabled {
      border-color: $danger-disabled-color;
      color: $danger-disabled-color;
    }

    &.confirm {
      border-color: $danger-color;
      background: $danger-color;
      color: $danger-background;

      &:disabled {
        border-color: $danger-disabled-color;
        background: $danger-disabled-color;

        .octopus.themed & {
          border-color: $octopus-inactive-color;
          color: $octopus-inactive-color;
        }
      }
    }
  }

  &.inline {
    display: inline-block;
    min-width: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: none;
  }

  &.small {
    padding: 7px 10px;
    min-width: 0;
    font-size: 0.875rem;
    line-height: 1.125;
  }

  &.add-new {
    padding: 5px 7px;
    min-width: 0;
    font-size: 0.75rem;
    line-height: 1.125;
  }
}

form {
  .error {
    margin-bottom: 25px;
    color: $error-color;
  }
}

fieldset, section.fieldset {
  position: relative;
  padding: 72px 30px 14px;
  margin: 16px 0 30px;
  border: 2px solid $section-border-color;
  border-radius: 4px;

  dialog & {
    border: 0;
    border-top: 1px solid $border-color;
    border-radius: 0;
    padding-bottom: 30px;
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      border-top: 0;
    }
  }

  legend, h2.legend, h3.legend, h4.legend {
    position: absolute;
    left: 30px;
    right: 30px;
    top: 27px;
    color: $bold-text;
    font-size: 1.375rem;
    font-weight: 700;

    .octopus.themed & { color: $octopus-bold-text }
  }
}

.field {
  position: relative;
  margin-bottom: 16px;

  label[for] {
    display: block;
    margin: 10px 0;
    color: $label-text;
    font-size: .875rem;
    font-weight: 700;
    text-transform: none;

    .octopus.themed & { color: $octopus-bold-text }

    .hint {
      color: $deemphasized-text;
      font-size: .75rem;
      font-weight: 400;
      line-height: 1.125;
      text-transform: none;
      margin-left: 0.5em;
    }

    &.error {
      text-transform: none;
      color: $error-color;

      &::before {
        display: inline-block;
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
        border-radius: 50%;
        background: $error-color;
        color: $default-background;
        font-size: .8125rem;
        font-weight: 700;
        text-align: center;
        content: "!";
      }
    }

  }

  .has-icon {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 21px;
      margin: -12px 0 0 -12px;
      width: 24px;
      height: auto;
      pointer-events: none;
    }

    select, textarea, input {
      padding-left: 38px;
    }
  }

  select, textarea, input[type="text"], input[type="password"], input[type="email"], input[type="date"], input[type="number"] {
    box-sizing: border-box;
    width: 100%;
    border: 2px solid $input-border-color;
    border-radius: 4px;
    font-size: .9375rem;

    // FIXME: The GroupSelect cannot set this dynamically
    & + span.value, & + span.placeholder {
      font-size: .9375rem;
    }

    &.erroneous {
      border-color: $erroneous-border-color;
    }
  }

  &.actions {
    margin-top: 16px;

    &.horizontal {
      display: flex;

      button + button {
        margin-top: 0;
        margin-left: 20px;
      }

      @media screen and (max-width: 506px) {
        display: block;

        button + button {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }

  .add-new {
    margin-top: 7px;
  }
}

label.radio, label.checkbox {
  font-size: .875rem;
  overflow: hidden;

  > input {
    position: absolute;
    left: -999em;
  }

  > span {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    
    @include icon-left (22, 0);
    &::before { left: -7px }
    &::after {
      position: absolute;
      top: 50%;
      left: 9px;
      display: none;
      box-sizing: border-box;
      margin-left: -9px;
      margin-top: -9px;
      width: 18px;
      height: 18px;
      border: 2px solid $outline-color;
      border-radius: 9px;
      content: "";
    }
  }

  &:hover > span, > input:focus + span { @include icon-location (22, 1) }
  > input:focus + span::after { display: block; }
  &:hover > input:disabled + span { @include icon-location (22, 0) }
  > input:disabled + span::before { opacity: 0.65 }
  > input:disabled + span { color: $inactive-text }
  > input:checked + span,
  &:hover > input:disabled:checked + span { @include icon-location (22, 2) }
}

label.checkbox {
  > span { @include icon-location (23, 0) }
  > span::after { border-radius: 2px; }
  &:hover > span, > input:focus + span { @include icon-location (23, 1) }
  &:hover > input:disabled + span { @include icon-location (23, 0) }
  > input:checked + span,
  &:hover > input:disabled:checked + span { @include icon-location (23, 2) }
}

.show-error-enter-active, .show-error-leave-active {
  transition: opacity .25s;
}
.show-error-enter, .show-error-leave-to {
  opacity: 0;
}

.reveal-enter-active, .reveal-leave-active {
  transition: opacity .25s;
}
.reveal-enter, .reveal-leave-to {
  opacity: 0;
}


// 
// Tables
// 

table {
  border-spacing: 0;
  line-height: 1.5;
  text-align: right;

  .textual {
    text-align: left;
  }

  .empty {
    text-align: center;
    font-weight: 500;
    color: $deemphasized-text;
  }

  .checkbox {
    text-align: left;
    padding-left: 0;
    padding-right: 0px;
  }

  td, th {
    padding-left: 20px;
    padding-right: 20px;

    &.amount {
      padding-right: 0;

      & + .change {
        padding-left: 0;
        padding-right: 5px;
        text-align: left;
      }
    }
  }

  tbody {
    td.balance {
      padding-right: 0;

      &.last {
        padding-right: 20px;
      }
    }
  }

  tbody, tfoot {
    td, th {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }

  th {
    font-weight: 500;
    text-align: left;
  }

  tbody {
    white-space: nowrap;
  }

  tbody td {
    small {
      margin-left: 6px;
      vertical-align: 0;
    }
  }

  th {
    color: $bold-text;

    .octopus.themed & { color: $octopus-bold-text }
  }

  // .unit.currency {
  //   font-size: 1em;
  // }

  thead {
    color: $table-head-text;
    font-size: .875rem;
    font-weight: 700;
    text-transform: uppercase;

    .octopus.themed & { color: $octopus-bold-text }

    td, th {
      padding-bottom: 16px;
      border-bottom: 2px solid $table-head-separator;
      line-height: 1.15;
    }

    // Sort button
    button {
      @include button(5, 0, 27px, 27px);
      margin: 0 -3px;
      padding-left: 3px;
      padding-right: 25px;
      margin-right: -25px;
      width: auto;
      height: auto;
      overflow: hidden;
      color: inherit;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      text-align: right;
      text-transform: inherit;
      text-indent: 0;
      white-space: normal;

      &::before {
        left: auto;
        right: -3px;
      }

      &.sorted { @include icon-location(5, 1); }
      &.sorted.ascending { @include icon-location(5, 2); }
    }

    // Header icons
    td {
      &.cable-id, &.car-reg, &.tariff {
        padding-left: 35px;

        @include icon-left (14, 0);

        &::before {
          top: 50%;
          left: 5px;
        }
      }

      &.cable-id { @include icon-location (13, 0) }
      &.tariff { @include icon-location (15, 0) }
    }
  }

  tbody {
    td, th {
      border-top: 1px solid $table-body-separator;
    }
    tr:last-child {
      td, th {
        border-bottom: 1px solid $table-body-separator;
      }
    }
    tr:first-child, .filters + tr {
      td, th {
        border-top: 0;
      }
    }
  }

  tfoot {
    td, th {
      border-top: 1px solid $table-body-separator;
      background: $table-foot-background;
      background: rgba(240, 243, 243, 0.95);
    }
  }

  // Big tables have more vertical padding
  &.big {
    // width: 100%;

    td, th {
      padding-left: 15px;
      padding-right: 15px;
    }

    tbody td.balance.last {
      padding-right: 15px;
    }

    tbody, tfoot {
      td, th {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    tbody th:first-child, tbody td:first-child, thead td:first-child {
      padding-left: 0;

      &.empty {
        padding-left: 15px;
        border-bottom: 0;
      }
    }

    .user {
      padding-top: 19px;
      padding-bottom: 15px;

      .portrait {
        position: relative;
        top: -2px;
        margin-right: 8px;
      }
    }
  }

  // Wide tables have more horizontal padding
  &.wide {
    min-width: 50%;
    
    td, th {
      padding-right: 25px;

      &.amount {
        padding-right: 0;
      }
      &.change {
        padding-right: 10px;
      }
    }
    tbody td.balance.last {
      padding-right: 25px;
    }
  }

  // Full-width tables occupy all available horizontal space
  &.full-width {
    min-width: 100%;
  }

  // Big tables may have a floating foot
  &.big tfoot {
    &.hidden {
      td, th {
        opacity: 0;
      }
    }
    td, th {
      position: relative;
      position: -webkit-sticky;
      position: sticky;
      bottom: 11px;
      z-index: 2;

      border: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      margin-top: 11px;
      margin-bottom: 11px;

      color: $bold-text;
      .octopus.themed & { color: $octopus-bold-text }
      font-weight: bold;
    }

    .invisible {
      background: transparent;
    }
  }

  // Big tables have a floating head
  &.big {
    margin-top: -18px;
  }

  &.big thead {
    td, th {
      position: relative;
      position: -webkit-sticky;
      position: sticky;
      top: -3px;
      z-index: 2;
      padding-top: 18px;
      background: $default-background;
    }
  }

  // Controls
  &.controlled {
    margin-bottom: 58px;

    td.checkbox {
      width: 20px;

      label {
        display: block;
        overflow: hidden;
        width: 10px;
        padding: 5px 8px 4px 2px;
        margin: -10px -8px -10px 0;
      }

      label, input {
        cursor: pointer;
      }
    }
  }

  // Filters
  .filters, &.big .filters {
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 0;
    }
    input[type="search"] {
      padding: 4px 8px;
      width: 100%;
    }
    select {
      padding: 4px 20px 4px 8px;
      width: 100%;
      background-position: right 10px top 50%, 0 0;
    }
  }

  td.filter {
    input[type="search"] {
      padding: 4px 8px;
      width: 100%;
    }
  }
}

// 
// Badge
// 

.badge {
  display: inline-block;
  padding: 2px;
  background: $alt-background;
  color: $bold-text;
  .octopus.themed & { color: $octopus-bold-text }
  text-transform: uppercase;
  font-size: .625rem;
  font-weight: 500;
  line-height: 1.2;
}


//
// Quick stats for management pages
//
aside.highlights {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 40px;

  > * {
    flex-grow: 1;
    flex-basis: 100%;
  }

  > * + * {
    margin-left: 30px;
  }

  .stat-link {
    flex-grow: 0;
    flex-basis: 270px;
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 270px;
    padding: 50px 30px 20px;
    background: $menu-background;
    font-size: .75rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    .octopus.themed & { 
      background: #F5F7FC;
      color: #100030;
    }

    @media screen and (max-width: 1280px) {
      display: none;
    }

    @include icon (1, 2);
    &::before {
      top: 30px;
    }
  }
}

//
// Category slider 
//
.categories {
  position: relative;
  margin-top: -10px;
  height: 55px;
  padding-left: 120px;
  margin-bottom: 30px;

  h2 {
    position: absolute;
    top: 3px;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    padding-left: 40px;
    background: $default-background;
    font-size: .75rem;
    font-weight: 300;
    cursor: pointer;

    &.cars {
      @include icon-left (14, 2);
    }
    &.chargers {
      @include icon-left (13, 2);
    }

    &::after { margin-top: -11px; }

    .amount {
      display: block;
      font-size: 1.375rem;
      font-weight: 700;
    }
  }

  ul {
    white-space: nowrap;
  }

  li {
    display: inline-block;
    padding-left: 25px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    white-space: nowrap;

    & + li {
      border-left: 1px solid $border-color;
      margin-left: 15px;
    }

    .category, .amount {
      display: block;
    }

    .amount {
      font-size: .75rem;
      font-weight: 300;
    }

    button {
      border: 0;
      padding: 7px 10px;
      margin: -7px -10px;
      border-radius: 4px;
      background: transparent;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-align: inherit;

      &:focus, &:hover {
        background: $filter-hover-background;
      }
    }

    &.current button {
      background: $filter-selected-background;
      .category {
        color: $primary-color;
      }
    }
  }


  &.with-logos {
    button {
      padding-left: 43px;
    }

    .logo {
      position: absolute;
      top: 2px;
      left: 21px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 32px;
      height: 32px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}



//
// Selection actions
//
.selection-actions {
  position: fixed;
  bottom: 30px;
  right: 30px;
  left: $menu-width + $section-padding;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: stretch;
  padding: 18px 25px;
  background: $table-foot-background;
  background: rgba(240, 243, 243, 0.95);

  kbd {
    display: inline-block;
    font-family: inherit;
    font-size: inherit;

    .outline-focused-elements & {
      border-bottom: 1px dotted;
      margin-bottom: -1px;
    }
  }

  p {
    margin-right: 25px;
  }

  ul {
    flex: 1 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  li {
    margin: -2px 0;
    padding: 0 15px;

    & + li {
      border-left: 1px solid $table-head-separator;
    }
  }

  button {
    position: relative;
    margin: -7px 0;
    border: 0;
    padding-left: 33px;
    background: transparent;
    font-size: 1rem;
    font-weight: 700;
    color: $primary-color;

    @include icon-left(17, 0);

    &.deactivate { @include icon-location(19, 0) }
    &.activate { @include icon-location(21, 0) }
    &.delete { @include icon-location(20, 0) }
    &.export { @include icon-location(18, 0) }
  }
}

a.downloader {
  position: absolute;
  left: -999em;
}
</style>