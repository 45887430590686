<template>
  <dialog open ref="modal">
    <div class="backdrop" @click.self="$emit('close')" @keyup.esc="$emit('close')" />
    <slot />
  </dialog>
</template>

<script>
import { Modal } from '~/mixins'

export default {
  mixins: [Modal],
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: $transparent-color;
}

dialog {
  position: static;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  color: $default-text;
}
</style>

<style lang="scss">
// 
// Bottom Sheet transition
// 
.bottom-sheet-enter-active, .bottom-sheet-leave-active {
  // Fake transition to supply timing to <transition> component
  transition: opacity .4s;

  .sheet {
    transition: transform .25s;
    + .shade {
      transition: opacity .4s;
    }
  }
}
.bottom-sheet-enter, .bottom-sheet-leave-to {
  opacity: 1;

  .sheet {
    transform: translateY(200px);
    + .shade {
      opacity: 0;
    }
  }
}
.bottom-sheet-enter .sheet {
  transition-delay: .15s
}

// 
// Inner Sheet transition
// 
.inner-sheet-enter-active, .inner-sheet-leave-active {
  transition: opacity .22s;
  &.command {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &.command > p, &.command > p + button,
  &.confirm > p, &.confirm > p + .actions {
    transition: transform .20s;
  }
  &.command > p + button,
  &.confirm > p + .actions {
    transition-delay: 0.02s
  }
}
.inner-sheet-enter, .inner-sheet-leave-to {
  opacity: 1;
  &.command > p, &.command > p + button {
    transform: translateY(80px);
  }
  &.confirm > p, &.confirm > p + .actions {
    transform: translateY(-80px);
  }
}
.inner-sheet-enter-to, .inner-sheet-leave {
  opacity: 1;
  &.command > p, &.command > p + button,
  &.confirm > p, &.confirm > p + .actions {
    transform: translateY(0);
  }
}
</style>