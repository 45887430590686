import * as firebase from 'firebase/app'
import 'firebase/auth'

export const state = {
  id: null,
  name: null,
  email: null,
  photo: null,
}

export const getters = {
  isAuthenticated: state => state.id !== null,
  id: state => state.id,
  name: state => state.name,
  email: state => state.email
}

export const mutations = {
  setName (state, name) {
    state.name = name
  },
  setEmail (state, email) {
    state.email = email
  },
  signIn (state, user) {
    state.id = user.uid
    state.name = user.displayName
    state.email = user.email
    state.photo = user.photoURL
  },
  signOut (state) {
    state.id = null
    state.name = null
    state.email = null
    state.photo = null
  }
}

export const actions = {
  updateDetails ({commit}, details) {
    const payload = {}
    if (details.name != null) payload['displayName'] = details.name
    // if (details.email != null) payload['email'] = details.email
    return firebase.auth().currentUser
      .updateProfile(payload)
      .then(() => {
        if (details.name != null) commit('setName', details.name)
        // if (details.email != null) commit('setEmail', details.email)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}