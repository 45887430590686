import Vue from 'vue'
import { createGroup, updateGroup, deleteGroup } from '~/api'

export const state = {
  rootUserGroup: null, // {id: "", name: ""}
  rootDeviceGroup: null, // {id: "", name: ""}
  groups: {
    // "id": {id: "", name: "", parendId: "", type: "user" | "device"}
  }
}

/*
  Search through all groups and return an array of groups sorted by their
  parent and name, adding properties:
    - `level: 0` – equal to the amount of non-root parents the group has
    - `parents: [true | false, ...]` – a mask indicating groups connections
    - `last: true | false` – is it the last item in the group?
*/
function indentAndSort (groups, rootId) {
  const haystack = Object.values(groups)
  const hierarchy = buildHierarchyFor(rootId)
  return flattenHierarchy(hierarchy, 0, [])

  function buildHierarchyFor(groupId) {
    return haystack.filter(g => g.parentId === groupId).sort(byName).map(g => {
      return { id: g.id, children: buildHierarchyFor(g.id) }
    })
  }
  
  function flattenHierarchy(hierarchy, level, parents) {
    const result = []
    const amount = hierarchy.length
    const mask = parents.slice()
    mask.push(true)

    hierarchy.forEach((h, i) => {
      groups[h.id].level = level
      groups[h.id].parents = mask.slice()
      groups[h.id].last = i === amount - 1
      result.push(groups[h.id])

      if (i === amount - 1) mask[mask.length - 1] = false

      flattenHierarchy(h.children, level + 1, mask).forEach(g => result.push(g))
    })

    return result
  }
}

function byName (a, b) { return a.name < b.name ? -1 : 1 }

function getFilterIdsByParentId (groups, parentId) {
  let foundIds = []
  let foundLevel = null

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i]

    // Find the group
    if (foundLevel == null && group.id == parentId) {
      foundLevel = group.level
      foundIds.push(parentId)
    }
    // Start appending its children
    else if (foundLevel != null && foundLevel < group.level) {
      foundIds.push(group.id)
    }
    // Stop when another group of the same level is found
    else if (foundLevel != null && foundLevel >= group.level) {
      break
    }
  }

  return foundIds
}

export const getters = {
  rootUserGroup: state => state.rootUserGroup,
  rootDeviceGroup: state => state.rootDeviceGroup,
  userGroupCount: (_, getters) => getters.userGroups.length,
  deviceGroupCount: (_, getters) => getters.deviceGroups.length,
  groups: state => Object.values(state.groups),
  userGroups: state => state.rootUserGroup == null? [] : indentAndSort(state.groups, state.rootUserGroup.id),
  deviceGroups: state => state.rootDeviceGroup == null? [] : indentAndSort(state.groups, state.rootDeviceGroup.id),
  getGroupById: state => id => state.groups[id],

  getParentsById: (state, getters) => id => {
    const parents = []
    let parent = getters.getGroupById(getters.getGroupById(id).parentId)

    while (parent != null && [state.rootDeviceGroup, state.rootUserGroup].indexOf(parent.id) === -1) {
      parents.unshift(parent)
      parent = getters.getGroupById(parent.parentId)
    }

    return parents
  },
  
  // Return all applicable IDs for given filter (parent id) value
  userGroupFilterIds: (_, getters) => id => getFilterIdsByParentId(getters.userGroups, id),
  deviceGroupFilterIds: (_, getters) => id => getFilterIdsByParentId(getters.deviceGroups, id)
}

export const mutations = {
  reset (state) {
    state.rootUserGroup = null
    state.rootDeviceGroup = null
    state.groups = {}
  },
  setRootUserGroupId (state, id) {
    state.rootUserGroup = {id: id, name: 'All drivers', type: 'user'}
  },
  setRootDeviceGroupId (state, id) {
    state.rootDeviceGroup = {id: id, name: 'All chargers', type: 'device'}
  },
  addGroup (state, group) {
    if (group.id === state.rootUserGroup.id || group.id === state.rootDeviceGroup.id) 
      return
    Vue.set(state.groups, group.id, group)
  },
  patchGroup (state, group) {
    const old = state.groups[group.id]

    if (old == undefined) {
      Vue.set(state.groups, group.id, group)
    }
    else for (let prop in group) {
      if (group.hasOwnProperty(prop)) Vue.set(old, prop, group[prop])
    }
  },
  removeGroup(state, groupId) {
    Vue.delete(state.groups, groupId)
  },
}

export const actions = {
  createGroup ({ rootGetters, commit }, data) {
    return createGroup(rootGetters['Fleet/fleetId'], data).then((g) => {
      commit('addGroup', g)
      return g
    })
  },
  updateGroup ({ rootGetters, commit }, data) {
    return updateGroup(rootGetters['Fleet/fleetId'], data).then((g) => {
      commit('patchGroup', g)
      return g
    })
  },
  deleteGroupById ({ rootGetters, commit }, groupId) {
    return deleteGroup(rootGetters['Fleet/fleetId'], groupId).then((id) => {
      commit('removeGroup', id)
      return id
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}