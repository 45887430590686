<template>
  <div class="sidebar" @keyup.esc="$router.push({name: back})" ref="modal">
    <router-link class="backdrop" :to="{name: back}" tag="div" />
    <router-link class="close init-focus" :to="{name: back}" tag="button">Close</router-link>

    <article>
      <slot />
    </article>

    <slot name="controls" />
  </div>
</template>

<script>
import { Modal } from '~/mixins'

export default {
  mixins: [Modal],
  props: {
    back: {
      type: String,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

</style>