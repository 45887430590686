import Vue from 'vue'
import { createCharger, updateCharger, deleteCharger, getStatusOfChargerSockets } from '~/api'

export const state = {
  models: {
    /*
    "id": {
      id: "",
      make: "",
      model: "",
      version: "",
      sockets: 0,
      phases: "1 | 2",
      maxPower: 0,
      connector: "Type 1 | Type 2 | CHAdeMO | CCS | NC"
    }
    */
  },
  chargers: {
    /*
    "id": {
      id: "",
      serialId: "",
      sockets: [
        {
          id: "",
          externalId: "",
          status: "preparing|charging|finishing|reserved|available|suspended|unavailable|offline|error"
        }
      ],
      name: "",
      groupIds: [""],
      paygProductId: "",
      address: {
        streetNumber: "",
        streetName: "",
        city: "",
        postcode: ""
      },
      location: {
        latitude: 0,
        longitude: 0
      },
      locationType: "workplace|private|public",
      refreshInterval: 0
    }
    */
  }
}

export const getters = {
  chargerCount: (_, getters) => getters.chargers.length,
  chargers: state => Object.values(state.chargers).sort((a, b) => a.name < b.name ? -1 : 1),
  chargerMakes: state => {
    const makes = {}
    Object.values(state.models).forEach(m => makes[m.make] = m.make)
    return Object.values(makes).sort((a, b) => a < b ? -1 : 1)
  },
  chargerModelsByMake: state => make => Object.values(state.models).filter(m => m.make === make).sort((a, b) => a.model < b.model ? -1 : 1),
  getChargerById: state => id => state.chargers[id],
  getChargerModelById: state => id => state.models[id],
  chargersByGroupId: (_, getters) => groupId => getters.chargers.filter(c => c.groupIds.indexOf(groupId) !== -1),
  chargerCountByGroupId: (_, getters) => groupId => getters.chargersByGroupId(groupId).length,
}

export const mutations = {
  reset (state) {
    state.chargers = {}
  },
  addChargerModel (state, model) {
    Vue.set(state.models, model.id, model)
  },
  addCharger (state, charger) {
    Vue.set(state.chargers, charger.id, charger)
  },
  patchCharger (state, charger) {
    const old = state.chargers[charger.id]

    if (old == undefined) {
      Vue.set(state.chargers, charger.id, charger)
    }
    else for (let prop in charger) {
      if (charger.hasOwnProperty(prop)) Vue.set(old, prop, charger[prop])
    }
  },
  updateSocketStatus (state, data) {
    const socketId = data.id
    const chargerId = data.chargerId
    const status = data.status
    const charger = state.chargers[chargerId]
    
    if (charger == null) {
      console.error('Unknown charger ID:', chargerId)
    }
    else for (let s = 0; s < charger.sockets.length; s++) {
      const socket = charger.sockets[s];
      
      if (socket.id !== socketId) continue

      charger.sockets[s].status = status
    }
  },
  removeCharger(state, chargerId) {
    Vue.delete(state.chargers, chargerId)
  },
}

export const actions = {
  createCharger ({ rootGetters, commit }, data) {
    return createCharger(rootGetters['Fleet/fleetId'], data)
      .then((c) => {
        commit('addCharger', c)
        // FIXME: There should not be a need for this. The charger object should just contain the actual socket statuses.
        getStatusOfChargerSockets(rootGetters['Fleet/fleetId'])
        .then((socketStatus) => {
          socketStatus
          .filter((ss => c.id === ss.chargerId))
          .forEach(ss => commit('updateSocketStatus', ss))
        })
        return c
      })
  },
  updateCharger ({ rootGetters, commit }, data) {
    return updateCharger(rootGetters['Fleet/fleetId'], data).then((c) => {
      commit('patchCharger', c)
      return c
    })
  },
  deleteCharger ({ rootGetters, commit }, charger) {
    return deleteCharger(rootGetters['Fleet/fleetId'], charger.sockets[0].id).then((id) => {
      commit('removeCharger', charger.id)
      return id
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}