export const currencies = {
  'GBP': {
    divisor: 100,
    major: { symbol: '£', prefix: true},
    minor: { symbol: 'p', prefix: false}
  },
  'USD': {
    divisor: 100,
    major: { symbol: '$', prefix: true},
    minor: { symbol: '¢', prefix: true}
  },
  'EUR': {
    divisor: 100,
    major: { symbol: '€', prefix: true},
    minor: { symbol: '¢', prefix: false}
  }
}