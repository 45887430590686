<template>
  <main>
    <header>
      <h1>Driver statistics</h1>
      <DateFilter class="filter" />
      <div class="actions">
        <button @click="downloadUserData" class="action" :disabled="loadingStats">Export data</button>
        <button @click="downloadUserDataSummary" class="action" :disabled="loadingStats">Export summary</button>
      </div>
    </header>

    <StatisticsTabs @change="changeTable" />

    <table class="big wide controlled" ref="table">
      <thead>
        <tr>
          <td class="checkbox"><label class="checkbox"><input type="checkbox" v-model="selectedAll"><span> Select all</span></label></td>
          <td class="filter"><input type="search" :value="nameFilter" @input="changeNameFilter" placeholder="Search user by name…" /></td>
          <template v-if="table === 'cost'">
            <td><button :class="{ sorted: sortColumn === 'cost', ascending: sortAscending }" @click="sort('cost')">Cost</button></td>
            <td><button :class="{ sorted: sortColumn === 'cpd', ascending: sortAscending }" @click="sort('cpd')">Per {{units.distanceSingular}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'cp', ascending: sortAscending }" @click="sort('cp')">Business / Personal</button></td>
            <td v-if="isCorporate" colspan="3" class="balance"><button :class="{ sorted: sortColumn === 'cbhp', ascending: sortAscending }" @click="sort('cbhp')">Charged at Work / Home / Public</button></td>
            <td><button :class="{ sorted: sortColumn === 'cice', ascending: sortAscending }" @click="sort('cice')">Saved vs <abbr title="Internal Combustion Engine">ICE</abbr></button></td>
            <td><button :class="{ sorted: sortColumn === 'cohme', ascending: sortAscending }" @click="sort('cohme')">Ohme savings</button></td>
          </template>
          <template v-if="table === 'energy'">
            <td><button :class="{ sorted: sortColumn === 'energy', ascending: sortAscending }" @click="sort('energy')">Energy</button></td>
            <td><button :class="{ sorted: sortColumn === 'epd', ascending: sortAscending }" @click="sort('epd')">Per {{units.distanceSingular}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'ep', ascending: sortAscending }" @click="sort('ep')">Business / Personal</button></td>
            <td v-if="isCorporate" colspan="3" class="balance"><button :class="{ sorted: sortColumn === 'ebhp', ascending: sortAscending }" @click="sort('ebhp')">Charged at Work / Home / Public</button></td>
          </template>
          <template v-if="table === 'estimate_distance'">
            <td><button :class="{ sorted: sortColumn === 'distance', ascending: sortAscending }" @click="sort('distance')">{{units.distancePlural}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'dp', ascending: sortAscending }" @click="sort('dp')">Business / Personal</button></td>
          </template>
          <template v-if="table === 'reported_distance' && isCorporate">
            <td><button :class="{ sorted: sortColumn === 'reported_distance', ascending: sortAscending }" @click="sort('reported_distance')">{{units.distancePlural}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'rdp', ascending: sortAscending }" @click="sort('rdp')">Business / Personal</button></td>
          </template>
          <template v-if="table === 'co2'">
            <td><button :class="{ sorted: sortColumn === 'co2', ascending: sortAscending }" @click="sort('co2')">CO2 emissions</button></td>
            <td><button :class="{ sorted: sortColumn === 'spd', ascending: sortAscending }" @click="sort('spd')">Per {{units.distanceSingular}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'sp', ascending: sortAscending }" @click="sort('sp')">Business / Personal</button></td>
            <td v-if="isCorporate" colspan="3" class="balance"><button :class="{ sorted: sortColumn === 'sbhp', ascending: sortAscending }" @click="sort('sbhp')">Charged at Work / Home / Public</button></td>
            <td><button :class="{ sorted: sortColumn === 'sice', ascending: sortAscending }" @click="sort('sice')">Saved vs <abbr title="Internal Combustion Engine">ICE</abbr></button></td>
            <td><button :class="{ sorted: sortColumn === 'sohme', ascending: sortAscending }" @click="sort('sohme')">Ohme savings</button></td>
          </template>
        </tr>
      </thead>
      <tfoot v-if="userData.length > 0" :class="{hidden: selectionSize > 0}">
        <tr>
          <td class="checkbox"></td>
          <th>Average:</th>
          <template v-if="table === 'cost'">
            <td><Amount :value="averageCost" type="currency" /></td>
            <td><Amount :value="averageCPD" type="currency_small" /></td>
            <td v-if="isCorporate" colspan="5">&nbsp;</td>
            <td><Amount :value="averageCostSavings" type="currency" /></td>
            <td><Amount :value="averageCostOhme" type="currency" /></td>
          </template>
          <template v-if="table === 'energy'">
            <td><Amount :value="averageEnergy" type="energy" /></td>
            <td><Amount :value="averageEPD" type="energy" /></td>
            <td v-if="isCorporate" colspan="5">&nbsp;</td>
          </template>
          <template v-if="table === 'estimate_distance'">
            <td><Amount :value="averageDistance" type="distance" /></td>
            <td v-if="isCorporate" colspan="2">&nbsp;</td>
          </template>
          <template v-if="table === 'reported_distance' && isCorporate">
            <td><Amount :value="averageReportedDistance" type="distance" /></td>
            <td v-if="isCorporate" colspan="2">&nbsp;</td>
          </template>
          <template v-if="table === 'co2'">
            <td><Amount :value="averageCo2" type="weight" /></td>
            <td><Amount :value="averageCo2PD" type="weight" :decimal="1" /></td>
            <td v-if="isCorporate" colspan="5">&nbsp;</td>
            <td><Amount :value="averageCo2Savings" type="weight" /></td>
            <td><Amount :value="averageCo2Ohme" type="weight" /></td>
          </template>
        </tr>
      </tfoot>
      <tbody ref="table-body">
        <tr v-if="loadingStats">
          <td :colspan="
              table === 'cost'? (isCorporate ? 10 : 5) : 
              table === 'energy'? (isCorporate ? 8 : 3) : 
              table === 'estimate_distance'? (isCorporate ? 4 : 2) : 
              table === 'reported_distance'? (isCorporate ? 4 : 2) : 
              table === 'co2'? (isCorporate ? 10 : 5) : 0
            " class="empty">
            <Loading />
          </td>
        </tr>
        <tr v-else-if="userData.length == 0">
          <td :colspan="
              table === 'cost'? (isCorporate ? 10 : 5) : 
              table === 'energy'? (isCorporate ? 8 : 3) : 
              table === 'estimate_distance'? (isCorporate ? 4 : 2) : 
              table === 'reported_distance'? (isCorporate ? 4 : 2) : 
              table === 'co2'? (isCorporate ? 10 : 5) : 0
            " class="empty">{{isFiltered? 'No records matching the criteria were found' : 'No data to display'}}</td>
        </tr>
        <tr v-else v-for="(u, i) in userData" :key="u.id">
          <td class="checkbox"><label class="checkbox"><input type="checkbox" v-model="selectedIds" :value="u.id"><span> Select {{u.name}}</span></label></td>
          <template v-if="isRowHidden(i)">
            <th><router-link :to="{name: 'driver-stats-driver-stats', params: {userId: u.id}}">{{u.name}}</router-link></th>
            <td v-if="table === 'cost'" :colspan="isCorporate ? 9 : 4">&nbsp;</td>
            <td v-if="table === 'energy'" :colspan="isCorporate ? 7 : 2">&nbsp;</td>
            <td v-if="table === 'estimate_distance'" :colspan="isCorporate ? 3 : 1">&nbsp;</td>
            <td v-if="table === 'reported_distance'" :colspan="isCorporate ? 3 : 1">&nbsp;</td>
            <td v-if="table === 'co2'" :colspan="isCorporate ? 9 : 4">&nbsp;</td>
          </template>
          <template v-else>
            <th class="user"><router-link :to="{name: 'driver-stats-driver-stats', params: {userId: u.id}}">
              <Portrait :src="u.photo" class="portrait" /> {{u.name}}
            </router-link></th>
            <template v-if="table === 'cost'">
              <td><Amount :value="u.cost ? u.cost.total : null" type="currency" /></td>
              <td><Amount :value="u.cost ? u.cost.perDistance : null" type="currency_small" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.cost ? u.cost.totalBusiness : null" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="personal" :value="u.cost ? u.cost.totalPersonal : null " /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.cost ? u.cost.totalWorkCharge : null " /></td>
              <td v-if="isCorporate" class="balance"><Share type="home" :value="u.cost ? u.cost.totalHomeCharge : null" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="public" :value="u.cost ? u.cost.totalDestinationCharge : null" /></td>
              <td><Amount :value="u.cost ? u.cost.iceSavings : null" type="currency" /></td>
              <td><Amount :value="u.cost ? u.cost.ohmeSavings : null" type="currency" /></td>
            </template>
            <template v-if="table === 'energy'">
              <td><Amount :value="u.energy ?  u.energy.total : null" type="energy" /></td>
              <td><Amount :value="u.energy ? u.energy.perDistance : null" type="energy" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.energy ? u.energy.totalBusiness : null" unit="energy" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="personal" :value="u.energy ? u.energy.totalPersonal : null" unit="energy" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.energy ? u.energy.totalWorkCharge : null" unit="energy" /></td>
              <td v-if="isCorporate" class="balance"><Share type="home" :value="u.energy ? u.energy.totalHomeCharge : null" unit="energy" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="public" :value="u.energy ? u.energy.totalDestinationCharge : null" unit="energy" /></td>
            </template>
            <template v-if="table === 'estimate_distance'">
              <td><Amount :value="u.distance.total" type="distance" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.distance.totalBusiness" unit="distance" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="personal" :value="u.distance.totalPersonal" unit="distance" /></td>
            </template>
            <template v-if="table === 'reported_distance' && isCorporate">
              <td><Amount :value="u.reportedDistance.total" type="distance" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.reportedDistance.totalBusiness" unit="distance" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="personal" :value="u.reportedDistance.totalPersonal" unit="distance" /></td>
            </template>
            <template v-if="table === 'co2'">
              <td><Amount :value="u.co2.total" type="weight" /></td>
              <td><Amount :value="u.co2.perDistance" type="weight" :decimal="1" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.co2.totalBusiness" unit="weight" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="personal" :value="u.co2.totalPersonal" unit="weight" /></td>
              <td v-if="isCorporate" class="balance"><Share type="business" :value="u.co2.totalWorkCharge" unit="weight" /></td>
              <td v-if="isCorporate" class="balance"><Share type="home" :value="u.co2.totalHomeCharge" unit="weight" /></td>
              <td v-if="isCorporate" class="balance last"><Share type="public" :value="u.co2.totalDestinationCharge" unit="weight" /></td>
              <td><Amount :value="u.co2.iceSavings" type="weight" /></td>
              <td><Amount :value="u.co2.ohmeSavings" type="weight" /></td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>

    <div v-if="selectionSize > 0" class="selection-actions">
      <p>{{selectionSize}} item{{selectionSize > 1 ? 's' : ''}} selected</p>
      <ul>
        <li><button class="export" @click="downloadUserData">Export data for user{{selectionSize > 1 ? 's' : ''}}</button></li>
      </ul>
    </div>

    <transition name="modal"
      @before-leave="beforeModalLeaves"
      @before-enter="beforeModalEnters"
      @enter="whenModalEnters"
      @leave="whenModalLeaves"
      @after-leave="afterModalLeaves"
      @after-enter="afterModalEnters"
    >
      <router-view />
    </transition>
  </main>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters, mapActions, mapState } from 'vuex'

import StatisticsTabs from 'elements/StatisticsTabs'
import Portrait from 'elements/Portrait'
import Amount from 'elements/Amount'
import Share from 'elements/Share'
import DateFilter from 'elements/DateFilter'
import Loading from "elements/SVGs/Loading";

import { SortableStats, HiddenRows, Downloader, ModalOwner } from '~/mixins'

export default {
  mixins: [SortableStats, HiddenRows, Downloader, ModalOwner],
  components: { StatisticsTabs, Amount, Share, Portrait, DateFilter, Loading },
  data () {
    return {
      table: 'cost',
      selectedIds: [],
      exportData: false,
      nameFilter: null,
    }
  },
  computed: {
    ...mapGetters(['units']),
    ...mapGetters('Fleet', ['isCorporate']),
    ...mapGetters('Users', ['users']),
    ...mapState('Stats', ['loadingStats']),

    filtered () {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.trim().toLowerCase()) !== -1
      }

      return this.users.filter(user => {
        if (user.invited) return false
        else if (this.nameFilter != null) return matches(this.nameFilter, user.name)
        else return true
      })
    },
    isFiltered () {
      return this.nameFilter != null
    },
    selectionSize () {
      return this.selectedIds.length
    },
    selectedAll: {
      get () {
        return this.selectedIds.length > 0 && this.selectedIds.length === this.users.length
      },
      set (value) {
        this.selectedIds = value ? this.users.map(u => u.id) : []
      }
    },
    userData () {
      return this.filtered.map(s => s).sort(this.order)
    },
    averageCost () {
      return this.averageForGetter(u => u.cost ? u.cost.total : null)
    },
    averageCPD () {
      return this.averageForGetter(u => u.cost ? u.cost.perDistance : null)
    },
    averageCostSavings () {
      return this.averageForGetter(u => u.cost ? u.cost.iceSavings : null)
    },
    averageCostOhme () {
      return this.averageForGetter(u => u.cost ? u.cost.ohmeSavings : null)
    },
    averageEnergy () {
      return this.averageForGetter(u => u.energy ? u.energy.total : null)
    },
    averageEPD () {
      return this.averageForGetter(u => u.energy ? u.energy.perDistance  : null)
    },
    averageDistance () {
      return this.averageForGetter(u => u.distance? u.distance.total  : null)
    },
    averageReportedDistance () {
      return this.averageForGetter(u => u.reportedDistance ? u.reportedDistance.total  : null)
    },
    averageCo2 () {
      return this.averageForGetter(u => u.co2 ? u.co2.total  : null)
    },
    averageCo2PD () {
      return this.averageForGetter(u => u.co2 ? u.co2.perDistance  : null)
    },
    averageCo2Savings () {
      return this.averageForGetter(u => u.co2 ? u.co2.iceSavings  : null)
    },
    averageCo2Ohme () {
      return this.averageForGetter(u => u.co2 ? u.co2.ohmeSavings  : null)
    },
  },
  methods: {
    ...mapActions('Notifications', ['showSuccessMessage']),

    changeTable (table) { this.table = table },
    changeNameFilter: debounce(function (e) { this.nameFilter = e.target.value }, 300),
    averageForGetter (getter) {
      let count = 0
      let sum = 0

      this.userData.forEach(u => {
        const datum = getter(u)

        if (datum > 0) {
          count++
          sum += datum
        }
      })

      return count > 0 ? sum / count : 0
    },
    ...mapActions(['exportUserStatisticsForUserIds', 'exportUserStatisticsSummary']),
    downloadUserData (e) {
      this.download(e.target, 'users.xlsx', () => this.exportUserStatisticsForUserIds(this.selectedIds)).then(() => {
        this.showSuccessMessage('All data exported successfully')
      })
    },
    downloadUserDataSummary (e) {
      this.download(e.target, 'users-summary.xlsx', () => this.exportUserStatisticsSummary()).then(() => {
        this.showSuccessMessage('All data exported successfully')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

table {
  .filter {
    padding-right: 0;
  }
  tbody th {
    padding-left: 0;
  }
  tfoot th {
    color: $bold-text;
    padding-left: 42px;
  }
}
</style>
