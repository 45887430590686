<template>
  <WelcomeLayout>
    <transition name="dive" appear><main v-if="!hidden" class="form reverse static">
      <h1>Confirm authentication</h1>
      <div class="instructions">
        <p>Are you sure you want to authenticate <strong>{{client_id}}</strong> using your credentials?</p>
        <template v-if="client_id == 'octopus'">
          <p>Confirming authentication will mean the following:</p>
          <ol>
            <li>Octopus will access your Ohme charger using your credentials</li>
            <li>Ohme will update your tariff to Intelligent Octopus in the Tariff section of your app</li>
          </ol>
        </template>
        <template v-else-if="client_id == 'kaluza'">
          <p>Confirming authentication will mean the following:</p>
          <ol>
            <li>Kaluza will access your Ohme charger using your credentials</li>
            <li>Ohme will update your tariff to 10p/kWh OVO Charge Anytime in the Tariff section of your app</li>
          </ol>
        </template>
      </div>
      <div class="field actions horizontal">
        <button class="action" @click.prevent="onConfirm">{{'Confirm'}}</button>
        <button class="action secondary" @click.prevent="onCancel">Cancel</button>
      </div>
    </main></transition>
  </WelcomeLayout>
</template>

<script>
import { mapActions } from 'vuex'

import * as firebase from 'firebase/app'
import 'firebase/auth'

import WelcomeLayout from 'layouts/Welcome'

import { getAuthenticationCode, validateAuthenticationURL } from '~/api'

export default {
  components: { WelcomeLayout },
  data () {
    return {
      hidden: true,

      client_id: null,
      redirect_uri: null,
      scope: null,
      state: null
    }
  },
  created () {
    this.client_id = this.$route.query.client_id
    this.redirect_uri = this.$route.query.redirect_uri
    this.scope = this.$route.query.scope
    this.state = this.$route.query.state

    // If no cliend_id or redirect back to login
    if (this.client_id == null || this.redirect_uri == null) {
      return this.$router.replace({name: 'login'})
    }
    else {
      validateAuthenticationURL(this.client_id, this.redirect_uri)
      .then(({valid}) => {
        if (!valid) {
          this.showErrorMessage(`Invalid redirection URL: ${this.redirect_uri}`)
          this.backToLogin()
        } else {
          this.hidden = false
        }
      })
      .catch(() => {
        this.showErrorMessage(`Could not validate redirection URL: ${this.redirect_uri}`)
        this.backToLogin()
      })
    }
  },
  methods: {
    ...mapActions('Notifications', ['showErrorMessage']),
    backToLogin () {
      this.$router.replace({name: 'login', query: {
        response_type: 'code',
        client_id: this.client_id,
        redirect_uri: this.redirect_uri,
        scope: this.scope,
        state: this.state,
      }})
    },
    onConfirm () {
      getAuthenticationCode(this.client_id, this.scope)
      .then(({code}) => {
        window.location = `${this.redirect_uri}?code=${code}&state=${this.state}`
      })
      .catch((error) => {
        this.showErrorMessage(`Could not acquire the authentication code: ${error.message}`)
      })
    },
    onCancel () {
      this.hidden = true
      setTimeout(() => {
        firebase.auth().signOut()
        this.backToLogin()
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.instructions {
  // font-size: .8125rem;
  h3 {
    font-size: 1.125rem;
  }
  h4 {
    font-size: 1rem;
  }
  p, ul, ol {
    margin-bottom: .8125rem;
  }
  ol {
    padding-left: 1.25em;
    list-style: decimal;
  }
  ul {
    padding-left: 1.25em;
    list-style: square;
  }
  li {
    margin-bottom: 0.40625rem;
  }
}
</style>