<template>
  <WelcomeLayout ref="container" :show-image="!imageHidden" :use-image-layout="true">
    <transition name="slide"
      @before-enter="beforeContainerFit"
      @enter="enterContainerFit"
      @after-enter="afterContainerFit"
      @cancel-enter="afterContainerFit"
    ><main v-if="step == 0" class="form">
      <h1>Welcome to Ohme</h1>

      <p class="instruction">Please set a password for your account.</p>

      <form @submit.prevent="changePassword" novalidate>
        <TextField required type="password" v-model.trim="enteredPassword" :error="enteredPasswordError" id="new-password" label="Password" placeholder="Enter your password…">
          <PasswordStrength :password="enteredPassword" @change="(strong) => passwordIsStrong = strong" />
        </TextField>
        <TextField required type="password" v-model.trim="confirmPassword" :error="confirmPasswordError" id="confirm-password" label="Confirm password" placeholder="Confirm your password…" />

        <div class="field">
        <label class="checkbox"><input type="checkbox" v-model="termsAccepted" /><span> By clicking here, I state that I have read and understood the <a href="https://ohme-ev.com/fleet-terms-and-conditions/" target="_blank">
          terms &amp; conditions
        </a>.</span></label>
        <transition name="show-error">
          <label class="error" for="terms-accepted-checkbox" v-if="termsAcceptedError">{{termsAcceptedError}}</label>
        </transition>

      </div>

        <p class="actions"><button class="action" type="submit" :disabled="submitting">Let&rsquo;s go</button></p>
      </form>
    </main></transition>
    <transition name="dive"
      @before-leave="checkDirection"
      @before-enter="(el) => {checkDirection(el); beforeContainerFit()}"
      @enter="enterContainerFit"
      @after-enter="afterContainerFit"
      @cancel-enter="afterContainerFit"
    ><main v-if="step == 1" class="form">
      <h1>Welcome to {{fleetName? fleetName : 'your fleet portal'}}</h1>
      <p class="instruction">To get started please review company information&nbsp;below.</p>

      <form @submit.prevent="changeDetails" novalidate>
        <TextField required type="text" v-model.trim="fleetName" :error="fleetNameError" id="fleet-name" label="Fleet organisation name" placeholder="Enter organisation name…" />
        <AddressField required v-model="fleetAddress" :error="fleetAddressError" id="fleet-address" label="Fleet organisation address" />
        <TextField required type="text" v-model.trim="contactName" :error="contactNameError" id="contact-name" label="Contact name" placeholder="Enter contact name…" />
        <TextField required type="email" v-model.trim="contactEmail" :error="contactEmailError" id="contact-email" label="Contact email address" placeholder="Enter email address…" />
        <TextField required type="text" v-model.trim="fleetPhone" :error="fleetPhoneError" id="fleet-phone" label="Contact phone number" placeholder="Enter phone number…" />

        <p class="actions"><button class="action" type="submit" :disabled="submitting">Save and continue</button></p>
      </form>
    </main></transition>
    <transition name="dive"
      @before-leave="checkDirection"
      @before-enter="(el) => {checkDirection(el); beforeContainerFit()}"
      @enter="enterContainerFit"
      @after-enter="afterContainerFit"
      @cancel-enter="afterContainerFit"
    ><main v-if="step == 2" class="form">
      <h1>Welcome to {{fleetName? fleetName : 'your fleet portal'}}</h1>
      <p class="instruction">To get started please review company information&nbsp;below.</p>

      <dl>
        <dt>Fleet organisation name</dt> <dd>{{fleetName}}</dd>
        <dt>Fleet organisation address</dt> <dd v-html="fleetAddressFormatted" />
        <dt>Contact name</dt> <dd>{{contactName}}</dd>
        <dt>Contact email address</dt> <dd>{{contactEmail}}</dd>
        <dt>Contact phone number</dt> <dd>{{fleetPhone}}</dd>
      </dl>
      <p class="actions">
        <button class="action secondary" :disabled="submitting" @click="editDetails">Edit details</button>
        <button class="action" :disabled="submitting" @click="confirmDetails">Looks good</button>
      </p>
    </main></transition>
  </WelcomeLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { WelcomeLayout, ContainerFit } from 'layouts/Welcome'
import TextField from 'elements/TextField'
import AddressField from 'elements/AddressField'
import PasswordStrength from 'elements/PasswordStrength'

import { setupPasswordForInvitation, termsToConsent } from '~/api-public'
import * as firebase from 'firebase/app'
import 'firebase/auth'

const STEP_SETUP_PASSWORD = 0
const STEP_EDIT_DETAILS = 1
const STEP_CONFIRM_DETAILS = 2
const STEP_HIDE = 3

export default {
  mixins: [ContainerFit],
  components: { WelcomeLayout, TextField, AddressField, PasswordStrength },
  props: {
    step: Number,
  },
  data () {
    return {
      submitting: false,
      imageHidden: false,

      additionalAdmin: false,
      userId: null,
      oobCode: null,
      fleetId: null,

      enteredPassword: '',
      confirmPassword: '',
      passwordIsStrong: false,

      enteredPasswordError: '',
      confirmPasswordError: '',

      termsAccepted: false,
      termsAcceptedError: '',

      contactName: '',
      contactEmail: '',

      contactEmailError: '',
      contactNameError: '',

      fleetName: '',
      fleetPhone: '',
      fleetAddress: {
        street1: '',
        street2: '',
        city: '',
        postcode: ''
      },

      fleetNameError: '',
      fleetPhoneError: '',
      fleetAddressError: {
        street1: '',
        street2: '',
        city: '',
        postcode: ''
      },
    }
  },
  created () {
    this.userId = this.$route.query.uid
    this.oobCode = this.$route.query.code
    this.fleetId = this.$route.query.fleetId
    this.additionalAdmin = this.$route.query.additionalAdmin != null

    if (this.oobCode == null || this.oobCode == null || this.userId == null) {
      return this.$router.replace({name: 'login'})
    }

    this.handleRedirects()
  },
  computed: {
    ...mapGetters('Fleet', ['fleetDetails', 'hasFeatures', 'hasSetup']),
    ...mapGetters('Fleet', {
      authenticatedFleetId: 'fleetId'
    }),
    ...mapGetters('Account', {
      accountName: 'name',
      accountEmail: 'email'
    }),
    fleetAddressFormatted () {
      const parts = []
      Object.values(this.fleetAddress).forEach(p => p.length > 0? parts.push(p) : undefined)
      return parts.join('<br />')
    }
  },
  watch: {
    enteredPassword(_, __) { this.enteredPasswordError = '' },
    confirmPassword(_, __) { this.confirmPasswordError = '' },
    termsAccepted(_, __) { this.termsAcceptedError = '' },
    fleetName(_, __) { this.fleetNameError = '' },
    fleetPhone(_, __) { this.fleetPhoneError = '' },
    'fleetAddress.street1': function (_, __) { this.fleetAddressError.street1 = '' },
    'fleetAddress.street2': function (_, __) { this.fleetAddressError.street2 = '' },
    'fleetAddress.city': function (_, __) { this.fleetAddressError.city = '' },
    'fleetAddress.postcode': function (_, __) { this.fleetAddressError.postcode = '' },
    contactName(_, __) { this.contactNameError = '' },
    contactEmail(_, __) { this.contactEmailError = '' },
    // FIXME: There must be a cleaner way to fire this after sign in setup is complete...
    authenticatedFleetId (to, __) {
      if (to == null) return

      this.goToDetails()
    },
    $route(_, __) {
      this.handleRedirects()
    }
  },
  methods: {
    ...mapActions('Fleet', ['updateFleetDetails']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),
    ...mapActions('Account', {
      updateAccountDetails: 'updateDetails'
    }),
    checkDirection (element) {
      if (element == null) return
      if (this.step == STEP_EDIT_DETAILS) element.classList.add('reverse')
      else element.classList.remove('reverse')
    },
    handleRedirects () {
      if (this.step != STEP_SETUP_PASSWORD && this.authenticatedFleetId == null) {
        this.goTo(STEP_SETUP_PASSWORD)
      }
      if (this.authenticatedFleetId != null && this.additionalAdmin) {
        if (this.hasFeatures.reimbursements && !this.hasSetup.directDebit) {
          this.$router.replace({name: 'direct-debit'})
        }
        else if (this.hasFeatures.reimbursementSettings && !this.hasSetup.reimbursementSettings) {
          this.$router.replace({name: 'reimbursement-settings'})
        }
        else {
          this.$router.replace({name: 'dashboard', params: {fleetId: this.authenticatedFleetId}})
        }
      }
      else if (this.authenticatedFleetId != null && this.step == STEP_SETUP_PASSWORD) {
        this.goToDetails()
      }
      else if (this.authenticatedFleetId != null) {
        this.loadDetails()
      }
    },
    loadDetails() {
      this.contactName = this.fleetDetails.contactName
      this.contactEmail = this.fleetDetails.contactEmail
      this.fleetName = this.fleetDetails.name
      this.fleetPhone = this.fleetDetails.phone
      this.fleetAddress.street1 = this.fleetDetails.street1
      this.fleetAddress.street2 = this.fleetDetails.street2
      this.fleetAddress.city = this.fleetDetails.city
      this.fleetAddress.postcode = this.fleetDetails.postcode
    },
    goTo (step) {
      this.$router.push({name: 'invitation', query: {
        step,
        uid: this.userId,
        code: this.oobCode,
        fleetId: this.fleetId,
      }})
    },
    goToDetails () {
      this.submitting = false
      this.loadDetails()

      if (this.areDetailFieldsValid()) {
        this.clearDetailErrors()
        this.goTo(STEP_CONFIRM_DETAILS)
      }
      else {
        this.clearDetailErrors()
        this.goTo(STEP_EDIT_DETAILS)
      }
    },
    // 
    // STEP 0: Password setup
    // 
    clearPasswordErrors () {
      this.enteredPasswordError = ''
      this.confirmPasswordError = ''
    },
    arePasswordFieldsValid () {
      if (this.enteredPassword.length === 0)
        this.enteredPasswordError = 'Fill in your new password please'
      else if (!this.passwordIsStrong)
        this.enteredPasswordError = 'Password does not satisfy the criteria:'

      if (this.confirmPassword.length === 0)
        this.confirmPasswordError = 'Confirm your new password please'
      else if (this.enteredPassword.length > 0 && this.enteredPassword != this.confirmPassword) 
        this.confirmPasswordError = 'Password does not match'

      return this.enteredPasswordError.length == 0 &&
        this.confirmPasswordError.length == 0
    },
    areTermsAccepted(){
      if (!this.termsAccepted) {
        this.termsAcceptedError = 'Please accept the terms and conditions'
        return false
      }
      return true
    },
    acceptTerms() {
      try {
        const terms = {
        userId: this.userId,
        consentId: '58b92737-6e11-4e64-85ff-042f99f198cf',
        consented: true,
        code: this.oobCode,
      }
      return termsToConsent(terms)
      } catch (err) {
        throw err
      }
    },
    changePassword () {
      this.clearPasswordErrors()
      if (!this.arePasswordFieldsValid() || !this.areTermsAccepted()) return

      this.submitting = true

      this.acceptTerms().then(() => {
        setupPasswordForInvitation(
        this.fleetId,
        this.userId,
        this.oobCode,
        this.enteredPassword,
        this.termsAccepted
      )
      .then((login) => {
        firebase.auth()
        .signInWithEmailAndPassword(login.email, this.enteredPassword)
        // NB! The watcher for authenticatedFleetId does the redirection.
      })
      .catch((firebaseError) => {
        this.showErrorMessage('Cannot set password for this invitation!')
        this.$router.replace({name: 'login'})
      })
      }).catch((termsError) => {
        this.showErrorMessage('There was a problem with accepting terms and conditions. Please try again later.')
      }).finally(() => this.submitting = false)
    },
    // 
    // STEP 1: Edit details
    //
    clearDetailErrors () {
      this.contactNameError = ''
      this.contactEmailError = ''
      this.fleetNameError = ''
      this.fleetPhoneError = ''
      this.fleetAddressError.street1 = ''
      this.fleetAddressError.street2 = ''
      this.fleetAddressError.city = ''
      this.fleetAddressError.postcode = ''
    },
    areDetailFieldsValid () {
      if (this.contactName.length == 0) this.contactNameError = 'Please fill in the contact name'
      if (this.contactEmail.length == 0) this.contactEmailError = 'Please fill in the contact email address'
      if (this.fleetName.length == 0) this.fleetNameError = 'Please fill in the fleet organisation name'
      if (this.fleetPhone.length == 0) this.fleetPhoneError = 'Please fill in the contact phone number'

      if (this.fleetAddress.street1.length == 0) this.fleetAddressError.street1 = 'Please fill in the street address'
      if (this.fleetAddress.city.length == 0) this.fleetAddressError.city = 'Please fill in the city'
      if (this.fleetAddress.postcode.length == 0) this.fleetAddressError.postcode = 'Please fill in the postcode'

      return this.contactNameError.length == 0
        && this.contactEmailError.length == 0
        && this.fleetNameError.length == 0
        && this.fleetPhoneError.length == 0
        && this.fleetAddressError.street1.length == 0
        && this.fleetAddressError.street2.length == 0
        && this.fleetAddressError.city.length == 0
        && this.fleetAddressError.postcode.length == 0
    },
    changeDetails () {
      this.clearDetailErrors()

      if (!this.areDetailFieldsValid()) return

      this.goTo(STEP_CONFIRM_DETAILS)
    },
    // 
    // STEP 2: Confirm details
    // 
    editDetails () {
      this.goTo(STEP_EDIT_DETAILS)
    },
    confirmDetails () {
      this.submitting = true
      
      this.updateFleetDetails({
        name: this.fleetName,
        contactName: this.contactName,
        contactEmail: this.contactEmail,
        phone: this.fleetPhone,
        street1: this.fleetAddress.street1,
        street2: this.fleetAddress.street2,
        city: this.fleetAddress.city,
        postcode: this.fleetAddress.postcode,
      })
      .then(() => {
        this.imageHidden = true

        // NB! Be sure to replace this with a valid router state!
        this.goTo(STEP_HIDE)

        // Let the animations finish
        setTimeout(() => {
          if (this.hasFeatures.reimbursements) {
            this.$router.replace({name: 'direct-debit'})
          }
          else {
            this.showSuccessMessage(`Thank you for completing your profile!`)
            this.$router.replace({name: 'dashboard', params: {fleetId: this.authenticatedFleetId}})
          }
        }, 600)
      })
      .catch((error) => {
        this.showErrorMessage(`Could not complete profile: ${error.message}`)
      })
      .finally(() => this.submitting = false)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';
pre {
  white-space: pre-wrap;
}
dl {
  dt {
    margin-top: 32px;
    color: $label-text;
    font-size: .875rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  dd {
    margin-top: 2px;
    margin-left: 0;
    color: $bold-text;
    font-size: 1.3125rem;
    line-height: 1.15;
    font-weight: 300;
  }
}
</style>