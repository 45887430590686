<template>
  <transition-group name="notification" id="notifications" tag="ul">
    <li v-for="n in notifications" :key="n.id"><span :class="n.status">
      <em>{{noWindow(n.message)}}</em>
      <button @click="hideNotification(n.id)">Close</button>
    </span></li>
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('Notifications', ['notifications'])
  },
  methods: {
    ...mapActions('Notifications', ['hideNotification']),
    noWindow (message) {
      const windowIndex = message.lastIndexOf(' ')

      if (windowIndex !== -1) {
        message = message.substring(0, windowIndex) + ' ' + message.substring(windowIndex + 1)
      }

      return message
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

ul {
  pointer-events: none;
}
li {
  max-width: 30em;
  height: 60px;
  margin: 0 auto 10px;
  pointer-events: auto;
}
li > span {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  height: 60px;
  position: relative;
  padding: 0 45px 0 20px;
  border-radius: 30px;
  background: $alt-background;
  box-shadow: 0 0 3px $dropshadow-color;
  opacity: 0.95;
  line-height: 1.15;
  font-weight: 500;
  text-align: center;

  em {
    font-style: normal;
  }

  &.success {
    color: $light-text;
    background-color: $success-color;
  }
  &.error {
    color: $light-text;
    background-color: $error-color;
  }
  &.warning {
    color: $light-text;
    background-color: $warning-color;
  }
  &.info {
    color: $light-text;
    background-color: $info-color;
  }
}

button {
  @include button(20, 1);
  
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 15px;
  opacity: 0.75;

  &:hover, &:focus {
    opacity: 1.0;
  }
}

.notification-enter-active, .notification-leave-active {
  transition: all 0.125s;
  
  span {
    transition: all 0.25s;
  }
}
.notification-enter {
  height: 0;
  span {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.notification-leave-to {
  height: 0;

  span {
    opacity: 0;
    transform: translateY(10px);
  }
}
</style>