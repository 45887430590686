import axios from "axios";
import { APIv1 as config } from "~/config";

export function setupPasswordForInvitation(fleetId, userId, invitationId, password, termsAccepted) {
  return new Promise(function(resolve, reject) {
    return axios
      .request({
        baseURL: config.baseURL,
        url: `public/fleets/${fleetId}/users/${userId}/password?code=${invitationId}&termsAccepted=${termsAccepted}`,
        method: `post`,
        headers: { "Content-Type": "text/plain" },
        data: password,
      })
      .then(({ data }) => {
        resolve({
          userId: data.uid,
          email: data.email,
          name: data.displayName,
        });
      })
      .catch(reject);
  });
}

export function termsToConsent(terms) {
  return new Promise(function(resolve, reject) {
    return axios
      .request({
        baseURL: config.baseURL,
        url: "consent/public/users/status",
        method: `patch`,
        headers: { "Content-Type": "application/json" },
        data: terms,
      })
      .then(({ data }) => {
        resolve();
      })
      .catch(reject);
  });
}
