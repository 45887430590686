import Vue from 'vue'

import {
  addSeconds,
} from 'date-fns'

const HIDE_AFTER_SECONDS = 4;

export const state = {
  notifications: [
    // { id: 0, hideAfter: Date, visible: true | false, status: "success" | "error" | "warning" | "info", message: ""}
  ]
}

export const getters = {
  notifications: state => state.notifications.filter(n => n.visible === true).sort((a, b) => b.id - a.id)
}

export const mutations = {
  showNotification (state, notification) {
    const last = state.notifications[state.notifications.length - 1]
    const nextId = state.notifications.length

    if (last != null && last.status === 'info' && notification.status === 'info') {
      Vue.set(state.notifications, last.id, {
        id: last.id,
        hideAfter: addSeconds(new Date, HIDE_AFTER_SECONDS),
        visible: true,
        status: notification.status,
        message: notification.message
      })
    }
    else {
      Vue.set(state.notifications, nextId, {
        id: nextId,
        hideAfter: addSeconds(new Date, HIDE_AFTER_SECONDS),
        visible: true,
        status: notification.status,
        message: notification.message
      })
    }
  },
  hideNotification (state, notificationId) {
    state.notifications[notificationId].visible = false;
  }
}

export const actions = {
  tick({ dispatch, commit, getters }) {
    const now = new Date
    getters.notifications.forEach((n) => {
      if (n.hideAfter.getTime() < now.getTime()) commit('hideNotification', n.id)
    })
    setTimeout(() => dispatch('tick'), 500)
  },
  showSuccessMessage({ commit }, message) {
    commit('showNotification', {
      status: "success",
      message: message
    })
  },
  showErrorMessage({ commit }, message) {
    commit('showNotification', {
      status: "error",
      message: message
    })
  },
  showWarningMessage({ commit }, message) {
    commit('showNotification', {
      status: "warning",
      message: message
    })
  },
  showInfoMessage({ commit }, message) {
    commit('showNotification', {
      status: "info",
      message: message
    })
  },
  hideNotification({ commit }, notificationId) {
    commit('hideNotification', notificationId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}