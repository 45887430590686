import Vue from 'vue'

import {
  startOfMonth,
  getTime
} from 'date-fns'

import {
  exportTransactionInvoice,
} from '~/api'

export const state = {
  transactions: [
    // {
    //   createdOn: 0,
    //   completedOn: 0,
    //   description: "",
    //   invoiceId: "",
    //   amount: { amount: 0.0, currency: "" },
    //   status: "complete" | "processing" | "failed" | "unknown",
    // }
  ],
  reimbursements: [
    // {
    //   startedOn: 0,
    //   endedOn: 0,
    //   amount: { amount: 0.0, currency: "" },
    //   ratePerKWh: { amount: 0.0, currency: "" },
    //   quantity: 0.0,
    //   user: {
    //     id: '',
    //     firstName: '',
    //     lastName: '',
    //   },
    //   statusUpdatedOn: 0,
    //   status: "complete" | "processing" | "pending" | "failed" | "unknown",
    // }
  ]
}

export const getters = {
  transactions: (state, _, __, rootState) => {
    return state.transactions.filter((t) => 
      (rootState.periodFrom <= t.createdOn && rootState.periodUntil >= t.createdOn) ||
      (rootState.periodFrom <= t.completedOn && rootState.periodUntil >= t.completedOn)
    )
  },
  transactionsTotal: (_, getters) => {
    let total = {
      amount: 0,
      currency: null,
    }

    for (let i = 0; i < getters.transactions.length; i++) {
      const t = getters.transactions[i]

      // Halt, if transactions have different currencies!
      if (total.currency != null && t.amount.currency != total.currency) {
        return null
      }
      
      total.currency = t.amount.currency
      total.amount += t.amount.amount
    }
    
    if (total.currency != null)
      return total
    else
      return null
  },
  reimbursementsByMonth: (state, _, __, rootState) => {
    const months = {
      // "ts" : [
      //   {status: "", timestamp: 0, name: "", amount: {amount: 0.0, currency: ""}, ...}
      // ]
    }
    state.reimbursements
    .filter((r) => !(
      rootState.periodFrom > r.endedOn ||
      rootState.periodUntil < r.startedOn
    ))
    .sort((a, b) => b.startedOn - a.startedOn)
    .forEach((r) => {
      const date = getTime(startOfMonth(r.startedOn))

      if (months[date] == null) {
        months[date] = []
      }

      months[date].push({
        name: r.user.firstName + ' ' + r.user.lastName,
        status: r.status,
        timestamp: r.statusUpdatedOn,
        quantity: r.quantity,
        ratePerKWh: {
          amount: r.ratePerKWh.amount,
          currency: r.ratePerKWh.currency
        },
        amount: {
          amount: r.amount.amount,
          currency: r.amount.currency
        }
      })
    })

    return months
  },
  reimbursementsTotal: (_, getters) => {
    let total = {
      amount: 0,
      currency: null,
    }

    const months = Object.values(getters.reimbursementsByMonth)

    for (let i = 0; i < months.length; i++) {
      const people = Object.values(months[i])
      
      for (let j = 0; j < people.length; j++) {
        const p = people[j]

        // Halt, if reimbursements have different currencies!
        if (total.currency != null && p.amount.currency != total.currency) {
          return null
        }
        
        total.currency = p.amount.currency
        total.amount += p.amount.amount
      }
    }
    
    if (total.currency != null)
      return total
    else
      return null
  },
}

export const mutations = {
  reset (state) {
    state.transactions = []
    state.reimbursements = []
  },
  addTransaction (state, transaction) {
    const nextId = state.transactions.length
    Vue.set(state.transactions, nextId, transaction)
  },
  addReimbursements (state, reimbursement) {
    const nextId = state.reimbursements.length
    Vue.set(state.reimbursements, nextId, reimbursement)
  }
}

export const actions = {
  exportTransactionInvoice ({ rootGetters }, invoiceId) {
    return exportTransactionInvoice(rootGetters['Fleet/fleetId'], invoiceId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}