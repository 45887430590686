<template>
  <Dialog><main class="form">
    <form @submit.prevent="save" novalidate>
      <section class="fieldset">
        <h2 class="legend">Manager details</h2>

        <TextField required type="text" id="full-name" v-model.trim="name" :error="nameError" label="Full name" placeholder="Enter manager’s full name" />
        <TextField required type="email" id="email-address" v-model="email" :error="emailError" label="Email address" placeholder="Enter email address" />

      </section>

      <div class="choose-action">
        <p class="actions sheet">
          <button class="action" :disabled="saving" type="submit">{{adminId != null ? 'Save changes' : 'Add manager'}}</button>
          <router-link class="action secondary" :disabled="saving" tag="button" :to="{name: returnTarget}">Cancel</router-link>
        </p>
      </div>
    </form>
  </main></Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Dialog from 'elements/Dialog'
import TextField from 'elements/TextField'

export default {
  components: { Dialog, TextField },
  data () {
    return {
      saving: false,
      
      name: '',
      email: '',

      nameError: '',
      emailError: '',
    }
  },
  created () {
    if (this.adminId == null) return

    const admin = this.getAdminById(this.adminId)

    this.name = admin.name
    this.email = admin.email
  },
  watch: {
    name(_, __) { this.nameError = '' },
    email(_, __) { this.emailError = '' },
  },
  computed: {
    ...mapGetters('Admins', ['getAdminById']),

    adminId () {
      return this.$route.params.adminId
    },
    returnTarget () {
      return this.$route.query.return || 'admin-management'
    },
  },
  methods: {
    ...mapActions('Admins', ['inviteAdmin']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),
    clearErrors () {
      this.nameError = ''
      this.emailError = ''
    },
    areFieldsValid () {
      if (this.name.length == 0) {
        this.nameError = 'Please enter manager’s full name'
      }

      if (this.email.length == 0) {
        this.emailError = 'Please enter manager’s email address'
      }

      return this.nameError.length == 0
        && this.emailError.length == 0
    },
    save () {
      this.clearErrors()

      if (!this.areFieldsValid()) {
        this.showErrorMessage(`Validation failed. Please correct all errors!`)
        return
      }

      this.saving = true

      const data = {
        name: this.name,
        email: this.email,
      }

      if (this.adminId != null) {
        data.id = this.adminId
      }

      const action = this.inviteAdmin
      const successVerb = 'invited'
      const failureVerb = 'invite'

      action(data).then(() => {
        this.showSuccessMessage(`The manager has been ${successVerb} successfully`)
        this.$router.push({name: this.returnTarget})
      })
      .catch((error) => {
        if (error.field === 'email') {
          this.emailError = error.message
        }
          
        this.showErrorMessage(`Could not ${failureVerb} the manager: ${error.message}`)
        this.saving = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

</style>