<template>
  <main>
    <header>
      <h1>{{groupType | capitalize}} management</h1>

      <div class="actions">
        <router-link class="action" tag="button" :to="{name: groupType + '-groups-add-group', params: {groupType}}">Add {{objectSingular}}</router-link>
      </div>

      <ManagementTabs :type="groupType" />
    </header>

    <table class="big full-width controlled" ref="table">
      <thead>
        <tr>
          <td class="name textual" :colspan="levels">Group name</td>
          <td v-if="isResidential" class="textual">{{packageHeading}}</td>
          <td>{{countHeading}}</td>
        </tr>
      </thead>
      <tbody ref="table-body">
        <tr class="filters">
          <td class="name textual" :colspan="levels"><input type="search" :value="nameFilter" @input="changeNameFilter" placeholder="Search…" /></td>
          <td v-if="isResidential">&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr v-if="visibleGroups.length == 0">
          <td :colspan="isResidential ? levels + 2 : levels + 1" class="empty">{{isFiltered? 'No records matching the criteria were found' : 'No data to display'}}</td>
        </tr>
        <tr v-else v-for="(g, i) in visibleGroups" :key="g.id">
          <template v-if="!isFiltered">
            <td v-for="l in g.level" :key="l" :class="{
              spacer: true,
              vertical: g.parents[l],
              connected: l === g.level,
              last: l === g.level && g.last
            }"><span>&nbsp;</span></td>
          </template>
          <th :colspan="levels - (isFiltered? 0 : g.level)" :class="{
            name: true,
            first: !isFiltered && visibleGroups[i + 1] != null && visibleGroups[i + 1].level > g.level
          }"><span><router-link :to="{name: groupType + '-groups-group-details', params: {groupId: g.id}}">{{g.name}}</router-link></span></th>
          <template v-if="isRowHidden(i)">
            <td :colspan="isResidential? '2' : '1'">&nbsp;</td>
          </template>
          <template>
            <td v-if="isResidential" class="textual">
              <ProductTags :ids="productIds(g.id)" />
            </td>
            <td>{{countAmount(g.id)}}</td>
          </template>
        </tr>
      </tbody>
    </table>

    <transition name="modal"
      @before-leave="beforeModalLeaves"
      @before-enter="beforeModalEnters"
      @enter="whenModalEnters"
      @leave="whenModalLeaves"
      @after-leave="afterModalLeaves"
      @after-enter="afterModalEnters"
    >
      <router-view />
    </transition>
  </main>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters } from 'vuex'

import ManagementTabs from 'elements/ManagementTabs'
import ProductTags from 'elements/ProductTags'

import { HiddenRows, ModalOwner } from '~/mixins'

export default {
  mixins: [HiddenRows, ModalOwner],
  components: { ManagementTabs, ProductTags },
  props: {
    groupType: {
      type: String,
      validator: function (value) {
        return [
          'driver',
          'charger'
        ].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      nameFilter: null,
    }
  },
  computed: {
    ...mapGetters(['units']),
    ...mapGetters('Fleet', ['fleetName', 'isResidential']),
    ...mapGetters('Groups', ['userGroups', 'deviceGroups']),
    ...mapGetters('Users', ['userCountByGroupId']),
    ...mapGetters('Chargers', ['chargerCountByGroupId']),
    ...mapGetters('Products', ['productsByGroupId']),
    packageHeading () {
      return this.groupType === 'driver' ? 'Available packages' : 'Allowed packages'
    },
    countHeading () {
      return this.groupType === 'driver' ? 'Drivers in group' : 'Chargers in group'
    },
    objectSingular () {
      return `${this.groupType === 'driver' ? this.units.userSingular : this.units.deviceSingular} group`
    },
    objectPlural () {
      return `${this.groupType === 'driver' ? this.units.userSingular : this.units.deviceSingular} groups`
    },
    groups () {
      return this.groupType === 'driver' ? this.userGroups : this.deviceGroups
    },
    levels () {
      return this.visibleGroups.length == 0? 
        1 : 
        this.visibleGroups.reduce((maxLevel, group) => Math.max(group.level, maxLevel), 0) + 1
    },
    visibleGroups () {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.trim().toLowerCase()) !== -1
      }

      return this.groups.filter(group => {
        let pass = true

        if (this.nameFilter != null) pass &= matches(this.nameFilter, group.name)

        return pass
      })
    },
    isFiltered () {
      return this.nameFilter != null && this.nameFilter.length > 0
    },
  },
  methods: {
    changeNameFilter: debounce(function (e) { this.nameFilter = e.target.value }, 300),

    productIds (groupId) {
      return this.productsByGroupId(groupId).map(p => p.id)
    },
    countAmount (groupId) {
      return this.groupType === 'driver' ? this.userCountByGroupId(groupId) : this.chargerCountByGroupId(groupId)
    },

  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

$graph-color: $border-color;
$graph-width: 2px;
$tab-width: 30px;
$cell-height: 64px;
$middle-point: $cell-height / 2 - ($graph-width / 2);
$left-line-offset: 4px - ($graph-width / 2);
$right-line-offset: 7px - ($graph-width / 2);
$bottom-line-offset: 20px - ($graph-width / 2);

.name {
  padding-left: 0;
}
.checkbox.indented {
  border-top-color: transparent;
}
.spacer {
  border-top-color: transparent;
  width: $tab-width;
  padding: 0;

  span {
    display: block;
    width: $tab-width;
    height: $cell-height;
    position: relative;

  }
  &.vertical span::before {
    position: absolute;
    top: -2px;
    left: $left-line-offset;
    bottom: -1px;
    border-left: $graph-width solid $graph-color;
    content: "";
  }
  &.last span::before {
    bottom: $middle-point;
  }
  &.last span::after, &.connected span::after {
    position: absolute;
    top: $middle-point;
    left: $left-line-offset;
    right: $right-line-offset;
    border-top: $graph-width solid $graph-color;
    content: "";
  }
  &.connected span::after {
    right: $right-line-offset;
  }
}
th.first {
  span {
    position: relative;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    margin-bottom: -20px;
  }
  span::before {
    position: absolute;
    top: $cell-height - $bottom-line-offset;
    left: $left-line-offset;
    bottom: -1px;
    border-left: $graph-width solid $graph-color;
    content: "";
  }
}
</style>