<template>
  <div class="field address">
    <label :for="id">{{label}} <Parenthetical v-if="!required" class="hint">Optional</Parenthetical></label>
    <label class="hidden" :for="id">street address line 1</label>
    <input
      :id="id"
      type="text"
      :required="required"
      :disabled="disabled"
      :class="{first: true, street: true, erroneous: error.street1.length > 0}"
      placeholder="Street address line 1"
      :aria-labelledby="id + '-street1'"
      :value="value.street1"
      @input="input({street1: $event.target.value})" />
    <label class="hidden" :for="id + '-street2'">street address line 2</label>
    <input
      :id="id + '-street2'"
      type="text"
      :required="false"
      :disabled="disabled"
      :class="{street: true, erroneous: error.street2.length > 0}"
      placeholder="Street address line 2"
      :value="value.street2"
      @input="input({street2: $event.target.value})" />
    <label class="hidden" :for="id + '-city'">city</label>
    <input
      :id="id + '-city'"
      type="text"
      :required="required"
      :disabled="disabled"
      :class="{city: true, erroneous: error.city.length > 0}"
      placeholder="City"
      :value="value.city"
      @input="input({city: $event.target.value})" />
    <label class="hidden" :for="id + '-postcode'">postcode</label>
    <input
      :id="id + '-postcode'"
      type="text"
      :required="required"
      :disabled="disabled"
      :class="{postcode: true, erroneous: error.postcode.length > 0}"
      placeholder="Postcode"
      :value="value.postcode"
      @input="input({postcode: $event.target.value})" />
    <label class="hidden" :for="id + '-country'">country</label>
    <div :class="{select: true, country: true, us: value.country === 'US'}">
      <select
        :id="id + '-country'"
        :required="required"
        :disabled="disabled"
        :class="{erroneous: error.country.length > 0}"
        :value="value.country"
        @change="input({country: $event.target.value})"
      >
        <option></option>
        <option v-for="(c) in mainCountries" :value="c.code" :key="c.code">{{c.name}}</option>
        <option disabled>–––</option>
        <option v-for="(c) in otherCountries" :value="c.code" :key="c.code">{{c.name}}</option>
      </select>
      <span v-if="!value.country" class="placeholder">Country</span>
    </div>
    <div v-if="value.country === 'US'" class="select state">
      <select
        :id="id + '-state'"
        :required="required"
        :disabled="disabled"
        :class="{erroneous: error.state.length > 0}"
        :value="value.state"
        @change="input({state: $event.target.value})"
      >
        <option></option>
        <option v-for="(s) in states" :value="s.code" :key="s.code">{{s.name}}</option>
      </select>
      <span v-if="!value.state" class="placeholder">State</span>
      <span v-else class="value">{{value.state}}</span>
    </div>
    <transition name="show-error">
      <ul class="errors">
        <li v-if="error.street1.length > 0"><label class="error" :for="id">{{error.street1}}</label></li>
        <li v-if="error.street2.length > 0"><label class="error" :for="id + '-street2'">{{error.street2}}</label></li>
        <li v-if="error.city.length > 0"><label class="error" :for="id + '-city'">{{error.city}}</label></li>
        <li v-if="error.postcode.length > 0"><label class="error" :for="id + '-postcode'">{{error.postcode}}</label></li>
        <li v-if="error.country.length > 0"><label class="error" :for="id + '-country'">{{error.country}}</label></li>
        <li v-if="error.state.length > 0"><label class="error" :for="id + '-state'">{{error.state}}</label></li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { countries, states } from '~/locations.js'
import Parenthetical from 'elements/Parenthetical'

function addressFields (value) {
  return value.hasOwnProperty('street1')
    && value.hasOwnProperty('street2')
    && value.hasOwnProperty('city')
    && value.hasOwnProperty('state')
    && value.hasOwnProperty('postcode')
    && value.hasOwnProperty('country')
}

export default {
  components: { Parenthetical },
  props: {
    value: {
      type: Object,
      validator: addressFields
    },
    label: String,
    error: {
      type: Object,
      validator: addressFields
    },
    id: String,
    required: Boolean,
    disabled: Boolean
  },
  computed: {
    mainCountryCodes () {
      return ['US', 'GB', 'BE', 'FR', 'DE']
    },
    mainCountries () {
      return this.countries.filter(c => this.mainCountryCodes.includes(c.code))
    },
    otherCountries () {
      return this.countries.filter(c => !this.mainCountryCodes.includes(c.code))
    },
    countries () {
      return countries.sort((a, b) => (a.name === b.name? 0 : a.name < b.name ? -1 : 1))
    },
    states () {
      return states.sort((a, b) => (a.name === b.name? 0 : a.name < b.name ? -1 : 1))
    }
  },
  methods: {
    input (patch) {
      // TODO: Trim each address line
      this.$emit('input', {...this.value, ...patch})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.hidden {
  position: absolute;
  left: -999em;
}
.address {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
input {
  margin-top: 8px;

  &.first {
    margin-top: 0;
  }

  &.city {
    flex: 0 1 calc(60% - 2.66px);
    margin-right: 8px;
  }
  &.postcode {
    flex: 0 1 calc(40% - 5.33px);
    margin-right: 0;
  }
}

.select {
  width: 100%;
  margin-top: 8px;
  position: relative;

  &.us {
    flex: 0 1 calc(70% - 2.66px);
    margin-right: 8px;
  }
  &.state {
    flex: 0 1 calc(30% - 5.33px);
    margin-right: 0;
  }

  .placeholder, .value {
    position: absolute;
    top: 2px;
    left: 2px;
    display: inline-block;
    padding: 11px 15px;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: $placeholder-color;
    pointer-events: none;
  }
  .value {
    color: $default-text;
    background: $default-background;
    right: 25px;
  }
}
</style>