<template>
  <Dialog><main class="form">
    <form @submit.prevent="save" novalidate>
      <section class="fieldset group">
        <h2 class="legend">{{objectSingular | capitalize}} details</h2>

        <TextField
          required
          type="text"
          id="group-name"
          label="Name"
          placeholder="Enter group name"
          v-model.trim="name"
          :error="nameError"
        />

        <div class="field groups">
          <label for="user-group">Parent group</label>
          <GroupSelect
            id="user-group"
            :type="groupType === 'driver'? 'user' : 'device'"
            v-model="parentId"
            :exclude="groupId"
            placeholder="Select parent group"
          />
        </div>
      </section>

      <section v-if="false && groupType === 'driver'" class="fieldset drivers">
        <h2 class="legend">{{driversInGroup}} driver{{driversInGroup === 1 ? '' : 's'}} in this group</h2>

        <table class="full-width controlled">
          <thead>
            <tr>
              <td class="checkbox">&nbsp;</td>
              <td class="textual">Driver's name</td>
              <td class="textual">Cable ID</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in users" :key="d.id">
              <td class="checkbox"><label class="checkbox"><input type="checkbox" :id="`driver-${d.id}`" v-model="userIds" :value="d.id" /><span> Select {{d.name}}</span></label></td>
              <th><label :for="`driver-${d.id}`"><Abbreviated middle :value="d.name" :max-length="33"/></label></th>
              <td class="textual"><Abbreviated middle :value="d.cable.id" :max-length="22" class="last" /></td>
            </tr>
          </tbody>
        </table>
      </section>

      <section v-if="false && groupType === 'charger'" class="fieldset chargers">
        <h2 class="legend">{{chargersInGroup}} charger{{chargersInGroup === 1 ? '' : 's'}} in this group</h2>

        <table class="full-width controlled">
          <thead>
            <tr>
              <td class="checkbox">&nbsp;</td>
              <td class="textual">Charger name</td>
              <td class="textual">Serial ID</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in chargers" :key="c.id">
              <td class="checkbox"><label class="checkbox"><input type="checkbox" :id="`charger-${c.id}`" v-model="deviceIds" :value="c.id" /><span> Select {{c.name}}</span></label></td>
              <th><label :for="`charger-${c.id}`"><Abbreviated middle :value="c.name" :max-length="33"/></label></th>
              <td class="textual"><Abbreviated middle :value="c.serialId" :max-length="22" class="last" /></td>
            </tr>
          </tbody>
        </table>
      </section>

      <div class="choose-action">
        <p class="actions sheet">
          <button class="action" :disabled="saving" type="submit">{{buttonLabel}}</button>
          <button class="action secondary" :disabled="saving" type="button" @click="closeDialog">Cancel</button>
        </p>
      </div>
    </form>
  </main></Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { Dialog, DialogChainNode } from 'elements/Dialog'
import GroupSelect from 'elements/GroupSelect'
import TextField from 'elements/TextField'
import Abbreviated from 'elements/Abbreviated'

export default {
  mixins: [DialogChainNode],
  components: { Dialog, GroupSelect, TextField, Abbreviated },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      saving: false,

      name: '',
      parentId: '',

      userIds: [],
      deviceIds: [],

      nameError: '',
    }
  },
  created () {
    if (this.groupId == null) return

    const group = this.getGroupById(this.groupId)

    this.name = group.name
    this.parentId = group.parentId

    if (this.groupType === 'driver') {
      this.userIds = this.usersByGroupId(this.groupId).map(u => u.id)
    }
    else {
      this.deviceIds = this.chargersByGroupId(this.groupId).map(c => c.id)
    }
  },
  watch: {
    name (_, __) { this.nameError = '' },
  },
  computed: {
    ...mapGetters(['units']),
    ...mapGetters('Groups', ['getGroupById', 'rootUserGroup', 'rootDeviceGroup']),
    ...mapGetters('Chargers', ['chargers', 'chargersByGroupId']),
    ...mapGetters('Users', ['users', 'usersByGroupId']),
    chargersInGroup () {
      return this.deviceIds.length
    },
    driversInGroup () {
      return this.userIds.length
    },
    title () {
      if (this.groupId != null) {
        return `Edit ${this.objectSingular}`
      } else {
        return `Add a new ${this.objectSingular}`
      }
    },
    buttonLabel () {
      if (this.groupId != null) {
        return `Save ${this.objectSingular}`
      } else {
        return `Add ${this.objectSingular}`
      }
    },
    groupId () {
      return this.params != null? this.params.groupId : this.$route.params.groupId
    },
    groupType () {
      if (this.groupId != null) {
        return this.getGroupById(this.groupId).type === 'user' ? 'driver' : 'charger'
      }
      else if (this.params != null) {
        return this.params.groupType
      }
      else {
        return this.$route.params.groupType
      }
    },
    objectSingular () {
      return `${this.groupType === 'driver' ? this.units.userSingular : this.units.deviceSingular} group`
    },
    returnTarget () {
      return this.$route.query.return || (this.groupType === 'charger' ? 'charger-groups' : 'driver-groups')
    },
  },
  methods: {
    ...mapActions('Groups', ['createGroup', 'updateGroup']),
    ...mapActions('Notifications', ['showErrorMessage', 'showSuccessMessage']),

    clearErrors () {
      this.nameError = ''
    },

    areFieldsValid () {
      if (this.name.length == 0)
        this.nameError = 'Please enter group name'

      return this.nameError.length == 0
    },

    save () {
      this.clearErrors()

      if (!this.areFieldsValid()) {
        this.showErrorMessage(`Validation failed. Please correct all errors!`)
        return
      }

      this.saving = true

      const data = {
        name: this.name,
        type: this.groupType === 'driver'? 'user' : 'device',
        parentId: this.parentId
      }

      if (data.parentId == '') {
        data.parentId = this.groupType === 'driver'? this.rootUserGroup.id : this.rootDeviceGroup.id
      }

      if (this.groupId != null) {
        data.id = this.groupId
      }

      const action = this.groupId == null ? this.createGroup : this.updateGroup
      const successVerb = this.groupId == null ? 'added' : 'updated'
      const failureVerb = this.groupId == null ? 'add' : 'update'

      action(data).then((group) => {
        this.showSuccessMessage(`The ${this.objectSingular} has been ${successVerb} successfully`)
        this.completeDialog(group)
      })
      .catch((error) => {
        this.showErrorMessage(`Could not ${failureVerb} the ${this.objectSingular}: ${error.message}`)
        this.saving = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset.group {
  .legend { @include icon-location (19, 2) }
}
main.form .fieldset.drivers {
  .legend { @include icon-location (16, 2) }
}
main.form .fieldset.chargers {
  .legend { @include icon-location (13, 2) }
}

table.controlled {
  margin-top: 8px;
  margin-bottom: 0;
}
th label {
  color: $default-text;
  cursor: pointer;
}
thead {
  th, td {
    // border-bottom: 0;
    padding-bottom: 8px;
  }
}
tbody {
  th, td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
</style>