// 
// Abstract downloading operation
// 
export const Downloader = {
  methods: {
    download (button, fileName, urlGetter, status = 'Exporting…') {
      const anchor = document.createElement('a')
      anchor.className = 'downloader'
      document.body.appendChild(anchor)
      
      const label = button.textContent
      button.disabled = true
      if (status) button.textContent = status

      return urlGetter().then(url => {
        anchor.href = url
        anchor.download = fileName
        anchor.click()

        button.disabled = false
        if (status) button.textContent = label
      })
    }
  }
}


// 
// Abstracts functionality common to Dialogs and Sidebars
// - Locks the main content scrolling
// - Isolates keyboard focus
//

const modalHierarchy = []

export const Modal = {
  data () {
    return {
      modal: null,
      firstFocusableElement: null,
      lastFocusableElement: null,
      focusableElements: []
    }
  },
  mounted () {
    if (modalHierarchy.length == 0) document.body.classList.add('locked')

    // Get the modal portion of the component
    this.modal = this.$el
    modalHierarchy.push(this.modal)

    if (!this.modal) return

    this.focusableElements = this.modal.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])')

    this.firstFocusableElement = this.focusableElements[0]
    this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1]

    const initiallyFocused = this.modal.querySelector('*.init-focus') || this.lastFocusableElement
    initiallyFocused.focus()
    this.modal.addEventListener('keydown', this.trapKeyboardFocus)
  },
  destroyed () {
    let mhi
    if ((mhi = modalHierarchy.indexOf(this.modal)) !== -1) {
      modalHierarchy.splice(mhi, 1)
    }
    if (modalHierarchy.length == 0) document.body.classList.remove('locked')

    if (this.modal) this.modal.removeEventListener('keydown', this.trapKeyboardFocus)
  },
  methods: {
    trapKeyboardFocus (e) {
      if (e.key !== 'Tab' && e.keyCode !== 9) return

      if (e.shiftKey && document.activeElement === this.firstFocusableElement) {
        this.lastFocusableElement.focus()
        e.preventDefault()
      }
      else if (!e.shiftKey && document.activeElement === this.lastFocusableElement) {
        this.firstFocusableElement.focus()
        e.preventDefault()
      }
    }
  }
}

// Helps adding correct classes to identify transitions
function calculateInnerHeight (el) {
  let innerHeight = 0
  Array.prototype.forEach.call(el.children, function (el, i){
    innerHeight += el.offsetHeight
  })
  return innerHeight
}

export const ModalOwner = {
  beforeRouteUpdate (to , from, next) {
    // console.log('Changing route to', {to: to, from: from})

    if (to.name === from.name) {
      this.transitionFrom = 'same'
    }
    else if (to.name.indexOf(from.name) !== -1) {
      this.transitionFrom = 'main'
    }
    else if (from.name.indexOf('-add-') !== -1 || from.name.indexOf('-edit-') !== -1) {
      this.transitionFrom = 'dialog'
    }
    else {
      this.transitionFrom = 'sidebar'
    }

    next()
  },
  data () {
    return {
      transitionFrom: null
    }
  },
  methods: {
    beforeModalEnters (el) {
      if (this.transitionFrom === 'sidebar') {
        el.classList.add('from-sidebar')
      }
      else if (this.transitionFrom === 'dialog') {
        el.classList.add('from-dialog')
      }
      else if (this.transitionFrom === 'same') {
        if (el.classList.contains('sidebar')) {
          el.classList.add('from-sidebar')
        } else {
          el.classList.add('from-dialog')
        }
      }
      else {
        el.classList.add('from-main')
      }
      // console.log('Entering modal', el, 'from', this.transitionFrom)

      // Sidebar >> Dialog transition
      if (el.classList.contains('from-sidebar') && el.classList.contains('dialog')) {
        const dialog = el.querySelector('main > form')
        dialog.style.height = window.innerHeight + 'px'
      }
    },
    whenModalEnters (el) {
      // Sidebar >> Dialog transition
      if (el.classList.contains('from-sidebar') && el.classList.contains('dialog')) {
        const dialog = el.querySelector('main > form')
        dialog.style.height = calculateInnerHeight(dialog) + 'px'
      }
    },
    beforeModalLeaves (el) {
      const s = el.nextElementSibling
      if (s != null && s.classList.contains('sidebar')) {
        el.classList.add('to-sidebar')
      }
      else if (s != null && s.classList.contains('dialog')) {
        el.classList.add('to-dialog')
      }
      else {
        el.classList.add('to-main')
      }
      // console.log('Leaving modal', el, 'before sibling', s)

      // Dialog >> Sidebar transition
      if (el.classList.contains('to-sidebar') && el.classList.contains('dialog')) {
        const dialog = el.querySelector('main > form')
        const dialogHeight = calculateInnerHeight(dialog)
        if (window.innerHeight > dialogHeight) {
          dialog.style.height = dialogHeight + 'px'
          this.$nextTick(function () {
            dialog.style.height = window.innerHeight + 'px'
          })
        }
      }
    },
    whenModalLeaves (el) {
      
    },
    afterModalEnters (el) {
      el.classList.remove('from-sidebar')
      el.classList.remove('from-dialog')
      el.classList.remove('from-main')

      const dialog = el.querySelector('main > form')

      // Sidebar >> Dialog transition
      if (dialog) {
        dialog.style.height = 'auto'
      }
    },
    afterModalLeaves (el) {
      el.classList.remove('to-sidebar')
      el.classList.remove('to-dialog')
      el.classList.remove('to-main')
    },

  }
}


//
// Common functionality for sortable data tables
//

export const SortableStats = {
  data () {
    return {
      sortColumn: null,
      sortAscending: true,
    }
  },
  methods: {
    order (a, b) {
      let isGreater = false

      switch (this.sortColumn) {
        // Cost columns
        case 'cost':
          isGreater = a.cost.total > b.cost.total
          break
        case 'cpc':
          isGreater = a.cost.perCar > b.cost.perCar
          break
        case 'cpd':
          isGreater = a.cost.perDistance > b.cost.perDistance
          break
        case 'cp':
          isGreater = a.cost.totalBusiness > b.cost.totalBusiness
          break
        case 'cbhp':
          isGreater = a.cost.totalWorkCharge > b.cost.totalWorkCharge
          break
        case 'cice':
          isGreater = a.cost.iceSavings > b.cost.iceSavings
          break
        case 'cohme':
          isGreater = a.cost.ohmeSavings > b.cost.ohmeSavings
          break

        // Energy columns
        case 'energy':
          isGreater = a.energy.total > b.energy.total
          break
        case 'epc':
          isGreater = a.energy.perCar > b.energy.perCar
          break
        case 'epd':
          isGreater = a.energy.perDistance > b.energy.perDistance
          break
        case 'ep':
          isGreater = a.energy.totalBusiness > b.energy.totalBusiness
          break
        case 'ebhp':
          isGreater = a.energy.totalWorkCharge > b.energy.totalWorkCharge
          break

        // Distance columns
        case 'distance':
          isGreater = a.distance.total > b.distance.total
          break
        case 'dpc':
          isGreater = a.distance.perCar > b.distance.perCar
          break
        case 'dp':
          isGreater = a.distance.totalBusiness > b.distance.totalBusiness
          break

        case 'reported_distance':
          isGreater = a.reportedDistance.total > b.reportedDistance.total
          break
        case 'rdpc':
          isGreater = a.reportedDistance.perCar > b.reportedDistance.perCar
          break
        case 'rdp':
          isGreater = a.reportedDistance.totalBusiness > b.reportedDistance.totalBusiness
          break

        // CO2 columns
        case 'co2':
          isGreater = a.co2.total > b.co2.total
          break
        case 'spc':
          isGreater = a.co2.perCar > b.co2.perCar
          break
        case 'spd':
          isGreater = a.co2.perDistance > b.co2.perDistance
          break
        case 'sp':
          isGreater = a.co2.totalBusiness > b.co2.totalBusiness
          break
        case 'sbhp':
          isGreater = a.co2.totalWorkCharge > b.co2.totalWorkCharge
          break
        case 'sice':
          isGreater = a.co2.iceSavings > b.co2.iceSavings
          break
        case 'sohme':
          isGreater = a.co2.ohmeSavings > b.co2.ohmeSavings
          break
        
        // Default order
        default:
          return 0
      }

      if (this.sortAscending) return isGreater ? 1 : -1
      else return isGreater ? -1 : 1
    },
    sort (column) {
      if (this.sortColumn === column) {
        this.sortAscending = !this.sortAscending
      }
      else {
        this.sortColumn = column
        this.sortAscending = true
      }
    }
  }
}

//
// Huge tables can have thousands of rows, but only tens of them visible.
// This mixin helps you figure out whether given row is hidden or not.
//

export const HiddenRows = {
  data () {
    return {
      tableOffset: 0,
      windowHeight: 0,
      scrollOffset: 0,

      firstVisibleIndex: 0,
      lastVisibleIndex: 0,
    }
  },
  mounted () {
    this.measureDimensionsAndOffsets()
    this.updateScrollPosition()

    window.addEventListener('resize', this.measureDimensionsAndOffsets)
    window.addEventListener('scroll', this.updateScrollPosition)

    this.nextFrameRequest = window.requestAnimationFrame(() => this.updateVisibleBounds())
  },
  destroyed () {
    window.removeEventListener('resize', this.measureDimensionsAndOffsets)
    window.removeEventListener('scroll', this.updateScrollPosition)

    window.cancelAnimationFrame(this.nextFrameRequest)
  },
  methods: {
    measureDimensionsAndOffsets (e) {
      const table = this.$refs['table-body']
      const body = this.$refs['table']

      this.tableOffset = table.offsetTop + body.offsetTop
      this.windowHeight = document.getElementsByTagName('html')[0].clientHeight
    },
    updateScrollPosition (e) {
      this.scrollOffset = document.getElementsByTagName('html')[0].scrollTop
    },

    updateVisibleBounds () {
      const rowHeight = 65
      const visibleRowCount = Math.ceil(this.windowHeight / rowHeight)

      this.firstVisibleIndex = this.scrollOffset < this.tableOffset? 0 : Math.floor((this.scrollOffset - this.tableOffset - rowHeight) / rowHeight)
      this.lastVisibleIndex = this.firstVisibleIndex + visibleRowCount

      this.nextFrameRequest = window.requestAnimationFrame(() => this.updateVisibleBounds())
    },

    isRowHidden (index) {
      const rowOverdraw = Math.ceil(this.windowHeight / 65)
      return index < (this.firstVisibleIndex - rowOverdraw) || index > (this.lastVisibleIndex + rowOverdraw)
    },
  }
}