<template>
  <SectionTabs v-if="type === 'driver'" :items="[
    {name: 'Drivers', route: 'driver-management', count: userCount},
    {name: 'Driver groups', route: 'driver-groups', count: userGroupCount},
  ]" />
  <SectionTabs v-else-if="type === 'charger'" :items="[
    {name: 'Chargers', route: 'charger-management', count: chargerCount},
    {name: 'Charger groups', route: 'charger-groups', count: deviceGroupCount},
  ]" />
</template>

<script>
import { mapGetters } from 'vuex'
import SectionTabs from 'elements/SectionTabs'

export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return [
          'driver',
          'charger'
        ].indexOf(value) !== -1
      }
    }
  },
  components: { SectionTabs },
  computed: {
    ...mapGetters('Users', ['userCount']),
    ...mapGetters('Chargers', ['chargerCount']),
    ...mapGetters('Groups', ['userGroupCount', 'deviceGroupCount']),
  }
}
</script>