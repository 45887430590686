<template>
  <div class="dialog">
    <div class="backdrop" />

    <dialog open ref="modal">
      <slot />
    </dialog>
  </div>
</template>

<script>
import { Modal } from '~/mixins'

export const DialogChainNode = {
  props: {
    chain: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      openedDialog: null,
      previousDialogs: [],
    }
  },
  created () {
    if (this.chain != null) {
      this.previousDialogs = this.chain.split(',')
    }
    if (this.$route.query.dialogs != null) {
      this.tryOpeningChildDialog(this.$route.query.dialogs)
    }
  },
  watch: {
    '$route.query.dialogs': function (to) {
      const slug = this.previousDialogs.join(',')

      if (to == null && slug === '' || to === slug) {
        this.closeChildDialog(true)
      }
      else {
        this.tryOpeningChildDialog(to)
      }
    }
  },
  computed: {
    dialogDepth () {
      if (this.$route.query.dialogs == null) {
        return ''
      }
      
      const slug = this.previousDialogs.length > 0
        ? this.$route.query.dialogs.slice(this.previousDialogs.join(',').length + 1)
        : this.$route.query.dialogs

      if (slug !== '') {
        return 'stacked level-' + Math.min(slug.split(',').length, 4)
      }
      else {
        return ''
      }
    },
  },
  methods: {
    parentChain () {
      return this.dialogChain(this.openedDialog)
    },
    dialogChain (name) {
      const dialogChain = this.previousDialogs.slice()
      dialogChain.push(name)

      return dialogChain.join(',')
    },
    tryOpeningChildDialog (path) {
      const slug = this.previousDialogs.join(',')

      if (path != null && slug === '') {
        this.tryOpeningChain(path)
      }
      else if (path != null && path.indexOf(slug) === 0) {
        this.tryOpeningChain(path.slice(slug.length + 1))
      }
    },
    tryOpeningChain (chain) {
      const slugs = chain.split(',')

      if (slugs[0] === '' || this.isChildDialogOpened(slugs[0])) return

      this.openChildDialog(slugs[0], true)
    },

    isChildDialogOpened (name) {
      return this.openedDialog === name
    },
    openChildDialog (name, forwardButton = false) {
      const query = Object.assign({}, this.$route.query);
      query.dialogs = this.dialogChain(name)

      this.openedDialog = name

      if (forwardButton === false) {
        this.$router.push({
          name: this.$route.name,
          query: query
        })
      }
    },
    closeChildDialog (backButton = false) {
      const query = Object.assign({}, this.$route.query);
      if (this.previousDialogs.length > 0) {
        query.dialogs = this.previousDialogs.join(',')
      }
      else {
        delete query.dialogs
      }

      if (backButton === false) {
        this.$router.replace({
          name: this.$route.name,
          query: query
        })
      }
      this.openedDialog = null
    },
    completeChildDialog (_) {
      this.closeChildDialog()
    },

    completeDialog (payload) {
      if (this.$listeners['complete'] != null) {
        this.$emit('complete', payload)
      }
      else {
        this.$router.push({name: this.returnTarget})
      }
    },
    closeDialog () {
      if (this.$listeners['close']) {
        this.$emit('close')
      }
      else {
        this.$router.push({name: this.returnTarget})
      }
    }
  }
}

export const Dialog = {
  mixins: [Modal],
}

export default Dialog
</script>

<style lang="scss" scoped>
@import '~/common.scss';

</style>