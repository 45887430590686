<template>
  <SectionTabs :items="[
    {name: 'Account settings', route: 'account-settings'},
    {name: 'Business settings', route: 'business-settings'},
  ]" />
</template>

<script>
import SectionTabs from 'elements/SectionTabs'

export default {
  components: { SectionTabs },
}
</script>