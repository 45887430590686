<template>
  <span :class="['share', type]">
    <Amount :value="value" :type="unit" />
  </span>
</template>

<script>
import Amount from 'elements/Amount'
import { mapGetters } from 'vuex'

export default {
  components: { Amount },
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['public', 'home', 'personal', 'business'].indexOf(value) !== -1
      }
    },
    value: Number,
    unit: {
      type: String,
      default: 'currency'
    }
  },
  computed: {
    ...mapGetters(['units'])
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.share {
  position: relative;
  padding-left: 30px;

  @include icon-left (15, 1);

  &.home {
    @include icon-location (15, 1);
  }
  &.business {
    @include icon-location (16, 1);
  }
  &.personal {
    @include icon-location (17, 1);
  }
  &.public {
    @include icon-location (18, 1);
  }
}
</style>