<template>
  <small v-if="value !== null" :class="{positive: value >= 0, negative: value < 0, reverse: reverse}">
    <span class="paren">(</span><span class="sign">{{value >= 0 ? '&plus;' : '&minus;'}}</span>{{value | absolute | percentage(Math.abs(value) > 1 ? 0 : 1)}}%<span class="paren">)</span>
  </small>
</template>

<script>
export default {
  props: {
    value: Number,
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

small {
  position: relative;
  overflow: hidden;
  padding-left: 15px;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;

  .paren {
    position: absolute;
    left: -999em;
  }

  .sign {
    position: absolute;
    top: 4px;
    left: 0;
    overflow: hidden;
    display: inline-block;
    width: 10px;
    height: 10px;
    text-indent: -999em;
    text-align: left;

    @include icon(12, 0);
  }
  
  &.negative .sign {
    @include icon-location(12, 1);
  }

  color: $positive-color;

  &.negative {
    color: $negative-color;
  }

  &.reverse {
    &.positive {
      color: $negative-color;

      .sign {
        @include icon-location(21, 1);
      }
    }
    &.negative {
      color: $positive-color;

      .sign {
        @include icon-location(21, 2);
      }
    }
  }
}
</style>