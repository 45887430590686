<template>
  <form @submit.prevent="submit" novalidate>
    <TextField required type="text" v-model.trim="name" :error="nameError" id="name" label="Account holder’s name" placeholder="Enter your name…" />
    <TextField required type="email" v-model.trim="email" :error="emailError" id="email" label="Account holder’s email" placeholder="Enter your email…" />

    <div class="field" :class="{erroneous: ibanError.length > 0, focused: ibanFocused}">
      <label for="iban-field">IBAN</label>
      <div id="iban-field"></div>
      <transition name="show-error">
        <label v-show="ibanError.length > 0" for="iban-field" class="error" role="alert">{{ibanError}}</label>
      </transition>
    </div>

    <div class="pettite">By providing your payment information and confirming this payment, you authorise Ohme Operations UK Limited and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.</div>

    <div class="field actions horizontal">
      <button class="action" type="submit" :disabled="disabled">{{actionLabel}}</button>
      <button v-if="skippable" class="action secondary" :disabled="disabled" @click.prevent="$emit('skipped')">Do this later</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { stripe as stripeConfig } from '~/config'

import TextField from 'elements/TextField'

import { getStripeClientSecretForFleet, setDefaultPaymentMethod } from '~/api'

export default {
  components: { TextField },
  props: {
    skippable: Boolean,
    actionLabel: {
      type: String,
      default: "Save"
    },
  },
  data () {
    return {
      disabled: false,
      
      name: '',
      email: '',

      nameError: '',
      emailError: '',

      iban: null,
      ibanEmpty: true,
      ibanFocused: false,
      ibanError: '',
      stripe: null
    }
  },
  mounted () {
    if (window.Stripe != null) {
      this.stripe = Stripe(stripeConfig.apiKey)
      this.mountStripeElements()
      return
    }

    // Load Stripe
    this.disabled = true

    const stripeJs = document.createElement('script')
    stripeJs.src = 'https://js.stripe.com/v3/'
    stripeJs.async = true

    stripeJs.onload = () => {
      this.stripe = Stripe(stripeConfig.apiKey)
      this.disabled = false
      this.mountStripeElements()
    }

    stripeJs.onerror = (error) => {
      this.showErrorMessage(`Something went wrong trying to load our credit card form. Please try again later.`)
    }

    document.body.appendChild(stripeJs);
  },
  watch: {
    name(_, __) { this.nameError = '' },
    email(_, __) { this.emailError = '' },
  },
  computed: {
    ...mapGetters('Fleet', ['fleetId']),
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading']),
    ...mapActions('Notifications', ['showErrorMessage']),
    mountStripeElements () {
      const elements = this.stripe.elements()
      const style = {
        base: {
          color: '#5B6262',
          fontSize: '15px',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          '::placeholder': {
            color: '#CFD1D1'
          },
          ':-webkit-autofill': {
            color: '#5B6262',
          },
        },
        invalid: {
          color: '#ED5030',
          iconColor: '#ED5030',
          ':-webkit-autofill': {
            color: '#ED5030',
          },
        },
      }
      const options = {
        style,
        supportedCountries: ['SEPA'],
        placeholderCountry: 'GB',
      }

      // Create an instance of the IBAN Element
      this.iban = elements.create('iban', options)

      // Add an instance of the IBAN Element into the `iban-element` <div>
      this.iban.mount('#iban-field')
      this.iban.on('focus', () => this.ibanFocused = true)
      this.iban.on('blur', () => this.ibanFocused = false)
      this.iban.on('change', (event) => {
        this.ibanEmpty = !!event.empty
        if (event.error) {
          this.ibanError = event.error.message
        }
        else {
          this.ibanError = ''
        }
      })
    },
    clearErrors () {
      this.nameError = ''
      this.emailError = ''
      this.ibanError = ''
    },
    areFieldsValid () {
      if (this.name.length == 0) this.nameError = 'Please fill in the account holder’s name'
      if (this.email.length == 0) this.emailError = 'Please fill in the account holder’s email'
      if (this.ibanEmpty) this.ibanError = 'Please fill in the IBAN'

      return this.nameError.length == 0
        && this.emailError.length == 0
        && this.ibanError.length == 0
    },
    disable () {
      this.disabled = true
      this.showLoading()
    },
    enable () {
      this.disabled = false
      this.hideLoading()
    },
    submit () {
      this.clearErrors()

      if (!this.areFieldsValid()) return

      this.disable()

      getStripeClientSecretForFleet(this.fleetId)
      .then((secret) => {
        this.stripe.confirmSepaDebitSetup(secret, {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: {
              name: this.name,
              email: this.email,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            this.$emit('failed', result.error.message)
            this.enable()
          }
          else if (result.setupIntent) {
            setDefaultPaymentMethod(this.fleetId, result.setupIntent.payment_method)
            .then((result) => {
              this.hideLoading()
              this.$emit('succeeded', result)
            })
            .catch((error) => {
              this.$emit('failed', error)
              this.enable()
            })
          }
        })
        .catch((error) => {
          this.$emit('failed', error)
          this.enable()
        })
      })
      .catch((error) => {
        this.$emit('failed', error)
        this.enable()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.pettite {
  font-size: .75rem;
}
#iban-field {
  margin: 0;
  padding: 13px 15px;
  border: 2px solid $input-border-color;
  border-radius: 4px;
  background-color: $default-background;
  color: $default-text;
  font-weight: 400;
  min-height: 1em;

  .focused > & {
    border-color: $outline-color;
  }
  .erroneous > & {
    border-color: $erroneous-border-color;
  }

  &.StripeElement--webkit-autofill {
    background: $autofill-color !important;
  }
}

main.form .fieldset .settings {
  position: relative;
  .field {
    flex: 0 1 calc(50% - 12.5px);
    width: calc(50% - 12.5px);
    margin-right: calc(50% + 12.5px);
  }
  .pettite {
    position: absolute;
    top: 1.5rem;
    left: calc(50% + 12.5px);
    right: 10px;
  }
}
</style>