<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    role="img"
    aria-labelledby="unlink-svg"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    :style="{ cursor: cursorStyle }"
  >
    <title id="unlink-svg">Unlink charger</title>
    <path
      class="svg-unlink"
      d="M10.56,5.77l2.72-2.72a5.43,5.43,0,0,1,3.84-1.59,5.43,5.43,0,0,1,5.42,5.42A5.43,5.43,0,0,1,21,10.72l-2.72,2.72"
    ></path>
    <path class="svg-unlink" d="M5.77,10.56,3.05,13.28A5.42,5.42,0,1,0,10.72,21l2.72-2.72"></path>
    <line class="svg-unlink" x1="16.79" y1="7.21" x2="7.21" y2="16.79"></line>
    <line class="svg-unlink" x1="20.63" y1="15.83" x2="23.5" y2="15.83"></line>
    <line class="svg-unlink" x1="15.83" y1="20.63" x2="15.83" y2="23.5"></line>
    <line class="svg-unlink" x1="19.19" y1="19.19" x2="21.1" y2="21.1"></line>
    <line class="svg-unlink" x1="3.38" y1="8.17" x2="0.5" y2="8.17"></line>
    <line class="svg-unlink" x1="8.17" y1="3.38" x2="8.17" y2="0.5"></line>
    <line class="svg-unlink" x1="4.81" y1="4.81" x2="2.9" y2="2.9"></line>
  </svg>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    cursorStyle() {
      return this.disabled ? "not-allowed" : "pointer";
    },
  },

  methods: {
    onMouseEnter() {
      if (!this.disabled) {
        this.$el.classList.add("hover");
      }
    },
    onMouseLeave() {
      if (!this.disabled) {
        this.$el.classList.remove("hover");
      }
    },
  },
};
</script>

<style>
.svg-unlink {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.hover .svg-unlink {
  stroke: #6374f8;
}

.hover {
  transform: scale(1.5);
}
</style>
