import Vue from 'vue'

import {
  createFleet,
  updateFleetDetails,
  updateFleetReimbursementSettings,
  getDirectDebitInfo
} from '~/api'

import { fleetFeaturesOverride } from '~/config'

export const state = {
  fleets: {
    // "id": { 
    //   id: "", 
    //   name: "", 
    //   address: { ... },
    //   rootUserGroupId: "",
    //   rootDeviceGroupId: ""
    // }
  },

  fleetId: null,
  fleetCurrencyCode: null,
  fleetName: null,
  fleetFeatures: {}, // {reimbursements: false, ...}
  fleetSetupState: {}, // {directDebit: false, reimbursementSettings: false, ...}
  fleetBankDetails: {}, // {bankCode: "", branchCode: "", countryCode: "", last4Digits: ""}
  fleetReimbursementSettings: {}, // {settlementType: "bank" | "utility", reimbursementType: "flat-rate" | "mileage" | "energy-cost", flatRatePerKWh: {currency: "GBP", amount: 0.00} }

  contactName: null,
  contactEmail: null,

  fleetPhone: null,
  fleetStreet1: null,
  fleetStreet2: null,
  fleetCity: null,
  // fleetCountry: null,
  fleetPostcode: null,
}

export const getters = {
  fleets: state => Object.values(state.fleets),
  fleetId: state => state.fleetId,
  fleetCurrencyCode: state => state.fleetCurrencyCode,
  fleetName: state => state.fleetName,
  fleetDetails: state => ({
    contactName: state.contactName,
    contactEmail: state.contactEmail,
    name: state.fleetName,
    phone: state.fleetPhone,
    street1: state.fleetStreet1,
    street2: state.fleetStreet2,
    // country: state.fleetCountry,
    city: state.fleetCity,
    postcode: state.fleetPostcode,
  }),
  fleetBankDetails: state => state.fleetBankDetails,
  fleetReimbursementSettings: state => state.fleetReimbursementSettings,
  isCorporate: state => state.fleetFeatures.drivers !== false,
  isResidential: state => state.fleetFeatures.chargers !== false,
  hasFeatures: state => fleetFeaturesOverride == null? state.fleetFeatures : fleetFeaturesOverride,
  hasSetup: state => state.fleetSetupState,
  allowUnlinking : state => state.fleetFeatures?.allowUnlinking,
}

export const mutations = {
  reset (state) {
    state.fleetId = null
    state.fleetName = null
    state.fleetFeatures = {}
    state.fleetSetupState = {}

    state.contactName = null
    state.contactEmail = null
  
    state.fleetPhone = null
    state.fleetStreet1 = null
    state.fleetStreet2 = null
    // state.fleetCountry = null
    state.fleetCity = null
    state.fleetPostcode = null
  },
  resetList (state) {
    state.fleets = {}
  },
  addFleet (state, fleet) {
    Vue.set(state.fleets, fleet.id, fleet)
  },
  setFleet (state, fleet) {
    state.fleetId = fleet.id
    state.fleetName = fleet.name
    state.fleetCurrencyCode = fleet.currencyCode
    state.fleetFeatures = fleet.features
  },
  setFleetDirectDebitSetup (state, status) {
    state.fleetSetupState.directDebit = !!status
  },
  setFleetReimbursementSetup (state, status) {
    state.fleetSetupState.reimbursementSettings = !!status
  },
  setFleetDetails (state, details) {
    state.contactName = details.contactName
    state.contactEmail = details.contactEmail
    state.fleetName = details.name
    state.fleetPhone = details.phone
    state.fleetStreet1 = details.street1
    state.fleetStreet2 = details.street2
    // state.fleetCountry = details.country
    state.fleetCity = details.city
    state.fleetPostcode = details.postcode
  },
  setFleetBankDetails (state, details) {
    state.fleetBankDetails = details
  },
  setReimbursementSettings (state, settings) {
    state.fleetReimbursementSettings = settings
  }
}

export const actions = {
  createFleet ({ state, commit }, data) {
    return createFleet(state.fleetId, data).then((f) => {
      commit('addFleet', f)
      return f
    })
  },
  updateFleetDetails ({ state, commit }, data) {
    return updateFleetDetails(state.fleetId, data).then((d) => commit('setFleetDetails', d))
  },
  updateBankDetails ({ state, commit}) {
    commit('setFleetDirectDebitSetup', true)
    return getDirectDebitInfo(state.fleetId).then((bankDetails) => commit('setFleetBankDetails', bankDetails))
  },
  updateReimbursementSettings ({ state, commit }, data) {
    commit('setFleetReimbursementSetup', true)
    return updateFleetReimbursementSettings(state.fleetId, data).then(() => commit('setReimbursementSettings', data))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}