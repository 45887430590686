//
// Initialize Vue
//

import Vue from 'vue'

Vue.config.silent = false
Vue.config.devtools = true
Vue.config.performance = false

// 
// Initialize Firebase
// 

import * as firebase from 'firebase/app'
import { firebase as config } from '~/config'

firebase.initializeApp(config)

//
// Denormalise the search/hash parts of the URL
//

// Turn `/?varA=A&varB=B#/some-path` to `/#/some-path?varA=A&varB=B`
if (window.location.search.length > 0) {
  const l = window.location
  window.location = l.protocol + '//' + l.host + l.pathname + l.hash + l.search
}

// 
// Initialize Vue
// 

import Root from './components/Root.vue'
import store from './store'
import router from './router'

import './filters'

const appIntance = new Vue({
  router,
  store,
  render: r => r(Root)
}).$mount('#app')


// 
// Initialize Firebase Auth
// 

import 'firebase/auth'

// Hide app while authentication is happening
store.commit('setInitializingStatus', true)

firebase.auth().onAuthStateChanged(function(user) {
  if (!user) {
    store.dispatch('teardown')
    return
  }

  let query = router.currentRoute.query

  // Resolve internal redirect
  if (query.redirect != null) {
    const redirected = router.resolve(query.redirect)

    if (redirected.route != null && redirected.route.name === 'auth-redirect') {
      query = {
        response_type: 'code',
        ...redirected.route.query
      }
    }
  }

  // Handle 3rd party authentication: 
  // http://localhost:1234/#/authentication/login?response_type=code&client_id=kaluza&redirect_uri=https%3A%2F%2Fexample.com%2Flogin&scope=app&state=none
  if ( query.response_type === 'code'
    && query.client_id != null
    && query.redirect_uri != null
    // && query.scope != null
    // && query.state != null
  ) {
    // Simply log user in and redirect without loading any more data
    store.commit('Account/signIn', {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    })
    store.commit('setInitializingStatus', false)

    router.replace({name: 'auth-redirect', query: {
      client_id: query.client_id,
      redirect_uri: query.redirect_uri,
      scope: query.scope,
      state: query.state,
    }})
  }
  // Sign user in and load all their fleet data
  else {
    store.dispatch('setup', {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    }).catch(handleGeneralAPIErrors)
  }
})

// Handle all API and Server errors
export function handleGeneralAPIErrors (error) {
  if (error.response != null && [401, 403].indexOf(error.response.status) !== -1) {
    store.dispatch('Notifications/showErrorMessage', 'Your account does not have sufficient privileges')
    firebase.auth().signOut()
    Vue.nextTick(() => router.replace({name: 'login'}))
  }
  else if (error.response != null && [502, 503].indexOf(error.response.status) !== -1) {
    store.dispatch('Notifications/showErrorMessage', 'The server is restarting! Please try again later.')
  }
  else if (error.response != null && error.response.status != null) {
    store.dispatch('Notifications/showErrorMessage', 'Data failed to load due to an internal server error')
  }
  else {
    console.error(error)
  }
}