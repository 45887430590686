import Vue from 'vue'
import Router from 'vue-router'

import store from './store'

Vue.use(Router)

function overlayChildren (title, baseRoute) {
  return [
    {
      name: baseRoute + '-driver-details',
      meta: { title: 'Driver details – ' + title },
      path: 'driver-details/:userId',
      component: require('sections/UserDetailsSidebar').default,
      props: route => ({
        baseRoute: baseRoute,
        visible: route.query.visible != null
      })
    },
    {
      name: baseRoute + '-driver-stats',
      meta: { title: 'Driver stats – ' + title },
      path: 'driver-stats/:userId',
      component: require('sections/UserStatsSidebar').default,
      props: { baseRoute: baseRoute }
    },
    {
      name: baseRoute + '-charger-details',
      meta: { title: 'Charger details – ' + title },
      path: 'charger-details/:chargerId',
      component: require('sections/ChargerDetailsSidebar').default,
      props: { baseRoute: baseRoute }
    },
    {
      name: baseRoute + '-package-details',
      meta: { title: 'Package details – ' + title },
      path: 'package-details/:productId',
      component: require('sections/ProductDetailsSidebar').default,
      props: { baseRoute: baseRoute }
    },
    {
      name: baseRoute + '-group-details',
      meta: { title: 'Group details – ' + title },
      path: 'group-details/:groupId',
      component: require('sections/GroupDetailsSidebar').default,
      props: { baseRoute: baseRoute },
    },
    {
      name: baseRoute + '-add-charger',
      meta: { title: 'Add charger – ' + title },
      path: 'add-charger',
      component: require('sections/ChargerEditor').default
    },
    {
      name: baseRoute + '-edit-charger',
      meta: { title: 'Edit charger – ' + title },
      path: 'edit-charger/:chargerId',
      component: require('sections/ChargerEditor').default
    },
    {
      name: baseRoute + '-add-driver',
      meta: { title: 'Add driver – ' + title },
      path: 'add-driver',
      component: require('sections/UserEditor').default
    },
    {
      name: baseRoute + '-edit-driver',
      meta: { title: 'Edit driver – ' + title },
      path: 'edit-driver/:userId',
      component: require('sections/UserEditor').default
    },
    {
      name: baseRoute + '-add-group',
      meta: { title: 'Add group – ' + title },
      path: 'add-group/:groupType',
      component: require('sections/GroupEditor').default
    },
    {
      name: baseRoute + '-edit-group',
      meta: { title: 'Edit group – ' + title },
      path: 'edit-group/:groupId',
      component: require('sections/GroupEditor').default
    },
    {
      name: baseRoute + '-add-package',
      meta: { title: 'Add package – ' + title },
      path: 'add-package',
      component: require('sections/ProductEditor').default,
    },
    {
      name: baseRoute + '-edit-package',
      meta: { title: 'Edit package – ' + title },
      path: 'edit-package/:productId',
      component: require('sections/ProductEditor').default,
    },
    {
      name: baseRoute + '-add-manager',
      meta: { title: 'Add manager – ' + title },
      path: 'add-manager',
      component: require('sections/AdminEditor').default
    },
    {
      name: baseRoute + '-edit-manager',
      meta: { title: 'Edit manager – ' + title },
      path: 'edit-manager/:userId',
      component: require('sections/AdminEditor').default
    },
  ]
}

const router = new Router({
  linkActiveClass: 'current',
  linkExactActiveClass: 'current-root',
  routes: [
    {
      name: 'authentication',
      meta: { title: 'Authentication', requiresAuthentication: false },
      path: '/authentication',
      component: require('./components/Authentication').default,
      children: [
        {
          name: 'login',
          meta: { title: 'Login', requiresAuthentication: false },
          path: 'login',
          component: require('sections/AuthLogin').default
        },
        {
          name: 'forgotten-password',
          meta: { title: 'Password reset', requiresAuthentication: false },
          path: 'forgotten-password',
          component: require('sections/AuthForgotten').default
        },
        {
          name: 'reset-password',
          meta: { title: 'Password reset', requiresAuthentication: false },
          path: 'reset-password/:code',
          component: require('sections/AuthReset').default
        },
        {
          path: '*',
          redirect: 'login'
        }
      ]
    },
    {
      name: 'auth-redirect',
      meta: { title: 'Confirm authentication', requiresAuthentication: true },
      path: '/confirm-authentication',
      component: require('sections/AuthRedirect').default,
    },
    {
      name: 'invitation',
      meta: { title: 'Complete your profile', requiresAuthentication: false },
      path: '/invitation',
      component: require('sections/FleetInvitation').default,
      props: (route) => ({
        step: route.query.step == null? 0 : Number.parseInt(route.query.step),
      })
    },
    {
      name: 'direct-debit',
      meta: { title: 'Setup Direct Debit', requiresAuthentication: true },
      path: '/direct-debit',
      component: require('sections/FleetDirectDebit').default,
    },
    {
      name: 'reimbursement-settings',
      meta: { title: 'Reimbursement Settings', requiresAuthentication: true },
      path: '/reimbursement-settings',
      component: require('sections/FleetReimbursementSettings').default,
    },
    {
      path: '/fleet/:fleetId',
      component: require('./components/Portal').default,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          redirect: 'management/driver'
        },
        // {
        //   meta: { title: 'Dashboard' },
        //   name: 'dashboard',
        //   path: '/dashboard',
        //   component: require('sections/Dashboard').default
        // },
        {
          name: 'stats',
          path: 'stats',
          redirect: 'stats/fleet'
        },
        {
          name: 'management',
          path: 'management',
          redirect: 'management/driver'
        },
        {
          name: 'administration',
          path: 'administration',
          redirect: 'administration/managers'
        },
        {
          name: 'fleet-stats',
          meta: { title: 'Fleet statistics' },
          path: 'stats/fleet',
          component: require('sections/FleetStatistics').default
        },
        {
          name: 'driver-root',
          path: 'management/driver',
          redirect: 'management/driver/list'
        },
        {
          name: 'driver-management',
          meta: { title: 'Driver management' },
          path: 'management/driver/list',
          component: require('sections/FleetManagement').default,
          children: overlayChildren('Driver management', 'driver-management')
        },
        {
          name: 'driver-groups',
          meta: { title: 'Driver groups' },
          path: 'management/driver/groups',
          component: require('sections/GroupManagement').default,
          props: { groupType: 'driver' },
          children: overlayChildren('Driver groups', 'driver-groups')
        },
        {
          name: 'charger-root',
          path: 'management/charger',
          redirect: 'management/charger/list'
        },
        {
          name: 'charger-management',
          meta: { title: 'Charger management' },
          path: 'management/charger/list',
          component: require('sections/ChargerManagement').default,
          children: overlayChildren('Charger groups', 'charger-management')
        },
        {
          name: 'charger-groups',
          meta: { title: 'Charger groups' },
          path: 'management/charger/groups',
          component: require('sections/GroupManagement').default,
          props: { groupType: 'charger' },
          children: overlayChildren('Charger groups', 'charger-groups')
        },
        {
          name: 'package-management',
          meta: { title: 'Package management' },
          path: 'management/package',
          component: require('sections/ProductManagement').default,
          children: overlayChildren('Package management', 'package-management')
        },
        {
          name: 'transactions',
          meta: { title: 'Transactions' },
          path: 'administration/transactions',
          component: require('sections/Transactions').default,
        },
        {
          name: 'reimbursements',
          meta: { title: 'Reimbursements' },
          path: 'administration/reimbursements',
          component: require('sections/Reimbursements').default,
        },
        {
          name: 'admin-management',
          meta: { title: 'Managers' },
          path: 'administration/managers',
          component: require('sections/AdminManagement').default,
          children: overlayChildren('Managers', 'admin-management')
        },
        {
          name: 'driver-stats',
          meta: { title: 'Driver statistics' },
          path: 'stats/drivers',
          component: require('sections/UserStatistics').default,
          children: overlayChildren('Driver statistics', 'driver-stats')
        },
        {
          name: 'charger-stats',
          meta: { title: 'Charger statistics' },
          path: 'stats/chargers',
          component: require('sections/ChargerStatistics').default,
        },
        {
          name: 'new-fleet',
          meta: { title: 'Add organisation' },
          path: 'add-organisation',
          component: require('sections/FleetAdder').default
        },
        {
          name: 'settings',
          path: 'administration/settings',
          redirect: 'administration/settings/account'
        },
        {
          name: 'account-settings',
          meta: { title: 'Account settings' },
          path: 'administration/settings/account',
          component: require('sections/AccountSettings').default
        },
        {
          name: 'business-settings',
          meta: { title: 'Business settings' },
          path: 'administration/settings/business',
          component: require('sections/BusinessSettings').default
        },
        {
          path: '*',
          redirect: 'dashboard'
        }
      ]
    },
    {
      path: '*',
      redirect: '/authentication'
    }
  ]
})

router.beforeEach(function (to, from, next) {
  const isAuthenticated = store.getters['Account/isAuthenticated']

  if (
    to.matched.some(route => route.meta.requiresAuthentication !== false) &&
    !isAuthenticated
  ) {
    next({
      path: '/authentication/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})

router.beforeEach(function (to, from, next) {
  document.title = (to.meta.title || 'Untitled') + ' – Ohme'
  next()
})

export default router