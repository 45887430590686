<template>
  <ul>
    <li><button :class="{current: tab === 'cost', cost: true}" @click="change('cost')">Costings</button></li>
    <li><button :class="{current: tab === 'energy', energy: true}" @click="change('energy')">Energy</button></li>
    <li><button :class="{current: tab === 'co2', co2: true}" @click="change('co2')">CO2 emissions</button></li>
    <li v-if="isCorporate"><button :class="{current: tab === 'reported_distance', distance: true}" @click="change('reported_distance')">Reported {{units.distancePlural}}</button></li>
    <li><button :class="{current: tab === 'estimate_distance', distance: true}" @click="change('estimate_distance')">Estimate {{units.distancePlural}}</button></li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      tab: 'cost'
    }
  },
  computed: {
    ...mapGetters(['units']),
    ...mapGetters('Fleet', ['isCorporate']),
  },
  methods: {
    change (tab) {
      this.tab = tab
      this.$emit('change', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

ul {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 40px;

  li + li {
    margin-left: 40px;
  }

  button {
    position: relative;
    border: 0;
    border-bottom: 2px solid transparent;
    padding: 10px 10px 10px 28px;
    background: transparent;
    color: #A9AFAF;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    
    @include icon-left (8, 0);
    &::before { left: -5px; }

    &.distance { @include icon-location(9, 0) }
    &.co2 { @include icon-location(10, 0) }
    &.energy { @include icon-location(11, 0) }

    &.current.cost {
      @include icon-location(8, 1);
      color: $cost-color;
      border-bottom-color: #CCDAEF;
    }
    &.current.distance {
      @include icon-location(9, 1);
      color: $miles-color;
      border-bottom-color: #F7DCDA;
    }
    &.current.co2 {
      @include icon-location(10, 1);
      color: $co2-color;
      border-bottom-color: #D8F1D8;
    }
    &.current.energy {
      @include icon-location(11, 1);
      color: $miles-color;
      border-bottom-color: #F7DCDA;
    }
  }
}
</style>
