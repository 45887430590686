<template>
  <Sidebar
    v-if="user != null && user.invited && !visible"
    class="invited"
    :back="baseRoute"
    :key="'invited-' + user.id"
  >
    <Portrait :src="user.photo" class="portrait" />
    <p><span class="badge">Invited</span></p>
    <h2>{{ user.name }}</h2>
    <p>
      Invite sent to <strong>{{ user.email }}</strong>
    </p>
    <p class="actions">
      <button class="action" @click="resend" :disabled="sending || resent">
        {{ resent ? "Sent" : "Resend invite" }}
      </button>
    </p>
    <p class="links">
      <router-link
        class="switch stats"
        :to="{
          name: baseRoute + '-driver-details',
          params: { userId: user.id },
          query: { visible: true },
        }"
        >View details</router-link
      >
    </p>
    <Overlay v-if="confirmUnlink && allowUnlinking" @close="confirmUnlink = false">
      <div class="confirm-removal sheet">
        <h3>Are you sure you want to unlink this charger?</h3>
        <p>Disconnecting this charger may lead to the loss of the driver's charging data.</p>
        <div class="actions">
          <button class="action" @click="unlinkCharger">Unlink charger</button>
          <button class="cancel" @click="confirmUnlink = false">Cancel</button>
        </div>
      </div>
      <div class="shade" />
    </Overlay>
  </Sidebar>
  <Sidebar v-else-if="user != null" :back="baseRoute" :key="'user-' + user.id">
    <header class="user">
      <Portrait :src="user.photo" class="portrait" />
      <h2>{{ user.name }}</h2>

      <router-link
        v-if="!user.invited"
        class="switch stats"
        :to="{
          name: baseRoute + '-driver-stats',
          params: { userId: user.id },
        }"
        >View driver stats</router-link
      >
    </header>

    <template v-slot:controls>
      <transition name="bottom-sheet">
        <div v-if="!confirmRemoval" class="choose-action">
          <p class="actions sheet">
            <router-link
              tag="button"
              class="action"
              :to="{
                name: baseRoute + '-edit-driver',
                params: { userId: user.id },
                query: { return: baseRoute + '-driver-details' },
              }"
              >Edit driver</router-link
            >
            <button class="action delete" @click="confirmRemoval = true">Remove</button>
          </p>
        </div>
      </transition>

      <transition name="bottom-sheet">
        <Overlay v-if="confirmRemoval" @close="confirmRemoval = false">
          <div class="confirm-removal sheet">
            <h3>Are you sure you want to remove this driver?</h3>
            <p>
              Removing the driver from the fleet may lose some of its data, including statistics. The driver will not be
              able to use their charger.
            </p>
            <div class="actions">
              <button class="action" @click="removeDriver">Remove driver</button>
              <button class="cancel" @click="confirmRemoval = false">Cancel</button>
            </div>
          </div>
          <div class="shade" />
        </Overlay>
        <Overlay v-else-if="confirmUnlink && allowUnlinking" @close="confirmUnlink = false">
          <div class="confirm-removal sheet">
            <h3>Are you sure you want to unlink this charger?</h3>
            <p>Disconnecting this charger may lead to the loss of the driver's charging data.</p>
            <div class="actions">
              <button class="action" @click="unlinkCharger">Unlink charger</button>
              <button class="cancel" @click="confirmUnlink = false">Cancel</button>
            </div>
          </div>
          <div class="shade" />
        </Overlay>
      </transition>
    </template>

    <section v-hide-empty="user" class="owner details">
      <h3>Driver details</h3>

      <ul>
        <li v-if="!isEmpty(user.homeAddress)">
          <span class="label">Home address</span><span class="invisible">:</span>
          {{
            Object.values(user.homeAddress)
              .filter((p) => p.length > 0)
              .join(", ")
          }}
        </li>
        <li v-if="!isEmpty(user.officeAddress)">
          <span class="label">Office address</span><span class="invisible">:</span>
          {{
            Object.values(user.officeAddress)
              .filter((p) => p.length > 0)
              .join(", ")
          }}
        </li>
        <li v-if="user.email">
          <span class="label">Email address</span><span class="invisible">:</span>
          <a v-if="user.email" :href="'mailto:' + user.email">{{ user.email }}</a>
        </li>
        <li v-if="user.phone">
          <span class="label">Phone number</span><span class="invisible">:</span>
          <a v-if="user.phone" :href="'tel:' + user.phone">{{ user.phone }}</a>
        </li>
        <li v-if="user.driverLicence">
          <span class="label">Driver license</span><span class="invisible">:</span>
          {{ user.driverLicence }}
        </li>
        <li v-if="user.id">
          <span class="label">User ID</span><span class="invisible">:</span>
          <var>{{ user.id }}</var>
        </li>
      </ul>
    </section>

    <section v-hide-empty="user.car" class="car details">
      <h3>Car details</h3>

      <ul class="make">
        <li v-if="user.car.make">
          <span class="label">Car make</span><span class="invisible">:</span>
          <img v-if="user.car.logo" class="logo" :src="user.car.logo" alt="" /> {{ user.car.make }}
        </li>
        <li v-if="user.car.model" class="model">
          <span class="label">Car model</span><span class="invisible">:</span>
          {{ user.car.model }}
        </li>
        <li v-if="user.car.year" class="year">
          <span class="label">Car year</span><span class="invisible">:</span>
          {{ user.car.year }}
        </li>
        <li v-if="user.car.registration" class="registration">
          <span class="label">Registration number</span><span class="invisible">:</span>
          {{ user.car.registration }}
        </li>
      </ul>
    </section>

    <section v-hide-empty="[user.tariff, user.fuelCard, user.cable]" class="charging details">
      <h3>Charging</h3>

      <ul>
        <li v-if="user.tariff && user.tariff.name">
          <span class="label">Home tariff</span><span class="invisible">:</span>
          <img v-if="user.tariff.logo" class="logo" :src="user.tariff.logo" alt="" /> {{ user.tariff.name }}
        </li>
        <li v-if="user.fuelCard">
          <span class="label">Fuel card</span><span class="invisible">:</span>
          {{ user.fuelCard }}
        </li>
        <template v-if="user.cable.id">
          <li>
            <span class="label">Cable ID</span><span class="invisible">:</span>
            {{ user.cable.id }}
          </li>
          <li v-if="allowUnlinking">
            <span class="label">Unlink charger</span><span class="invisible">:</span>
            <div @click="confirmUnlink = true">
              <UnlinkSVG class="svg-icon" />
            </div>
          </li>
        </template>
        <li v-if="user.cable.type">
          <span class="label">Cable type</span><span class="invisible">:</span>
          {{ user.cable.type }}
        </li>
      </ul>
    </section>

    <section v-if="user.groupIds.length > 0" class="groups details">
      <h3>Driver groups</h3>

      <p><GroupTags type="user" :baseRoute="baseRoute" :ids="user.groupIds" /></p>
    </section>
  </Sidebar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { resendDriverInvitation } from "~/api";
import { isEmpty } from "~/filters";

import Portrait from "elements/Portrait";
import Sidebar from "elements/Sidebar";
import GroupTags from "elements/GroupTags";
import Overlay from "elements/Overlay";

import UserStatsSidebar from "sections/UserStatsSidebar.vue";

import UnlinkSVG from "elements/SVGs/Unlink.vue";

export default {
  components: { Portrait, Sidebar, GroupTags, Overlay, UserStatsSidebar, UnlinkSVG },
  props: {
    baseRoute: String,
    visible: Boolean,
  },
  data() {
    return {
      confirmRemoval: false,
      sending: false,
      resent: false,
      confirmUnlink: false,
    };
  },
  created() {
    if (this.$route.query.confirmUnlink) this.confirmUnlink = true;
  },

  computed: {
    ...mapGetters("Users", ["getUserById"]),
    ...mapGetters("Fleet", ["fleetId", "allowUnlinking"]),
    user() {
      return this.getUserById(this.$route.params.userId);
    },
  },
  methods: {
    ...mapActions("Users", ["deleteUserById", "unlinkChargerById"]),
    ...mapActions("Notifications", ["showErrorMessage", "showSuccessMessage"]),
    isEmpty(value) {
      return isEmpty(value);
    },
    resend() {
      this.sending = true;
      resendDriverInvitation(this.fleetId, this.user.id)
        .then(() => {
          this.resent = true;
          this.showSuccessMessage(`Invitation has been sent to the driver successfully`);
        })
        .catch((error) => {
          this.showErrorMessage(`Could not resend the invitation: ${error.message}`);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    removeDriver() {
      this.deleteUserById(this.user.id)
        .then(() => {
          this.$router.replace({ name: this.baseRoute });
          this.showSuccessMessage("The driver has been removed");
        })
        .catch(() => {
          this.showErrorMessage("The driver could not be removed");
        });
    },
    unlinkCharger() {
      try {
        this.unlinkChargerById({
          chargerId: this.user.cable.id,
          userId: this.user.id,
        });
        this.confirmUnlink = false;
        this.showSuccessMessage("The charger has been unlinked");
      } catch (err) {
        this.showErrorMessage("The charger could not be unlinked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~/common.scss";

.invited {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  .portrait {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 4px;
    margin-bottom: 20px;
  }

  .actions {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .links {
    margin-bottom: 40px;
  }
}

section.owner {
  .user + & {
    margin-top: 30px;
  }

  h3 {
    @include icon-location(16, 2);
  }

  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      flex: 0 1 calc(50% - 25px);
    }
  }
}

section.car {
  h3 {
    @include icon-location(14, 2);
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;

    li {
      flex: 0 1 calc(33.33% - 25px);

      &.registration,
      &.dealer {
        flex: 0 2 calc(66.66% - 25px);
      }
    }
  }
}

section.charging {
  h3 {
    @include icon-location(15, 2);
  }

  ul {
    display: flex;
    flex-flow: row wrap;

    li {
      flex: 0 1 calc(33.33% - 25px);
    }
  }

  .cable-id {
    white-space: nowrap;
  }
}
</style>
