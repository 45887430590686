<template>
  <span>
    <span class="invisible">(</span><slot /><span class="invisible">)</span>
  </span>
</template>

<script>
export default {
  
}
</script>