<template>
  <router-link v-if="to" :to="to" :class="{abbreviated}">
    <abbr v-if="abbreviated">{{abbrStart}}<span class="ellipsis">…</span>{{abbrEnd}}</abbr>
    <span>{{value}}</span>
  </router-link>
  <span v-else :class="{abbreviated}">
    <abbr v-if="abbreviated">{{abbrStart}}<span class="ellipsis">…</span>{{abbrEnd}}</abbr>
    <span>{{value}}</span>
  </template>
</template>

<script>
export default {
  props: {
    value: String,
    maxLength: {
      type: Number,
      default: 0
    },
    middle: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      required: false,
    }
  },
  computed: {
    abbreviated () {
      return this.maxLength > 0 && this.value.length > this.maxLength
    },
    abbrStart () {
      return this.middle ? this.value.substring(0, Math.floor(this.maxLength / 2)) : this.value.substring(0, Math.floor(this.maxLength - 1))
    },
    abbrEnd () {
      return this.middle ? this.value.substring(this.value.length - Math.floor((this.maxLength - 1) / 2)) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.abbreviated {
  position: relative;

  > span {
    display: none;
    position: absolute;
    top: -6px;
    left: -5px;
    z-index: 1;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 1px 2px $dropshadow-color;
    background-color: $menu-background;
  }

  &.last > span {
    left: auto;
    right: -5px;
  }

  &:hover {
    > abbr {
      visibility: hidden;
    }
    > span {
      display: block;
    }
  }

  .ellipsis {
    color: $deemphasized-text;
  }
}

a.abbreviated .ellipsis {
  color: inherit;
}
</style>