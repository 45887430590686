<template>
  <main class="form">
    <header>
      <h1>Settings</h1>

      <SettingsTabs />
    </header>

    <form @submit.prevent="saveDetails" novalidate>
      <section class="fieldset contact">
        <h2 class="legend">Contact details</h2>
        <div class="group">
          <TextField required type="text" v-model.trim="fleetName" :error="fleetNameError" id="fleet-name" label="Organisation name" placeholder="Enter organisation name…" @input="checkDirtyDetails" />
          <AddressField required v-model="fleetAddress" :error="fleetAddressError" id="fleet-address" label="Primary address" @input="checkDirtyDetails" />
        </div>
        <div class="group">
          <TextField required type="text" v-model.trim="contactName" :error="contactNameError" id="contact-name" label="Contact name" placeholder="Enter contact name…" @input="checkDirtyDetails" />
          <TextField required type="email" v-model.trim="contactEmail" :error="contactEmailError" id="contact-email" label="Contact email" placeholder="Enter contact email…" @input="checkDirtyDetails" />
          <TextField required type="text" v-model.trim="fleetPhone" :error="fleetPhoneError" id="fleet-phone" label="Contact number" placeholder="Enter contact number…" @input="checkDirtyDetails" />
        </div>

        <div class="field actions">
          <button class="action" :disabled="savingDetails || !isDirtyDetails" type="submit">{{'Save changes'}}</button>
        </div>
      </section>
    </form>

    <section v-if="hasFeatures.reimbursements" class="fieldset billing">
      <h2 class="legend">Billing and bank account information</h2>
      <div v-if="!showStripeForm">
        <p v-if="hasSetup.directDebit">The billing has been successfully setup for account number ending with {{fleetBankDetails.last4Digits}}.</p>
        <p v-else>Please provide billing information.</p>
        <div class="field actions">
          <button class="action" @click="showStripeForm = true">{{hasSetup.directDebit? 'Update bank details' : 'Add bank details'}}</button>
        </div>
      </div>
      <StripeIBAN v-else class="settings" actionLabel="Update information" @failed="onIBANFailure" @succeeded="onIBANSuccess" />
    </section>

    <form v-if="hasFeatures.reimbursementSettings" @submit.prevent="saveReimbursement" novalidate><section class="fieldset vertical reimbursement">
      <h2 class="legend">Reimbursements</h2>
      <RadioField
        required
        v-model="reimbursementSettlement"
        :error="reimbursementSettlementError"
        id="reimbursement-settlement"
        label="Settlement type"
        :options="{
          'bank': 'Direct bank transfer',
          'utility': 'Utility transfer'
        }"
        @input="checkDirtyReimbursement"
      />

      <RadioField v-if="reimbursementSettlement === 'bank'"
        required
        v-model="reimbursementType"
        :error="reimbursementTypeError"
        id="reimbursement-type"
        label="Reimbursement type"
        :options="{
          'flat-rate': {label: 'Flat rate', legend: 'A set cost the organisation will reimbursement the driver.'},
          'mileage': {label: 'Mileage', legend: 'Driver submits a breakdown of personal and business miles on the app each month.'},
          'energy-cost': {label: 'Energy cost', legend: 'Exact rate driver pays for home electricity.'}
        }"
        @input="checkDirtyReimbursement"
      />

      <div v-if="reimbursementType === 'flat-rate' && reimbursementSettlement === 'bank'" class="field amount">
        <label for="flat-rate-amount">Flat rate amount</label>
        <CurrencyInput
          id="flat-rate-amount"
          v-model="reimbursementflatRatePerKWh"
          :code="reimbursementflatRatePerKWhCurrency"
          unit-label="per kWh"
          :decimal="4"
          required
          @input="checkDirtyReimbursement"
        />
        <transition name="show-error">
          <label class="error" for="flat-rate-amount" v-if="reimbursementflatRatePerKWhError.length > 0">{{reimbursementflatRatePerKWhError}}</label>
        </transition>
      </div>

      <div class="field actions">
        <button class="action" :disabled="savingReimbursement || !isDirtyReimbursement" type="submit">{{'Save changes'}}</button>
      </div>
    </section></form>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextField from 'elements/TextField'
import CurrencyInput from 'elements/CurrencyInput'
import RadioField from 'elements/RadioField'
import AddressField from 'elements/AddressField'
import SettingsTabs from 'elements/SettingsTabs'
import StripeIBAN from 'elements/StripeIBAN'

export default {
  components: { TextField, RadioField, CurrencyInput, AddressField, StripeIBAN, SettingsTabs },
  data () {
    return {
      showStripeForm: false,
      savingDetails: false,
      savingReimbursement: false,
      isDirtyDetails: false,
      isDirtyReimbursement: false,

      contactName: '',
      contactEmail: '',
      fleetName: '',
      fleetPhone: '',
      fleetAddress: {
        street1: '',
        street2: '',
        city: '',
        postcode: ''
      },

      contactNameError: '',
      contactEmailError: '',
      fleetNameError: '',
      fleetPhoneError: '',
      fleetNameError: '',
      fleetPhoneError: '',
      fleetAddressError: {
        street1: '',
        street2: '',
        city: '',
        postcode: ''
      },

      reimbursementSettlement: '',
      reimbursementType: '',
      reimbursementflatRatePerKWh: 0,
      reimbursementflatRatePerKWhCurrency: 'GBP',

      reimbursementSettlementError: '',
      reimbursementTypeError: '',
      reimbursementflatRatePerKWhError: '',
    }
  },
  created () {
    this.contactName = this.fleetDetails.contactName
    this.contactEmail = this.fleetDetails.contactEmail
    this.fleetName = this.fleetDetails.name
    this.fleetPhone = this.fleetDetails.phone
    this.fleetAddress.street1 = this.fleetDetails.street1
    this.fleetAddress.street2 = this.fleetDetails.street2
    this.fleetAddress.city = this.fleetDetails.city
    this.fleetAddress.postcode = this.fleetDetails.postcode
    
    this.reimbursementSettlement = this.fleetReimbursementSettings.settlementType
    this.reimbursementType = this.fleetReimbursementSettings.reimbursementType
    this.reimbursementflatRatePerKWh = this.fleetReimbursementSettings.flatRatePerKWh.amount
    this.reimbursementflatRatePerKWhCurrency = this.fleetReimbursementSettings.flatRatePerKWh.currency
      || this.fleetCurrencyCode
      || this.currencyCode
  },
  watch: {
    contactName(_, __) { this.contactNameError = '' },
    contactEmail(_, __) { this.contactEmailError = '' },
    fleetName(_, __) { this.fleetNameError = '' },
    fleetPhone(_, __) { this.fleetPhoneError = '' },
    'fleetAddress.street1': function (_, __) { this.fleetAddressError.street1 = '' },
    'fleetAddress.street2': function (_, __) { this.fleetAddressError.street2 = '' },
    'fleetAddress.city': function (_, __) { this.fleetAddressError.city = '' },
    'fleetAddress.postcode': function (_, __) { this.fleetAddressError.postcode = '' },
    reimbursementSettlement(_, __) { this.reimbursementSettlementError = '' },
    reimbursementType(_, __) { this.reimbursementTypeError = '' },
    reimbursementflatRatePerKWh(_, __) { this.reimbursementflatRatePerKWhError = '' },
  },
  computed: {
    ...mapGetters(['currencyCode']),
    ...mapGetters('Fleet', ['fleetCurrencyCode', 'fleetDetails', 'fleetBankDetails', 'fleetReimbursementSettings', 'hasSetup', 'hasFeatures']),
  },
  methods: {
    ...mapActions('Fleet', ['updateFleetDetails', 'updateBankDetails', 'updateReimbursementSettings']),
    ...mapActions('Notifications', ['showSuccessMessage', 'showErrorMessage']),

    // 
    // Contact details
    // 
    checkDirtyDetails () {
      this.isDirtyDetails = this.fleetName != this.fleetDetails.name
        || this.fleetPhone != this.fleetDetails.phone
        || this.contactName != this.fleetDetails.contactName
        || this.contactEmail != this.fleetDetails.contactEmail
        || this.fleetAddress.street1 != this.fleetDetails.street1
        || this.fleetAddress.street2 != this.fleetDetails.street2
        || this.fleetAddress.city != this.fleetDetails.city
        || this.fleetAddress.postcode != this.fleetDetails.postcode
    },
    clearDetailErrors () {
      this.contactNameError = ''
      this.contactEmailError = ''
      this.fleetNameError = ''
      this.fleetPhoneError = ''
      this.fleetAddressError.street1 = ''
      this.fleetAddressError.street2 = ''
      this.fleetAddressError.city = ''
      this.fleetAddressError.postcode = ''
    },
    areDetailFieldsValid () {
      if (this.fleetName.length == 0) this.fleetNameError = 'Please fill in the organisation name'
      if (this.fleetPhone.length == 0) this.fleetPhoneError = 'Please fill in the contact number'
      
      if (this.contactName.length == 0) this.contactNameError = 'Please fill in the contact name'
      if (this.contactEmail.length == 0) this.contactEmailError = 'Please fill in the contact email'

      if (this.fleetAddress.street1.length == 0) this.fleetAddressError.street1 = 'Please fill in the street address'
      if (this.fleetAddress.city.length == 0) this.fleetAddressError.city = 'Please fill in the city'
      if (this.fleetAddress.postcode.length == 0) this.fleetAddressError.postcode = 'Please fill in the postcode'

      return this.fleetNameError.length == 0
        && this.fleetPhoneError.length == 0
        && this.contactNameError.length == 0
        && this.contactEmailError.length == 0
        && this.fleetAddressError.street1.length == 0
        && this.fleetAddressError.street2.length == 0
        && this.fleetAddressError.city.length == 0
        && this.fleetAddressError.postcode.length == 0
    },
    saveDetails () {
      this.clearDetailErrors()

      if (!this.areDetailFieldsValid()) return

      this.savingDetails = true
      this.updateFleetDetails({
        contactName: this.contactName,
        contactEmail: this.contactEmail,
        name: this.fleetName,
        phone: this.fleetPhone,
        street1: this.fleetAddress.street1,
        street2: this.fleetAddress.street2,
        city: this.fleetAddress.city,
        postcode: this.fleetAddress.postcode,

        // Constants
        // country: this.fleetDetails.country,
      })
      .then(() => {
        this.checkDirtyDetails()
        this.showSuccessMessage('Your contact settings were successfully changed')
      })
      .catch((error) => {
        this.showErrorMessage(`Could not save changes: ${error.message}`)
      })
      .finally(() => this.savingDetails = false)
    },

    // 
    // Bank details
    // 
    onIBANSuccess (intent) {
      this.showStripeForm = false
      this.updateBankDetails()
      .then(() => {
        this.showSuccessMessage('Your billing information was successfully updated')
      })
      .catch((error) => {
        this.showErrorMessage(`Could not save changes: ${error.message}`)
      })
    },
    onIBANFailure (error) {
      this.showErrorMessage(`Could not upate your billing information: ${error.message}`)
    },

    // 
    // Reimbursements
    // 
    checkDirtyReimbursement () {
      this.isDirtyReimbursement = 
        this.reimbursementSettlement !== this.fleetReimbursementSettings.settlementType
        || this.reimbursementType !== this.fleetReimbursementSettings.reimbursementType
        || this.reimbursementflatRatePerKWh !== this.fleetReimbursementSettings.flatRatePerKWh.amount
    },

    clearReimbursementErrors () {
      this.reimbursementSettlementError = ''
      this.reimbursementTypeError = ''
      this.reimbursementflatRatePerKWhError = ''
    },

    areReimbursementFieldsValid () {
      if (this.reimbursementSettlement.length == 0) {
        this.reimbursementSettlementError = 'Please choose settlement type'
      }
      else if (this.reimbursementSettlement === 'bank') {
        if (this.reimbursementType.length == 0) {
          this.reimbursementTypeError = 'Please choose reimbursement type'
        }
        else if (this.reimbursementType === 'flat-rate') {
          if (this.reimbursementflatRatePerKWh == 0) {
            this.reimbursementflatRatePerKWhError = 'Please enter the rate'
          }
        }
      }

      return this.reimbursementSettlementError.length == 0
        && this.reimbursementTypeError.length == 0
        && this.reimbursementflatRatePerKWhError.length == 0
    },

    saveReimbursement () {
      this.clearReimbursementErrors()

      if (!this.areReimbursementFieldsValid()) return

      this.savingReimbursement = true
      this.updateReimbursementSettings({
        settlementType: this.reimbursementSettlement,
        reimbursementType: this.reimbursementType,
        flatRatePerKWh: {
          amount: this.reimbursementflatRatePerKWh,
          currency: this.reimbursementflatRatePerKWhCurrency
        }
      })
      .then(() => {
        this.checkDirtyReimbursement()
        this.showSuccessMessage('Reimbursement settings were successfully changed')
      })
      .catch((error) => {
        this.showErrorMessage(`Could not save changes: ${error.message}`)
      })
      .finally(() => this.savingReimbursement = false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main.form .fieldset {
  &.contact .legend { @include icon-location (20, 2) }
  &.billing .legend { @include icon-location (25, 2) }
  &.reimbursement .legend { @include icon-location (13, 3) }

  &.billing {
    display: block;
  }
}

main.form .fieldset.reimbursement {
  .amount {
    flex: 0 1 calc(40% - 16.66px);
  }
}
</style>