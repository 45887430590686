<template>
  <div class="field">
    <label :for="id">{{label}} <Parenthetical v-if="!required" class="hint">Optional</Parenthetical></label>
    <div :class="{legendary, vertical: orientation === 'vertical', horizontal: orientation === 'horizontal'}">
      <label v-for="(label, option, i) in options" class="radio" :key="option">
        <input
          :id="i === 0? id : null"
          :name="id"
          type="radio"
          :required="required"
          :disabled="disabled"
          :class="{erroneous: error.length > 0}"
          :value="option"
          :checked="value === option"
          @change="change" />
        <span v-if="label.label != null && label.legend != null">
          <strong>{{label.label}}</strong>
          <em>{{label.legend}}</em>
        </span>
        <span v-else>
          {{label}}
        </span>
      </label>
    </div>
    <transition name="show-error">
      <label class="error" :for="id" v-if="error.length > 0">{{error}}</label>
    </transition>
  </div>
</template>

<script>
import Parenthetical from 'elements/Parenthetical'

export default {
  components: { Parenthetical },
  props: {
    value: String,
    label: String,
    error: String,
    id: String,
    options: Object,
    required: Boolean,
    disabled: Boolean,
    orientation: {
      type: String,
      validator: function (value) {
        return ['horizontal', 'vertical'].indexOf(value) !== -1
      },
      default: 'horizontal'
    }
  },
  methods: {
    change ($event) {
      this.$emit('input', $event.target.value)
    }
  },
  computed: {
    legendary () {
      const options = Object.values(this.options)

      for (let i = 0; i < options.length; i++) {
        const o = options[i]
        
        if (o.label != null && o.legend != null) {
          return true
        }
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.legendary {
  display: flex;

  &.horizontal > * {
    flex: 0 1 calc(33.33% - 16.66px);
  }
  &.vertical {
    flex-direction: column;
  }
  &.vertical > * {
    + * {
      margin-top: 20px;

      main.form .fieldset & {
        margin-left: 0;
      }
    }
  } 

  label.radio > span {
    &::before, &::after {
      top: 9px;
    }
    strong {
      display: block;
      margin-top: 0px;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: .875rem;
    }
    em {
      display: block;
      font-style: normal;
      font-weight: 300;
    }
  }
}
</style>