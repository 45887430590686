<template>
  <span><span v-for="p in products" :key="p">{{p}}</span></span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    ids: Array
  },
  computed: {
    ...mapGetters('Products', ['getProductById']),
    products () {
      const products = []

      this.ids.forEach(productId => {
        const product = this.getProductById(productId)

        if (product == null) return

        products.push(product.name)
      })

      return products.sort((a, b) => a < b ? -1 : 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

span > span {
  display: inline-block;
  padding: 6px 6px;
  margin-top: -8px;
  margin-bottom: -8px;
  border-radius: 3px;
  border: 2px solid $group-color;
  color: $label-text;
  font-size: .875rem;

  & + * {
    margin-left: 8px;
  }
}
</style>