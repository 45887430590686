<template>
  <div class="wrapper">
    <button :disabled="fleets.length < 2 && !hasFeatures.suborganisations" :class="{fleet: true, open: open}" @click="openDropdown">
      <span class="logo">&nbsp;</span>
      <span class="name">{{fleetName}}</span>
    </button>
    <div @click.self="closeDropdown" v-if="open" class="backdrop" />
    <div v-if="open" class="dropdown">
      <div class="controls">
        <input type="search" placeholder="Search…" v-model.trim="nameFilter">
        <button v-if="hasFeatures.suborganisations" class="action" @click="addFleet">Add organisation</button>
      </div>
      <h2 v-if="visibleFleets.length > 0">Switch currently viewed organisation:</h2>
      <ul v-if="visibleFleets.length > 0" class="fleets">
        <li v-for="f in visibleFleets" :key="f.id">
          <button :class="{fleet: true, selected: f.id === fleetId}" @click="switchFleet(f.id); open = false">
            <span class="logo">&nbsp;</span>
            <span class="name">{{f.name}}</span>
          </button>
        </li>
      </ul>
      <p v-else class="empty">No matching fleets were found.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Portrait from 'elements/Portrait'

export default {
  components: { Portrait },
  data () {
    return {
      open: false,
      nameFilter: ""
    }
  },
  watch: {
    fleetId (to, from) {
      if (to != null) this.showInfoMessage(`Viewing ${this.fleetName} fleet`)
    }
  },
  methods: {
    ...mapActions('Notifications', ['showInfoMessage']),
    openDropdown () {
      if (this.fleets.length > 1) this.open = true
    },
    closeDropdown () {
      this.open = false
    },
    addFleet () {
      this.open = false

      if (this.$route.name != 'new-fleet') {
        this.$router.push({name: 'new-fleet'})
      }
    },
    switchFleet (fleetId) {
      if (fleetId == this.fleetId) return

      let name = this.$route.name
      const params = this.$route.params
      const query = this.$route.query

      if (params.fleetId != null) params['fleetId'] = fleetId

      const sections = [
        'driver-management',
        'driver-stats',
        'driver-groups',
        'charger-management',
        'charger-groups',
        'package-management'
      ]

      matchSection: for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        
        if (name.indexOf(section) !== -1) {
          name = section
          break matchSection
        }
      }

      this.$router.push({name, params, query})
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetName', 'fleetId', 'fleets', 'hasFeatures']),
    visibleFleets () {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.trim().toLowerCase()) !== -1
      }

      return this.fleets.filter(fleet => matches(this.nameFilter, fleet.name))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.wrapper {
  position: relative;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: $transparent-color;
}
.dropdown {
  position: absolute;
  z-index: 10;
  width: 650px;
  padding: 22px;
  border: 1px solid $menu-active-background;
  border-radius: 0 6px 6px 6px;
  background: $overlay-background;
  box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.05);

  .octopus.themed & { border-color: $octopus-menu-active-background }
}

h2 {
  font-size: 0.875rem;
  margin-top: 1.25rem;
}

button.fleet {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 206px;
  padding: 0 14px;
  border: 2px solid $menu-alt-background;
  border-radius: 5px;
  background: $menu-alt-background;
  vertical-align: middle;
  color: $bold-text;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.142857143;
  text-align: left;
  white-space: normal;

  .octopus.themed & { 
    color: $octopus-bold-text;
    border-color: $octopus-menu-alt-background;
    background: $octopus-menu-alt-background;
  }

  .logo {
    flex: 0 0 auto;
    margin: 14px 0;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    background: $default-background;
  }

  .name {
    display: inline-block;
    margin-left: 10px;
    overflow: hidden;
  }

  &.selected {
    border-color: $primary-color;

    .octopus.themed & { border-color: $octopus-primary-color }
  }
  
  .wrapper > & {
    position: relative;
    padding-right: 28px;

    &::after {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -3px;
      display: block;
      border-top: 6px solid $primary-color;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: ""
    }

    &.open {
      background: $menu-active-background;
      border-color: $menu-active-background;
      border-radius: 5px 5px 0 0;

      .octopus.themed & {
        background-color: $octopus-menu-active-background;
        border-color: $octopus-menu-active-background;
      }

      &::after {
        border-top: 0;
        border-bottom: 6px solid $primary-color;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }

    &:disabled {
      & {
        cursor: default;
      }
      &::after {
        display: none;
      }
    }
  }
}

.controls {
  input {
    width: 428px;
    border-width: 2px;
    border-radius: 5px;

    &:last-child {
      width: 100%
    }
  }
  button {
    position: relative;
    min-width: 206px;
    padding: 12px 15px 11px 25px;
    margin-left: 12px;
    font-size: 0.875rem;
    font-weight: 500;

    &::before {
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -0.75rem;
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 1;
      content: "+"
    }
  }
}

.fleets {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 280px;
  margin-top: 6px;
  margin-right: -20px;

  li {
    float: left;
    margin-top: 16px;
    margin-left: 16px;
  }
  li:nth-child(3n - 2) {
    margin-left: 0;
  }
}

.empty {
  text-align: center;
  margin: 38px 0 16px;
  color: $deemphasized-text;
}
</style>
