<template>
  <main>
    <header>
      <h1>Reimbursements</h1>
      <DateFilter class="filter" />
    </header>
    <table class="big" ref="table">
      <thead>
        <tr>
          <td class="textual">Date</td>
          <td class="textual">Driver</td>
          <td class="textual">Status</td>
          <td><abbr title="quantity">Qty</abbr></td>
          <td>Rate</td>
          <td>Amount</td>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>Total:</td>
          <td>
            <Currency v-if="reimbursementsTotal" :value="reimbursementsTotal.amount" :code="reimbursementsTotal.currency" />
            <span v-else>&ndash;</span>
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr v-if="Object.values(reimbursementsByMonth).length == 0">
          <td colspan="6" class="empty">{{'No data to display'}}</td>
        </tr>
        <template v-else v-for="(reimbursements, date) in reimbursementsByMonth">
          <tr v-for="(r, i) in reimbursements" :key="date + '-' + i">
            <td v-if="i === 0" :rowspan="Object.values(reimbursements).length" class="textual"><DateTime :timestamp="parseInt(date, 10)" format='LLLL yyyy' /></td>

            <td :class="{error: r.status === 'failed', textual: true}"><Portrait :src="null" class="portrait" /> {{r.name}}</td>
            <td :class="{error: r.status === 'failed', textual: true}"><span class="status">{{r.status}}</span> <DateTime :timestamp="r.timestamp" format="'on 'LLLL dd' at 'HH:mm" /></td>
            <td :class="{error: r.status === 'failed'}"><Amount :value="r.quantity" type="energy" /></td>
            <td :class="{error: r.status === 'failed'}">
              <Currency :value="r.ratePerKWh.amount" :code="r.ratePerKWh.currency" :minor="true" :decimal="1" denominator="kWh" />
            </td>
            <td :class="{error: r.status === 'failed'}">
              <Currency class="total" :value="r.amount.amount" :code="r.amount.currency" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DateFilter from 'elements/DateFilter'
import DateTime from 'elements/DateTime'
import Portrait from 'elements/Portrait'
import Amount from 'elements/Amount'
import Currency from 'elements/Currency'

export default {
  components: { DateFilter, DateTime, Portrait, Amount, Currency },
  computed: {
    ...mapGetters('Reimbursements', ['reimbursementsByMonth', 'reimbursementsTotal']),
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

td[rowspan] {
  vertical-align: top;
}
td[rowspan] + td {
  padding-left: 0;
}
.portrait {
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.status {
  text-transform: capitalize;
  font-weight: 500;
  color: $bold-text;

}
.total {
  font-weight: 500;
  color: $bold-text;
}

td.error {
  background: $error-background;

  .status, .total {
    color: $error-color;
  }
}

</style>