<template>
  <nav>
    <h1 class="logo">Ohme Partner Portal</h1>

    <FleetSwitcher />

    <h2 v-if="false" class="dashboard"><router-link :to="{name: 'dashboard'}">Dashboard</router-link></h2>

    <h2 v-if="hasFeatures.drivers || hasFeatures.chargers || hasFeatures.recurringProducts || hasFeatures.paygProducts" class="management"><router-link :to="{name: 'management'}">Management</router-link></h2>
    <ul>
      <li v-if="hasFeatures.drivers"><router-link :to="{name: 'driver-root'}">Driver management</router-link></li>
      <li v-if="hasFeatures.chargers"><router-link :to="{name: 'charger-root'}">Charger management</router-link></li>
      <li v-if="hasFeatures.recurringProducts || hasFeatures.paygProducts"><router-link :to="{name: 'package-management'}">Package management</router-link></li>
    </ul>

    <h2 v-if="hasFeatures.drivers" class="statistics"><router-link :to="{name: 'stats'}">Statistics</router-link></h2>
    <ul v-if="hasFeatures.drivers">
      <li><router-link :to="{name: 'fleet-stats'}">Fleet statistics</router-link></li>
      <li><router-link :to="{name: 'driver-stats'}">Driver statistics</router-link></li>
      <li v-if="hasFeatures.quicksight"><router-link :to="{name: 'charger-stats'}">Charger statistics</router-link></li>
    </ul>

    <h2 class="administration"><router-link :to="{name: 'administration'}">Administration</router-link></h2>
    <ul>
      <li><router-link :to="{name: 'admin-management'}">Managers</router-link></li>
      <li v-if="hasFeatures.reimbursements"><router-link :to="{name: 'reimbursements'}">Reimbursements</router-link></li>
      <li v-if="hasFeatures.reimbursements"><router-link :to="{name: 'transactions'}">Transactions</router-link></li>
      <li><router-link :to="{name: 'settings'}">Settings</router-link></li>
    </ul>

    <h2 class="feedback"><a href="mailto:help@ohme-ev.com">Feedback</a></h2>

    <div class="account">
      <Portrait :src="photo" />
      <span class="name">{{ name || email }}</span>
      <button @click="logout">Logout</button>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import * as firebase from 'firebase/app'
import 'firebase/auth'

import Portrait from 'elements/Portrait'
import FleetSwitcher from 'elements/FleetSwitcher'

export default {
  components: { Portrait, FleetSwitcher },
  methods: {
    ...mapActions('Notifications', ['showSuccessMessage']),
    logout () {
      firebase.auth().signOut()
      this.$router.push('/authentication')
      this.showSuccessMessage('You’ve been logged out successfully')
    }
  },
  computed: {
    ...mapState('Account', ['name', 'email', 'photo']),
    ...mapGetters('Fleet', ['hasFeatures']),
    ...mapGetters(['theme'])
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

nav {
  box-sizing: border-box;
  padding: 14px;
}

.logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 70px;
  width: $menu-width;
  background: $primary-color;
  text-indent: -999em;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -42.5px;
    margin-top: -13.5px;
    display: block;
    width: 85px;
    height: 27px;
    background: url(/images/logo.png) no-repeat 0 0;
    background-size: 100% 100%;
    content: '';
  }
}

h2 {
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  margin: 40px 0 20px;
  padding-left: 40px;
  padding-right: 0;

  a {
    @include icon-left(0, 0);
    &::before {
      left: 4px;
    }
  }

  &.dashboard a { @include icon-location(0, 0) }
  &.management a { @include icon-location(2, 0) }
  &.statistics a { @include icon-location(1, 0) }
  &.administration a { @include icon-location(30, 0) }
  &.feedback a { @include icon-location(31, 2) }

  &.dashboard a.current { @include icon-location(0, 1) }
  &.management a.current { @include icon-location(2, 1) }
  &.statistics a.current { @include icon-location(1, 1) }
  &.administration a.current { @include icon-location(30, 1) }
  &.feedback a:hover, &.feedback a:focus { @include icon-location(31, 3) }
}

ul {
  font-size: .875rem;
  margin: 0;
  padding-left: 40px;
  padding-right: 0;

  li {
    margin: 14px 0;
  }
}

a {
  text-decoration: none;

  &:hover, &:focus {
    color: $primary-color;
    .octopus.themed & { color: $octopus-menu-link }
  }

  &.current {
    color: $primary-color;
    .octopus.themed & { color: $octopus-menu-link }
  }

  h2 & {
    color: $default-text;
    .octopus.themed & { color: $octopus-menu-text }

    &.current {
      font-weight: 500;
    }
  }

  ul & {
    color: $menu-text;
    .octopus.themed & { color: $octopus-menu-text }
  }
}

.account {
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: $menu-width;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 20px;
  background: $menu-alt-background;
  color: $default-text;

  .octopus.themed & {
    background-color: $octopus-menu-alt-background;
    color: $octopus-default-text;
  }

  .name {
    display: inline-block;
    width: 120px;
    font-size: .875rem;
    font-weight: bold;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    @include button (3, 1);

    &:hover, &:focus {
      @include icon-location(3, 0);
    }
  }
}
</style>