import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Global from './Global'
import Fleet from './Fleet'
import Notifications from './Notifications'
import Groups from './Groups'
import Account from './Account'
import Users from './Users'
import Admins from './Admins'
import Stats from './Stats'
import Chargers from './Chargers'
import Products from './Products'
import Reimbursements from './Reimbursements'

const store = new Vuex.Store({
  modules: {
    Global,
    Fleet,
    Notifications,
    Groups,
    Account,
    Users,
    Admins,
    Stats,
    Chargers,
    Products,
    Reimbursements,
  },
  // strict: process.env.NODE_ENV !== 'production'
  strict: false
})

export default store