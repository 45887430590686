import Vue from 'vue'
import { inviteAdmin } from '~/api'

export const state = {
  admins: {
    // "id": {
    //  id: "",
    //  active: true,
    //  invited: true | false,
    //  name: "",
    //  email: ""
    // }
  }
}

export const getters = {
  admins: state => Object.values(state.admins).sort((a, b) => a.name < b.name ? -1 : 1),
  getAdminById: state => id => state.admins[id],
}

export const mutations = {
  reset (state) {
    state.admins = {}
  },
  addAdmin (state, admin) {
    Vue.set(state.admins, admin.id, admin)
  },
  patchAdmin (state, admin) {
    const old = state.admins[admin.id]

    if (old == undefined) {
      Vue.set(state.admins, admin.id, admin)
    }
    else for (let prop in admin) {
      if (admin.hasOwnProperty(prop)) Vue.set(old, prop, admin[prop])
    }
  },
}

export const actions = {
  inviteAdmin ({ rootGetters, dispatch }, data) {
    return inviteAdmin(rootGetters['Fleet/fleetId'], data).then(() => dispatch('reloadAdmins', null, { root: true }))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}