<template>
  <main>
    <header>
      <h1>Fleet statistics</h1>
    </header>

    <StatisticsTabs @change="changeTable" />

    <table class="big wide">
      <thead>
        <tr>
          <td class="textual"><button @click="sort('month')">Month</button></td>
          <template v-if="table === 'cost'">
            <td colspan="2"><button :class="{ sorted: sortColumn === 'cost', ascending: sortAscending }" @click="sort('cost')">Total cost</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'cpc', ascending: sortAscending }" @click="sort('cpc')">Per car</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'cpd', ascending: sortAscending }" @click="sort('cpd')">Per {{units.distanceSingular}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'cp', ascending: sortAscending }" @click="sort('cp')">Business / Personal</button></td>
            <td v-if="isCorporate" colspan="3" class="balance"><button :class="{ sorted: sortColumn === 'cbhp', ascending: sortAscending }" @click="sort('cbhp')">Charged at Work / Home / Public</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'cice', ascending: sortAscending }" @click="sort('cice')">Saved vs <abbr title="Internal Combustion Engine">ICE</abbr></button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'cohme', ascending: sortAscending }" @click="sort('cohme')">Ohme savings</td>
          </template>
          <template v-if="table === 'energy'">
            <td colspan="2"><button :class="{ sorted: sortColumn === 'energy', ascending: sortAscending }" @click="sort('energy')">Total energy</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'epc', ascending: sortAscending }" @click="sort('epc')">Per car</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'epd', ascending: sortAscending }" @click="sort('epd')">Per {{units.distanceSingular}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'ep', ascending: sortAscending }" @click="sort('ep')">Business / Personal</button></td>
            <td v-if="isCorporate" colspan="3" class="balance"><button :class="{ sorted: sortColumn === 'ebhp', ascending: sortAscending }" @click="sort('ebhp')">Charged at Work / Home / Public</button></td>
          </template>
          <template v-if="table === 'estimate_distance'">
            <td colspan="2"><button :class="{ sorted: sortColumn === 'distance', ascending: sortAscending }" @click="sort('distance')">Total {{units.distancePlural}}</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'dpc', ascending: sortAscending }" @click="sort('dpc')">Per car</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'dp', ascending: sortAscending }" @click="sort('dp')">Business / Personal</button></td>
          </template>
          <template v-if="table === 'reported_distance' && isCorporate">
            <td colspan="2"><button :class="{ sorted: sortColumn === 'reported_distance', ascending: sortAscending }" @click="sort('reported_distance')">Total {{units.distancePlural}}</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'rdpc', ascending: sortAscending }" @click="sort('rdpc')">Per car</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'rdp', ascending: sortAscending }" @click="sort('rdp')">Business / Personal</button></td>
          </template>
          <template v-if="table === 'co2'">
            <td colspan="2"><button :class="{ sorted: sortColumn === 'co2', ascending: sortAscending }" @click="sort('co2')">CO2 emissions</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'spc', ascending: sortAscending }" @click="sort('spc')">Per car</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'spd', ascending: sortAscending }" @click="sort('spd')">Per {{units.distanceSingular}}</button></td>
            <td v-if="isCorporate" colspan="2" class="balance"><button :class="{ sorted: sortColumn === 'sp', ascending: sortAscending }" @click="sort('sp')">Business / Personal</button></td>
            <td v-if="isCorporate" colspan="3" class="balance"><button :class="{ sorted: sortColumn === 'sbhp', ascending: sortAscending }" @click="sort('sbhp')">Charged at Work / Home / Public</button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'sice', ascending: sortAscending }" @click="sort('sice')">Saved vs <abbr title="Internal Combustion Engine">ICE</abbr></button></td>
            <td colspan="2"><button :class="{ sorted: sortColumn === 'sohme', ascending: sortAscending }" @click="sort('sohme')">Ohme savings</td>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loadingStats">
          <td :colspan="
              table === 'cost'? (isCorporate ? 16 : 11) :
              table === 'energy'? (isCorporate ? 12 : 7) :
              table === 'estimate_distance'? (isCorporate ? 7 : 5) :
              table === 'reported_distance'? (isCorporate ? 7 : 5) :
              table === 'co2'? (isCorporate ? 16 : 11) : 0
            " class="empty">
            <Loading />
          </td>
        </tr>        
        <tr v-else-if="fleetStats.length === 0">
          <td :colspan="
              table === 'cost'? (isCorporate ? 16 : 11) :
              table === 'energy'? (isCorporate ? 12 : 7) :
              table === 'estimate_distance'? (isCorporate ? 7 : 5) :
              table === 'reported_distance'? (isCorporate ? 7 : 5) :
              table === 'co2'? (isCorporate ? 16 : 11) : 0
            " class="empty">{{'No data to display'}}</td>
        </tr>
        <tr v-else v-for="s in fleetStats" :key="s.month">
          <th class="textual">{{s.month | decapitalize}}</th>
          <template v-if="table === 'cost'">
            <td class="amount"><Amount :value="s.cost.total" type="currency" /></td>
            <td class="change"><Change :value="s.cost.totalChange" /></td>
            <td class="amount"><Amount :value="s.cost.perCar" type="currency" /></td>
            <td class="change"><Change :value="s.cost.perCarChange" /></td>
            <td class="amount"><Amount :value="s.cost.perDistance" type="currency_small" /></td>
            <td class="change"><Change :value="s.cost.perDistanceChange" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.cost.totalBusiness" /></td>
            <td v-if="isCorporate" class="balance last"><Share type="personal" :value="s.cost.totalPersonal" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.cost.totalWorkCharge" /></td>
            <td v-if="isCorporate" class="balance"><Share type="home" :value="s.cost.totalHomeCharge" /></td>
            <td v-if="isCorporate" class="balance last"><Share type="public" :value="s.cost.totalDestinationCharge" /></td>
            <td class="amount"><Amount :value="s.cost.iceSavings" type="currency" /></td>
            <td class="change"><Change :value="s.cost.iceSavingsChange" reverse /></td>
            <td class="amount"><Amount :value="s.cost.ohmeSavings" type="currency" /></td>
            <td class="change"><Change :value="s.cost.ohmeSavingsChange" reverse /></td>
          </template>
          <template v-if="table === 'energy'">
            <td class="amount"><Amount :value="s.energy.total" type="energy" /></td>
            <td class="change"><Change :value="s.energy.totalChange" /></td>
            <td class="amount"><Amount :value="s.energy.perCar" type="energy" /></td>
            <td class="change"><Change :value="s.energy.perCarChange" /></td>
            <td class="amount"><Amount :value="s.energy.perDistance" type="energy" /></td>
            <td class="change"><Change :value="s.energy.perDistanceChange" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.energy.totalBusiness" unit="energy" /></td>
            <td v-if="isCorporate" class="balance last"><Share type="personal" :value="s.energy.totalPersonal" unit="energy" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.energy.totalWorkCharge" unit="energy" /></td>
            <td v-if="isCorporate" class="balance"><Share type="home" :value="s.energy.totalHomeCharge" unit="energy" /></td>
            <td v-if="isCorporate" class="balance last"><Share type="public" :value="s.energy.totalDestinationCharge" unit="energy" /></td>
          </template>
          <template v-if="table === 'estimate_distance'">
            <td class="amount"><Amount :value="s.distance.total" type="distance" /></td>
            <td class="change"><Change :value="s.distance.totalChange" /></td>
            <td class="amount"><Amount :value="s.distance.perCar" type="distance" :decimal="1" /></td>
            <td class="change"><Change :value="s.distance.perCarChange" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.distance.totalBusiness" unit="distance" /></td>
            <td v-if="isCorporate" class="balance"><Share type="personal" :value="s.distance.totalPersonal" unit="distance" /></td>
          </template>
          <template v-if="table === 'reported_distance' && isCorporate">
            <td class="amount"><Amount :value="s.reportedDistance.total" type="distance" /></td>
            <td class="change"><Change :value="s.reportedDistance.totalChange" /></td>
            <td class="amount"><Amount :value="s.reportedDistance.perCar" type="distance" :decimal="1" /></td>
            <td class="change"><Change :value="s.reportedDistance.perCarChange" /></td>
            <td v-if="isCorporate" class="balance business"><Share type="business" :value="s.reportedDistance.totalBusiness" unit="distance" /></td>
            <td v-if="isCorporate" class="balance personal"><Share type="personal" :value="s.reportedDistance.totalPersonal" unit="distance" /></td>
          </template>
          <template v-if="table === 'co2'">
            <td class="amount"><Amount :value="s.co2.total" type="weight" /></td>
            <td class="change"><Change :value="s.co2.totalChange" /></td>
            <td class="amount"><Amount :value="s.co2.perCar" type="weight" :decimal="1" /></td>
            <td class="change"><Change :value="s.co2.perCarChange" /></td>
            <td class="amount"><Amount :value="s.co2.perDistance" type="weight" :decimal="1" /></td>
            <td class="change"><Change :value="s.co2.perDistanceChange" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.co2.totalBusiness" unit="weight" /></td>
            <td v-if="isCorporate" class="balance last"><Share type="personal" :value="s.co2.totalPersonal" unit="weight" /></td>
            <td v-if="isCorporate" class="balance"><Share type="business" :value="s.co2.totalWorkCharge" unit="weight" /></td>
            <td v-if="isCorporate" class="balance"><Share type="home" :value="s.co2.totalHomeCharge" unit="weight" /></td>
            <td v-if="isCorporate" class="balance last"><Share type="public" :value="s.co2.totalDestinationCharge" unit="weight" /></td>
            <td class="amount"><Amount :value="s.co2.iceSavings" type="weight" /></td>
            <td class="change"><Change :value="s.co2.iceSavingsChange" reverse /></td>
            <td class="amount"><Amount :value="s.co2.ohmeSavings" type="weight" /></td>
            <td class="change"><Change :value="s.co2.ohmeSavingsChange" reverse /></td>
          </template>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import StatisticsTabs from 'elements/StatisticsTabs'
import Portrait from 'elements/Portrait'
import Amount from 'elements/Amount'
import Share from 'elements/Share'
import Change from 'elements/Change'
import DateFilter from 'elements/DateFilter'
import Loading from "elements/SVGs/Loading";

import { SortableStats } from '~/mixins'

export default {
  mixins: [SortableStats],
  components: { StatisticsTabs, Portrait, Amount, Share, Change, DateFilter, Loading },
  data () {
    return {
      table: 'cost',
    }
  },
  computed: {
    ...mapGetters(['units']),
    ...mapGetters('Fleet', ['fleetName', 'isCorporate']),
    ...mapGetters('Stats', ['fleetMonthly']),
    ...mapState('Stats', ['loadingStats']),
    fleetStats () {
      return this.fleetMonthly.map(s => s).sort(this.order)
    }
  },
  methods: {
    changeTable (table) {
      this.table = table
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

table thead td:first-child button::before {
  display: none;
}

table th {
  text-transform: capitalize;
}
</style>
