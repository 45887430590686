<template>
  <div v-if="!multiple && (!hideEmpty || groups.length > 0)" class="single">
    <select
      :id="id"
      :required="required"
      :disabled="disabled || groups.length === 0"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
      <option></option>
      <option v-for="g in groups" :key="g.id" :value="g.id" v-html="asciiHierachy(g) + g.name" />
    </select>
    <span v-if="groups.length < 1" class="placeholder empty">No {{type == 'user' ? 'driver' : 'charger'}} group exists yet</span>
    <span v-else-if="value == '' || value == null" class="placeholder">{{placeholder}}</span>
    <span v-else class="value">{{selectedGroupName}}</span>
  </div>
  <div v-else-if="!hideEmpty || groups.length > 0" :class="{multiselect: true, disabled}">
    <span v-if="groups.length < 1" class="empty">No {{type == 'user' ? 'driver' : 'charger'}} group exists yet</span>
    <div v-else class="list">
      <label v-for="g in groups" :key="g.id"><input type="checkbox" 
        :disabled="disabled"
        :value="g.id"
        :checked="value.indexOf(g.id) !== -1"
        @input="change($event)"><span><span v-for="l in g.level" :key="l" :class="{
          spacer: true,
          vertical: g.parents[l],
          connected: l === g.level,
          last: l === g.level && g.last
        }">&nbsp;</span>{{g.name}}</span></label>
      <p v-if="groups.length > 0 && !disabled" class="count">{{selectedCount}} group{{selectedCount === 1? '' : 's'}} selected</p>
    </div>
  </div>
</template>

<script>
const RENDER_ASCII = true

import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['user', 'device'].indexOf(value) !== -1
      },
      required: true
    },
    multiple: Boolean,
    hideEmpty: {
      type: Boolean,
      default: false
    },
    value: String | Array,
    exclude: String | Array,
    
    id: String,
    required: Boolean,
    disabled: Boolean,
    placeholder: String,
  },
  computed: {
    ...mapGetters('Groups', ['userGroups', 'deviceGroups', 'getGroupById', 'rootUserGroup', 'rootDeviceGroup']),
    selectedGroupName () {
      if (this.multiple) return ''

      const group = this.getGroupById(this.value)

      if (group == null) return ''

      return group.name
    },
    selectedCount () {
      if (!this.multiple) return ''

      let count = this.value.length

      this.value.forEach(id => {
        if (id === this.rootUserGroup.id || id === this.rootDeviceGroup.id) {
          count--
        }
      })

      return count
    },
    groups () {
      const excludedIds = this.exclude instanceof Array? this.exclude : [this.exclude]
      const groups = this.type === 'user' ? this.userGroups : this.deviceGroups
      const results = []

      let cutoff = 999

      groups.forEach(g => {
        if (excludedIds.indexOf(g.id) !== -1) {
          // Skip the item itself
          cutoff = g.level
        }
        else if (g.level > cutoff) {
          // Skip all its children
        }
        else {
          cutoff = 999
          results.push(g)
        }
      })
      
      return results
    },
  },
  methods: {
    asciiHierachy (group) {
      const ascii = []

      for (let l = 1; l <= group.level; l++) {
        ascii.push(!RENDER_ASCII ? '&nbsp;&nbsp;&nbsp;' :
          l === group.level && group.last? '└&nbsp;' :
          l === group.level? '├&nbsp;' :
          group.parents[l] === true? '│&nbsp;' : '&nbsp;&nbsp;&nbsp;&nbsp;'
        )
      }

      return ascii.join('')
    },
    change(e) {
      const values = this.value
      const id = e.target.value
      let i

      if (e.target.checked) {
        values.push(id)
      }
      else if ((i = values.indexOf(id)) !== -1) {
        values.splice(i, 1)
      }

      this.$emit('input', values)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

div.single {
  position: relative;

  .value, .placeholder {
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 3px;
    right: 26px;
    z-index: 1;
    display: block;
    padding: 9px 13px;
    background: $default-background;
    text-align: left;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    color: $placeholder-color;
  }

  .filters & .value, .filters & .placeholder {
    padding: 2px 0 2px 6px;
    right: 21px;
  }
}

div.multiselect {
  position: relative;
  border: 1px solid $border-color;

  .field & {
    box-sizing: border-box;
    border: 2px solid $input-border-color;
    border-radius: 4px;
  }

  > .empty {
    display: block;
    padding: 3px 0 2px;
    margin: (8em / 0.875 / 2) - (1.25 * 1em / 2) 0;
    font-size: .875rem;
    font-weight: 500;
    text-align: center;
    color: $deemphasized-text;
  }

  .list {
    height: 7em;
    overflow: scroll;
    overflow-x: hidden;
    padding-bottom: 21px;

    .count {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 3px 5px;
      background: $dark-overlay-color;
      font-size: 0.75rem;
    }
  }
  &.disabled > .list {
    overflow: hidden;
  }

  .list > label {
    overflow: hidden;
    display: block;
    color: $label-text;
    font-size: .875rem;
    font-weight: 400;
    cursor: pointer;

    > input {
      position: absolute;
      left: -999em;
    }

    > span {
      position: relative;
      display: block;
      padding: 3px 10px;
      padding-left: 28px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include icon-left (23, 0);
      &::before {
        left: -2px;
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 14px;
        display: none;
        box-sizing: border-box;
        margin-left: -9px;
        margin-top: -9px;
        width: 18px;
        height: 18px;
        border: 2px solid $outline-color;
        border-radius: 2px;
        content: "";
      }
    }

    &:hover > span, > input:focus + span { @include icon-location (23, 1) }
    input:focus + span::after { display: block; }
    input:disabled + span::before { opacity: 0.65 }
    input:disabled + span { color: $inactive-text }
    &:hover input:disabled + span { @include icon-location (23, 0) }
    > input:checked + span,
    &:hover input:disabled:checked + span { @include icon-location (23, 2) }
  }

  $graph-color: $border-color;
  $graph-width: 2px;
  $tab-width: 10px;
  $cell-height: 17px;
  $middle-point: $cell-height / 2 - ($graph-width / 2);
  $left-line-offset: 3px - ($graph-width / 2);
  $right-line-offset: 3px - ($graph-width / 2);

  .spacer {
    position: relative;
    display: inline-block;
    width: $tab-width;
    height: $cell-height;
    padding: 0;

    &.vertical::before {
      position: absolute;
      top: -3px;
      left: $left-line-offset;
      bottom: -3px;
      border-left: $graph-width solid $graph-color;
      content: "";
    }
    &.last::before {
      bottom: $middle-point;
    }
    &.last::after, &.connected::after {
      position: absolute;
      top: $middle-point;
      left: $left-line-offset;
      right: $right-line-offset;
      border-top: $graph-width solid $graph-color;
      content: "";
    }
    &.connected::after {
      right: $right-line-offset;
    }
  }
}
</style>