<template>
  <main>
    <header>
      <h1>Package management</h1>

      <div class="actions">
        <router-link class="action" tag="button" :to="{name: 'package-management-add-package'}">Add package</router-link>
      </div>
    </header>

    <table class="big full-width controlled" ref="table">
      <thead>
        <tr>
          <td class="textual">Package name</td>
          <td>Cost</td>
          <td class="textual">Period</td>
          <td>Rate</td>
          <td>Plug-in fee</td>
          <td class="textual">Driver groups</td>
          <td class="textual">Charger groups</td>
        </tr>
      </thead>
      <tbody ref="table-body">
        <tr class="filters">
          <td class="textual"><input type="search" :value="nameFilter" @input="changeNameFilter" placeholder="Search…" /></td>
          <td colspan="4">&nbsp;</td>
          <td class="textual"><GroupSelect hide-empty type="user" v-model="userGroupFilter" placeholder="Select…" /></td>
          <td class="textual"><GroupSelect hide-empty type="device" v-model="deviceGroupFilter" placeholder="Select…" /></td>
        </tr>
        <tr v-if="visibleProducts.length == 0">
          <td colspan="7" class="empty">{{isFiltered? 'No records matching the criteria were found' : 'No data to display'}}</td>
        </tr>
        <tr v-else v-for="(p, i) in visibleProducts" :key="p.id">
          <th><Abbreviated middle :value="p.name" :max-length="27" :to="{name: 'package-management-package-details', params: {productId: p.id}}" /></th>
          <template v-if="isRowHidden(i)">
            <td colspan="6">&nbsp;</td>
          </template>
          <template v-else>
            <td v-if="p.type === 'recurring'"><Currency :value="p.recurringFee.amount" :code="p.recurringFee.currency" /></td>
            <td v-else>&nbsp;</td>
            <td class="textual">{{p.recurringPeriod}}</td>
            <td><Currency :value="p.pricePerKWh.amount" :code="p.pricePerKWh.currency" :minor="true" :decimal="2" denominator="kWh" /></td>
            <td><Currency :value="p.plugInFee.amount" :code="p.plugInFee.currency" /></td>
            <td class="textual groups">
              <GroupTags baseRoute="package-management" :ids="p.groupIds" type="user" :collapsed="true">
            </td>
            <td class="textual groups">
              <GroupTags baseRoute="package-management" class="last" :ids="p.groupIds" type="device" :collapsed="true">
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <transition name="modal"
      @before-leave="beforeModalLeaves"
      @before-enter="beforeModalEnters"
      @enter="whenModalEnters"
      @leave="whenModalLeaves"
      @after-leave="afterModalLeaves"
      @after-enter="afterModalEnters"
    >
      <router-view />
    </transition>
  </main>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters } from 'vuex'

import Abbreviated from 'elements/Abbreviated'
import GroupSelect from 'elements/GroupSelect'
import Amount from 'elements/Amount'
import Currency from 'elements/Currency'
import GroupTags from 'elements/GroupTags'

import { HiddenRows, ModalOwner } from '~/mixins'

export default {
  mixins: [HiddenRows, ModalOwner],
  components: { Abbreviated, Amount, GroupSelect, Currency, GroupTags },
  data () {
    return {
      nameFilter: null,
      deviceGroupFilter: null,
      userGroupFilter: null,
    }
  },
  computed: {
    ...mapGetters('Fleet', ['fleetName']),
    ...mapGetters('Products', ['products']),
    ...mapGetters('Groups', ['userGroupFilterIds', 'deviceGroupFilterIds']),
    visibleProducts () {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.trim().toLowerCase()) !== -1
      }
      const userGroupIds = this.userGroupFilterIds(this.userGroupFilter)
      const deviceGroupIds = this.deviceGroupFilterIds(this.deviceGroupFilter)

      return this.products.filter(p => {
        let pass = true

        if (this.nameFilter != null) pass &= matches(this.nameFilter, p.name)
        if (this.userGroupFilter) pass &= p.groupIds.reduce((result, id) => result || userGroupIds.indexOf(id) != -1, false)
        if (this.deviceGroupFilter) pass &= p.groupIds.reduce((result, id) => result || deviceGroupIds.indexOf(id) != -1, false)

        return pass
      })
    },
    isFiltered () {
      return this.nameFilter != null
        || this.userGroupFilter
        || this.deviceGroupFilter
    },
  },
  methods: {
    changeNameFilter: debounce(function (e) { this.nameFilter = e.target.value }, 300),
    changeUserGroupFilter: debounce(function (e) { this.userGroupFilter = e.target.value }, 300),
    changeDeviceGroupFilter: debounce(function (e) { this.deviceGroupFilter = e.target.value }, 300),
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

main { 
  .octopus.themed & {
    header {
      margin-bottom: 100px;
    }
  }
}
</style>