<template>
  <main>
    <header>
      <h1>Managers</h1>

      <div class="actions">
        <router-link class="action" tag="button" :to="{name: 'admin-management-add-manager'}">Add manager</router-link>
      </div>
    </header>

    <table class="big controlled">
      <thead>
        <tr>
          <td class="textual">Name</td>
          <td class="textual">Email</td>
        </tr>
      </thead>
      <tbody>
        <tr class="filters">
          <td><input type="search" :value="nameFilter" @input="changeNameFilter" placeholder="Search…" /></td>
          <td><input type="search" :value="emailFilter" @input="changeEmailFilter" placeholder="Search…" /></td>
        </tr>
        <tr v-if="visibleAdmins.length == 0">
          <td :colspan="2" class="empty">{{isFiltered? 'No records matching the criteria were found' : 'No data to display'}}</td>
        </tr>
        <tr v-else v-for="a in visibleAdmins" :key="a.id" :class="{ inactive: !a.active, invited: a.invited }">
          <td class="textual user">
            {{a.name}}
            <Parenthetical v-if="a.invited" class="badge">invited</Parenthetical>
            <Parenthetical v-else-if="!a.active" class="badge">inactive</Parenthetical>
          </td>
          <td class="textual">{{a.email}}</td>
        </tr>
      </tbody>
    </table>

    <transition name="modal"
      @before-leave="beforeModalLeaves"
      @before-enter="beforeModalEnters"
      @enter="whenModalEnters"
      @leave="whenModalLeaves"
      @after-leave="afterModalLeaves"
      @after-enter="afterModalEnters"
    >
      <router-view />
    </transition>
  </main>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters } from 'vuex'

import Parenthetical from 'elements/Parenthetical'
import { ModalOwner } from '~/mixins'

export default {
  mixins: [ModalOwner],
  components: { Parenthetical },
  data () {
    return {
      nameFilter: null,
      emailFilter: null,
    }
  },
  computed: {
    ...mapGetters('Admins', ['admins']),
    visibleAdmins () {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.toLowerCase()) !== -1
      }

      return this.admins.filter(admin => {
        let pass = true

        if (this.nameFilter != null) pass &= matches(this.nameFilter, admin.name)
        if (this.emailFilter != null) pass &= matches(this.emailFilter, admin.email)

        return pass
      })
    },
    isFiltered () {
      return this.nameFilter != null && this.nameFilter.length > 0 ||
        this.emailFilter != null && this.emailFilter.length > 0
    },
  },
  methods: {
    changeNameFilter: debounce(function (e) { this.nameFilter = e.target.value }, 300),
    changeEmailFilter: debounce(function (e) { this.emailFilter = e.target.value }, 300),
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

table tbody .inactive, table tbody .invited {
  td, th {
    color: $inactive-text;
  }
}

</style>