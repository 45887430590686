export const rootAPI = {
  baseURL: 'https://api-beta.ohme.io/',
}

export const APIv1 = {
  baseURL: rootAPI.baseURL + 'v1/',
}

export const stripe = {
  apiKey: 'pk_test_51IsrAdKCChXaqnoZdXKAufNTfiFBCDncHks2HC7GtrKuRRAXRN279plXEZqb34ty92nrbqdBSIbZ46H0uYSKa0qh00PAVuOkRi',
}

export const firebase = {
  apiKey: 'AIzaSyCeBO_09CK-8EAFBe8N5jA_hVP-Ik-YLPA',
  authDomain: 'ohme-prod.firebaseapp.com',
  databaseURL: 'https://ohme-prod.firebaseio.com',
  projectId: 'ohme-prod',
  storageBucket: 'ohme-prod.appspot.com',
  messagingSenderId: '206163667850',
  appId: '1:206163667850:web:05a242df77b45f59503673',
  measurementId: 'G-WSY0S31MJ7'
}

export const quickstatBase = 'https://fleet-dashboard-beta.ohme-ev.com/'
