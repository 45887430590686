<template>
  <main v-if="hasFeatures.quicksight">
    <header>
      <h1>Charger statistics</h1>
    </header>

    <iframe id="stats-frame" :src="frameSource" frameborder="0"></iframe>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import { authToken } from '~/api'
import { quickstatBase } from '~/config'

export default {
  data () {
    return {
      token: null
    }
  },
  created () {
    authToken()
    .then(t => this.token = t)
  },
  computed: {
    ...mapGetters('Fleet', ['hasFeatures', 'fleetId']),
    frameSource () {
      return `${quickstatBase}?SessionTags=filter_id:${this.fleetId}&type=fleet&access_token=${this.token}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

#stats-frame {
  position: fixed;
  top: (30px + 40px);
  left: $menu-width;
  right: 0;
  bottom: 0;
  width: calc(100% - #{$menu-width});
  height: calc(100% - 30px - 40px);
}
</style>
