<template>
  <div class="field">
    <label :for="id">{{label}} <Parenthetical v-if="!required" class="hint">Optional</Parenthetical></label>
    <input 
      :id="id"
      :type="type"
      :required="required"
      :disabled="disabled"
      :class="{erroneous: error.length > 0}"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)" />
    <transition name="show-error">
      <label class="error" :for="id" v-if="error.length > 0">{{error}}</label>
    </transition>
    <slot />
  </div>
</template>

<script>
import Parenthetical from 'elements/Parenthetical'

export default {
  components: {Parenthetical},
  props: {
    value: String,
    label: String,
    error: String,
    id: String,
    type: {
      type: String,
      validator: function (value) {
        return ['text', 'email', 'password', 'date', 'number'].indexOf(value) !== -1
      }
    },
    required: Boolean,
    disabled: Boolean,
    placeholder: String,
  }
}
</script>