<template>
  <div class="scroller">
    <button class="previous" v-show="hasPrevious" @click="goPrevious">Previous</button>
    <slot />
    <button class="next" v-show="hasNext" @click="goNext">Next</button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      content: null,
      offset: 0,
      contentWidth: 0,
      slideWidth: 0,
    }
  },
  mounted () {
    this.content = this.$el.querySelector(':not(button)')
    this.measureDimensions()
    window.addEventListener('resize', this.measureDimensions)
  },
  destroyed () {
    window.removeEventListener('resize', this.measureDimensions)
  },
  watch: {
    offset (offset) {
      this.content.style.left = -offset + 'px'
    }
  },
  computed: {
    hasPrevious () {
      return this.offset > 0
    },
    hasNext () {
      return this.offset + this.slideWidth < this.contentWidth
    }
  },
  methods: {
    measureDimensions () {
      this.contentWidth = this.content.offsetWidth
      this.slideWidth = this.$el.offsetWidth
    },
    goPrevious () {
      let offset = this.offset - this.slideWidth + 50 * 2

      if (offset < 0) {
        offset = 0
      }

      this.offset = offset
    },
    goNext () {
      let offset = this.offset + this.slideWidth + 50 * 2

      if (offset + this.slideWidth > this.contentWidth) {
        offset = this.contentWidth - this.slideWidth
      }

      this.offset = offset
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/common.scss';

.scroller {
  position: relative;
  overflow: hidden;
  height: 100%;

  > * {
    position: absolute;
    left: 0;
    transition: left 300ms ease-in-out;
  }

  > button {
    overflow: none;
    z-index: 3;
    border: 0;
    padding: 0;
    height: 100%;
    width: 40px;
    text-indent: -999em;
    background: transparent;

    &::before {
      position: absolute;
      top: 0;
      width: 200%;
      bottom: 0;
      content: '';
      pointer-events: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      display: block;
      margin-top: -6px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      content: '';
    }

    &.previous {
      &::before {
        left: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1.0));
      }

      &::after {
        left: 6px;
        border-right: 9px solid $primary-color;
      }
    }

    &.next {
      left: auto;
      right: 0;

      &::before {
        right: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1.0));
      }

      &::after {
        right: 6px;
        border-left: 9px solid $primary-color;
      }
    }
  }
}
</style>