<template>
  <div :class="{'welcome-flow': true, 'has-image': useImageLayout, 'show-image': showImage}">
    <h1 class="logo">Ohme</h1>
    <slot />
    <div class="support"><a href="https://ohme.odoo.com/helpdesk/ohme-customer-care-1">Customer support</a></div>
    <div class="image" />
    <footer>
      <ul>
        <li class="copyright">Copyright @ 2021 Ohme Operations UK Ltd</li>
        <li class="phone"><a href="tel:+442033751586">+44&thinsp;(0)&thinsp;20&thinsp;3375&thinsp;1586</a></li>
        <li class="social">
          <a class="instagram" href="https://www.instagram.com/ohmeev">Instagram</a>
          <a class="facebook" href="https://www.facebook.com/ohmeev">Facebook</a>
          <a class="twitter" href="https://www.twitter.com/ohmeev">Twitter</a>
          <a class="linkedin" href="https://www.linkedin.com/company/ohmeev">LinkedIn</a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
function measureCSS (element, property) {
  return parseFloat(getComputedStyle(element, null)[property].replace('px', ''))
}

function scrollTo (element, to, duration) {
  const start = element.scrollTop,
    change = to - start,
    increment = 1000/60
  let currentTime = 0
    
  const animateScroll = function() {
    currentTime += increment
    element.scrollTop = Math.easeInOutQuad(currentTime, start, change, duration)
    if (currentTime < duration) {
      animate(animateScroll)
    }
  }
  animateScroll()
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

const animate = window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  // IE Fallback, you can even fallback to onscroll
  function(callback){ window.setTimeout(callback, 1000/60); };

//
// Mixin that can be used to transition the container size dynamically
//
export const ContainerFit = {
  methods: {
    beforeContainerFit () {
      const container = this.$refs.container.$el
      const window = document.documentElement

      container.style.height = measureCSS(container, 'height') + 'px'

      scrollTo(window, 0, 250)
    },
    enterContainerFit (element) {
      const container = this.$refs.container.$el

      const elementHeight = measureCSS(element, 'height')
      const containerHeight = measureCSS(container, 'height')
      const conatinerPadding = measureCSS(container, 'paddingTop') + measureCSS(container, 'paddingBottom')

      container.style.height = (elementHeight + conatinerPadding) + 'px';
    },
    afterContainerFit () {
      const container = this.$refs.container.$el

      container.style.height = 'auto';
    }
  }
}

// Component itself
export const WelcomeLayout = {
  props: {
    useImageLayout: Boolean,
    showImage: Boolean,
  }
}

export default WelcomeLayout
</script>

<style lang="scss">
@import '~/common.scss';

// 
// Layout
// 

// Constants
$outer-width: 1240px;
$inner-width: 458px;
$vertical-padding: 45px;
$outer-padding: 100px;
$image-padding: 840px;

$logo-width: 80px;
$logo-height: 25px;
$logo-offset-top: 65px;
$logo-offset-left: $outer-padding;

$footer-margin: 25px;
$footer-height: 52px;
$support-height: 20px;
$small-footer-height: 129px;

// Computed values
$total-width: ($outer-width + $outer-padding * 2);

$image-overlap-fixed: ($total-width / 2 - $image-padding);
$top-padding-fixed: ($logo-offset-top + $logo-height + $vertical-padding);
$left-padding-fixed: $outer-padding;
$right-padding-fixed: ($image-padding + $outer-padding);

$image-overlap-fluid: percentage($image-overlap-fixed / $total-width);
$left-padding-fluid: percentage($outer-padding / $total-width);
$right-padding-fluid: percentage(($image-padding + $outer-padding) / $total-width);

.welcome-flow {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 100%;
  max-width: $total-width;
  margin-left: auto;
  margin-right: auto;
  padding: ($top-padding-fixed) ($left-padding-fixed) ($footer-height + $footer-margin * 2 + $support-height);

  transition: height $page-transition-duration; 

  .image {
    display: block;
    position: fixed;
    top: 0;
    bottom: $footer-height;
    right: 0;
    left: 100%;
    margin-left: 0;
    background: url(/images/welcome-background.jpg) no-repeat 60% 60%;
    background-size: cover;
    opacity: 0;
  }

  .logo {
    position: absolute;
    top: $logo-offset-top;
    left: $logo-offset-left;
    width: $logo-width;
    height: $logo-height;
    text-indent: -999em;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: url(/images/logo-white.png) no-repeat 0 0;
      background-size: 100% 100%;
      content: '';
    }
  }

  .form {
    &,
    .octopus.themed & {
      width: 100%;
      max-width: $inner-width;
      margin: 0;
    }

    @media screen and (max-width: 700px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .support {
    position: absolute;
    left: $outer-padding;
    right: $outer-padding;
    bottom: ($footer-height + $footer-margin);
    width: auto;
    font-weight: 700;
    text-align: center;
  }

  // Transition slotted content
  .slide-enter-active, .slide-leave-active,
  .dive-enter-active, .dive-leave-active {
    transition-property: opacity, transform;
  }
  .slide-enter-active, .slide-leave-active,
  .dive-enter-active, .dive-leave-active {
    transition-duration: $page-transition-duration;
  }
  .slide-enter-active,
  .dive-enter-active {
    position: absolute;
    width: auto;
    left: $outer-padding;
    right: $outer-padding;

    &.to-image {
      right: $right-padding-fixed;
    }

    &.static {
      position: static;
    }
  }
  .slide-leave {
    transform: translateX(0);
    opacity: 1;
  }
  .slide-leave-to, .slide-enter.reverse {
    transform: translateX(-250%);
    opacity: 0;
  }
  .slide-enter, .slide-leave-to.reverse {
    transform: translateX(250%);
    opacity: 0;
  }
  .slide-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
  .dive-leave {
    transform: scale(1);
    opacity: 1;
  }
  .dive-leave-to, .dive-enter.reverse {
    transform: scale(0.95);
    opacity: 0;
  }
  .dive-enter, .dive-leave-to.reverse {
    transform: scale(1.05);
    opacity: 0;
  }
  .dive-enter-to {
    transform: scale(1);
    opacity: 1;
  }

  &.has-image {
    padding-right: $right-padding-fixed;
    
    .slide-enter-active,
    .dive-enter-active {
      right: $right-padding-fixed;

      &.to-no-image {
        right: $outer-padding;
      }
    }
  }
  &.show-image {
    .image {
      left: 50%;
      opacity: 1;
      margin-left: $image-overlap-fixed;
    }
    .support {
      right: $right-padding-fixed;
    }
  }

  // Make the layout fluid
  @media screen {
    max-width: none;

    .logo {
      left: $left-padding-fluid;
    }

    padding-left: $left-padding-fluid;
    padding-right: $left-padding-fluid;

    .support,
    .slide-enter-active,
    .dive-enter-active {
      left: calc(50% - (#{$inner-width} / 2));
      right: calc(50% - (#{$inner-width} / 2));

      &.to-image {
        left: $left-padding-fluid;
        right: $right-padding-fluid;
      }
    }

    &.has-image {
      & {
        padding-right: $right-padding-fluid;
      }
      .slide-enter-active,
      .dive-enter-active {
        left: $left-padding-fluid;
        right: $right-padding-fluid;

        &.to-no-image {
          left: calc(50% - (#{$inner-width} / 2));
          right: calc(50% - (#{$inner-width} / 2));
        }
      }
    }

    &.show-image {
      .image {
        margin-left: $image-overlap-fluid;
      }
      .support {
        left: $left-padding-fluid;
        right: $right-padding-fluid;
      }
    }
  }

  @media screen and (min-width: 1650px) {
    .support {
      max-width: $inner-width
    }
    .slide-enter-active,
    .dive-enter-active {
      &.to-image {
        left: calc(#{$left-padding-fluid} / 2 + (100% - #{$right-padding-fluid}) / 2 - #{$inner-width} / 2);
        right: $right-padding-fluid;
      }
    }
    &.has-image {
      .slide-enter-active,
      .dive-enter-active {
        left: calc(#{$left-padding-fluid} / 2 + (100% - #{$right-padding-fluid}) / 2 - #{$inner-width} / 2);
        right: $right-padding-fluid;

        &.to-no-image {
          left: calc(50% - (#{$inner-width} / 2));
          right: calc(50% - (#{$inner-width} / 2));
        }
      }
    }
    &.show-image {
      .support {
        left: calc(#{$left-padding-fluid} / 2 + (100% - #{$right-padding-fluid}) / 2 - #{$inner-width} / 2);
        right: $right-padding-fluid;
      }
    }
  }

  // Transition between "no image" and "has image" states on larger screens
  @media screen and (min-width: 768px) {
    &, .support {
      transition-property: left, right;
    }
    .image {
      transition-property: left, opacity, margin-left;
    }
    &, .support, .image {
      transition-timing-function: ease-in-out;
      transition-duration: $page-transition-duration;
    }
  }

  // Remove the image on sub-iPad screens
  @media screen and (max-width: 767px) {
    > .logo {
      left: 50%;
      margin-left: -($logo-width / 2);
    }
    &.has-image {
      & {
        padding-right: $left-padding-fluid;
      }
      
      .slide-enter-active,
      .dive-enter-active {
        right: $left-padding-fluid
      }
    }
    &.show-image {
      .image {
        display: none;
      }
      .support,
      .slide-enter-active,
      .dive-enter-active {
        left: calc(50% - (#{$inner-width} / 2));
        right: calc(50% - (#{$inner-width} / 2));
      }
    }
  }

  // Fix content being too wide upon entering transition 
  @media screen and (max-width: 532px) {
    &.show-image,
    &.has-image {
      .slide-enter-active,
      .dive-enter-active {
        &, &.to-no-image {
          left: $left-padding-fluid;
          right: $left-padding-fluid;
        }
      }
    }
  }

  > footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 14px 20px;
    background: $alt-background;
    font-size: .875rem;

    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    li {
      margin-left: 10px;
      margin-right: 10px;
    }

    .copyright {
      order: 1;
    }
    .phone {
      order: 0;
    }
    .social {
      order: 2;
      display: flex;
      flex-flow: row nowrap;

      a {
        @include button (0, 2, 32px, 32px);
        margin: -4px 4px;

        &.facebook { @include icon-location(2, 2); }
        &.twitter { @include icon-location(3, 2); }
        &.linkedin { @include icon-location(4, 2); }
      }
    }
  }

  // Simplify header / footer and support link on narrower screens
  @media screen and (max-width: 700px) {
    padding-bottom: ($small-footer-height + $footer-margin * 2 + $support-height);

    .support {
      position: absolute;
      bottom: $small-footer-height + $footer-margin;
    }
    > footer {
      position: absolute;
    }
    > footer {
      ul {
        flex-flow: column nowrap;
      }
      li {
        margin: 5px 0;
      }
      .copyright { order: 0 }
      .phone { order: 1 }
      .social { margin: 10px 0 }
    }
  }
}


// 
// Typography
// 

.welcome-flow {
  h1 {
    margin-bottom: 16px;
    line-height: 1.1;
  }
  h1 + form {
    margin-top: 32px;
  }
  p.instruction {
    margin-bottom: 36px;
    font-weight: 300;
  }
  p.links {
    font-weight: 500;

    .back {
      position: relative;
      display: inline-block;
      padding-left: 14px;
      margin-bottom: 40px;

      &::after, &::before {
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        width: 12px;
        height: 12px;
        background: $primary-color;
        content: "";
        z-index: -1;
        transform: rotate(45deg);
      }
      &::after {
        background: $default-background;
        left: 3px;
        z-index: -1;
      }
    }
  }
}


// 
// Inputs
// 
.welcome-flow {
  .field {
    margin-bottom: 32px;
  }

  .actions {
    margin-top: 32px;
  }

  button.action {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 28px;
    
    + button {
      margin-top: 16px;
    }

    &:focus {
      outline: 0;
      border-color: $outline-color;
    }
  }
}
</style>